import { useAnalytics } from 'client/hooks/gallery/useAnalytics';
import { galleryImpressionsTrackingSelector } from 'client/store/analytics';
import { instrumenter } from 'client/utils/instrumentation';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
    AnalyticsNames, ANALYTICS_CATEGORY, ANALYTICS_EVENT_ACTIONS, ANALYTICS_EVENT_DETAILS, ANALYTICS_LABEL,
    DESIGN_CREATION_TYPE,
} from 'shared/constants';
import { getMpvid } from '~/client/store/config';
import { getProductOptionsHash } from '~/client/utils/getProductOptionsHash';

export type QuickViewAnalyticsBuilderParameters = {
    analytics: Gallery.Analytics.IAnalytics,
    impressionProperties: Gallery.Analytics.GalleryImpressions,
    mpvId: string,
};

export type QuickViewAnalytics = {
    recordChangeColorSwatch: (
        currentEntity: Gallery.QuickView.QuickViewCurrentDesignData,
        newDesignId: string,
        impressionId: string,
        eventDetail?: string,
    ) => void;
    recordChangeFilter: (
        currentEntity: Gallery.QuickView.QuickViewCurrentDesignData,
        optionName: string,
        oldOptionValue: string,
        selectedValue: string,
        quantity: number | undefined,
        impressionId: string,
        eventDetail?: string,
    ) => void;
    recordSelectDesign: (
        currentEntity: Gallery.QuickView.QuickViewCurrentDesignData,
        impressionId: string,
        startingProductOptions: Gallery.ContentQuery.ProductOptions,
        quickViewFilters: string[],
        engagementId?: string,
        initialEstimatedPriceData?: VP.PCT.Models.ProductCatalogPricingService.QuantitiesPricingResult | null,
        currentEstimatedPriceData?: VP.PCT.Models.ProductCatalogPricingService.QuantitiesPricingResult | null,
        startingQuantity?: number,
        currentQuantity?: number,
        eventDetail?: string,
    ) => void;
    recordCloseQuickView: () => void;
    recordShippingNotIncluded: () => void;
    recordFirstInputFieldTouched: (fieldPurpose: string) => void;
    recordOpenReviewPage: () => void;
    recordAddToCart: () => void;
    recordImpression: (
        currentEntity: Gallery.QuickView.QuickViewCurrentDesignData,
        impressionId: string,
    ) => void;
};

function createQuickviewAnalyticsData(
    tileEntity: Gallery.QuickView.QuickViewCurrentDesignData,
): Gallery.Analytics.DesignAnalyticsObject {
    return {
        tileEntity,
        location: AnalyticsNames.Quickview,
    };
}

function trackImpression(
    currentEntity: Gallery.QuickView.QuickViewCurrentDesignData,
    analytics: Gallery.Analytics.IAnalytics,
    impressionProperties: Gallery.Analytics.GalleryImpressions,
    impressionId: string,
    mpvId: string,
): void {
    const analyticsData = createQuickviewAnalyticsData(currentEntity);

    const isDirectQuickView = currentEntity.isQSPEntity;

    const dataEntity = {
        productKey: currentEntity.productKey,
        product: currentEntity.product,
        designId: currentEntity.currentEntityId,
        colorSwatchValues: currentEntity.colorSwatches.map((cs) => cs.color?.split('#')[1]).filter(Boolean),
        isForceRanked: currentEntity.isForceRanked,
        galleryImpressionId: impressionId,
        productOptionsSetId: 1,
        designConceptId: currentEntity.designConceptId,
        externalId: currentEntity.currentColorSwatch.externalId,
        templateUseCase: currentEntity.templateUseCase,
        mpvId,
        designCreationType: currentEntity.designCreationType,
    } as Gallery.Analytics.AnalyticsEntity;

    if (!isDirectQuickView) {
        dataEntity.position = analyticsData.tileEntity.position;
    }

    const productOptionsSet = {
        id: 1,
        options: currentEntity.fullProductOptions,
    };

    analytics.trackImpression({
        ...impressionProperties,
        isDirectQuickView,
        entities: [dataEntity],
        productOptionsSets: [productOptionsSet],
    });
}

const buildQuickViewAnalytics = ({
    analytics,
    impressionProperties,
    mpvId,
}: QuickViewAnalyticsBuilderParameters): QuickViewAnalytics => ({
    recordChangeColorSwatch: (
        currentEntity: Gallery.QuickView.QuickViewCurrentDesignData,
        newDesignId: string,
        impressionId: string,
        eventDetail?: string,
    ): void => {
        const analyticsData = createQuickviewAnalyticsData(currentEntity);
        const color = (currentEntity.colorSwatches.find((cs) => cs.designId === newDesignId)?.color)?.split('#')[1];

        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.GALLERY_DESIGN_ENGAGEMENT,
            eventLabel: ANALYTICS_LABEL.SELECT_COLOR_SWATCH,
            eventDetail: eventDetail || '',
            ...analytics.buildDesignEngagement({
                engagementAction: ANALYTICS_LABEL.SELECT_COLOR_SWATCH,
                selectedDesign: newDesignId,
                color,
                tileEntity: analyticsData.tileEntity,
                colorSwatchObjects: currentEntity.colorSwatches,
                impressionId,
                location: analyticsData.location,
                position: analyticsData.tileEntity.position,
                selectedQuickViewOptions: currentEntity.fullProductOptions,
            }),
        });

        analytics.trackAttributeSelected('Design Substrate', color);
    },
    recordChangeFilter: (
        currentEntity: Gallery.QuickView.QuickViewCurrentDesignData,
        optionName: string,
        oldOptionValue: string,
        selectedValue: string,
        quantity: number | undefined,
        impressionId: string,
        eventDetail?: string,
    ): void => {
        trackImpression(currentEntity, analytics, impressionProperties, impressionId, mpvId);

        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.GALLERY_UPSELL_ENGAGEMENT,
            eventLabel: ANALYTICS_EVENT_ACTIONS.GALLERY_UPSELL_ENGAGEMENT,
            quantity,
            eventDetail: eventDetail || '',
            ...analytics.buildUpsellEngagement({
                selectedDesign: currentEntity.designId,
                selectedDesignId: currentEntity.currentEntityId,
                productKey: currentEntity.productKey,
                productOptions: currentEntity.fullProductOptions,
                optionName,
                oldOptionValue,
                newOptionValue: selectedValue,
                designCreationType: currentEntity.designCreationType,
                color: currentEntity.designCreationType === DESIGN_CREATION_TYPE.DYNAMIC
                    ? currentEntity.currentColorSwatch.designId.split('__')[1]
                    : currentEntity.currentColorSwatch.color?.split('#')[1],
                designConceptId: currentEntity.designConceptId,
                externalId: currentEntity.currentColorSwatch.externalId,
                templateUseCase: currentEntity.templateUseCase,
            }),
        });

        analytics.trackAttributeSelected(optionName, selectedValue);
    },
    recordSelectDesign: (
        currentEntity: Gallery.QuickView.QuickViewCurrentDesignData,
        impressionId: string,
        startingProductOptions: Gallery.ContentQuery.ProductOptions,
        quickViewFilters: string[],
        engagementId?: string,
        initialEstimatedPriceData?: VP.PCT.Models.ProductCatalogPricingService.QuantitiesPricingResult | null,
        currentEstimatedPriceData?: VP.PCT.Models.ProductCatalogPricingService.QuantitiesPricingResult | null,
        startingQuantity?: number,
        currentQuantity?: number,
        eventDetail?: string,
    ): void => {
        instrumenter.recordDesignSelection({ designId: currentEntity.currentDesignId }, 'tile');
        const analyticsData = createQuickviewAnalyticsData(currentEntity);
        const color = currentEntity.currentColorSwatch.color?.split('#')[1];

        const didQuickViewOptionsChange = getProductOptionsHash(currentEntity.productOptions)
            !== getProductOptionsHash(startingProductOptions);

        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.GALLERY_DESIGN_ENGAGEMENT,
            eventLabel: ANALYTICS_LABEL.SELECT_DESIGN,
            eventDetail: eventDetail || '',
            ...analytics.buildDesignEngagement({
                engagementAction: ANALYTICS_LABEL.SELECT_DESIGN,
                selectedDesign: currentEntity.currentDesignId,
                color,
                tileEntity: analyticsData.tileEntity,
                colorSwatchObjects: currentEntity.colorSwatches,
                impressionId,
                location: analyticsData.location,
                position: analyticsData.tileEntity.position,
                selectedQuickViewOptions: currentEntity.fullProductOptions,
                didQuickViewOptionsChange: !!didQuickViewOptionsChange,
                engagementId,
            }),
        });

        analytics.trackProductOptionsSelected({
            currentEntity,
            quickViewFilters,
            startingProductOptions,
            initialEstimatedPriceData,
            currentEstimatedPriceData,
            startingQuantity,
            currentQuantity,
        });
    },
    recordCloseQuickView: (): void => {
        analytics.trackEvent({
            category: ANALYTICS_CATEGORY.FLY_OUT,
            action: ANALYTICS_EVENT_ACTIONS.FLY_OUT_CLOSED,
            eventLabel: ANALYTICS_LABEL.FLY_OUT_EVENT,
        });
    },
    recordShippingNotIncluded: (): void => {
        analytics.trackEvent({
            category: ANALYTICS_CATEGORY.FLY_OUT,
            action: ANALYTICS_EVENT_ACTIONS.FLY_OUT_CLICKED,
            eventLabel: ANALYTICS_LABEL.FLY_OUT_EVENT,
            eventDetail: ANALYTICS_EVENT_DETAILS.SHIPPING_NOT_INCLUDED,
        });
    },
    recordFirstInputFieldTouched: (fieldPurpose: string): void => {
        analytics.trackEvent({
            category: ANALYTICS_CATEGORY.FLY_OUT,
            action: ANALYTICS_EVENT_ACTIONS.FLY_OUT_CLICKED,
            eventLabel: ANALYTICS_LABEL.FLY_OUT_EVENT,
            eventDetail: `${fieldPurpose} Text Clicked`,
        });
    },
    recordOpenReviewPage: (): void => {
        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.FLY_OUT_VIEWED,
            category: ANALYTICS_CATEGORY.FLY_OUT,
            eventLabel: ANALYTICS_LABEL.REVIEW_FLY_OUT_EVENT,
            eventDetail: ANALYTICS_EVENT_DETAILS.REVIEW,
        });
    },
    recordAddToCart: (): void => {
        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.FLY_OUT_CLICKED,
            category: ANALYTICS_CATEGORY.FLY_OUT,
            eventLabel: ANALYTICS_LABEL.REVIEW_FLY_OUT_EVENT,
            eventDetail: ANALYTICS_LABEL.ADD_TO_CART,
        });
    },
    recordImpression: (
        currentEntity: Gallery.QuickView.QuickViewCurrentDesignData,
        impressionId: string,
    ): void => {
        trackImpression(currentEntity, analytics, impressionProperties, impressionId, mpvId);
    },
});

export const useQuickViewAnalytics = (): QuickViewAnalytics => {
    const analytics = useAnalytics();
    const mpvId = useSelector(getMpvid);
    const impressionProperties = useSelector((state: State.GlobalState) => galleryImpressionsTrackingSelector(
        state,
        analytics,
        AnalyticsNames.Quickview,
    ));

    const memo = useMemo(
        () => buildQuickViewAnalytics({
            analytics,
            impressionProperties,
            mpvId,
        }),
        [analytics, impressionProperties, mpvId],
    );

    return memo;
};
