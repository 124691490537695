import { Box, ModalDialogFooter } from '@vp/swan';
import { MutableRefObject, memo } from 'react';
import { StudioUrlBuilder } from '~/client/components/Gallery/PreviewArea/GalleryQuickView/utils/studioUrl';
import { ContextualBatchPricingDisplay } from '~/client/components/common/PricingDisplay/ContextualBatchPricingDisplay';
import { SelectDesignButton } from '~/client/components/common/QuickView';
import { PersonalizedUrlBuilder } from '~/client/utils/tilePersonalization/personalizedUrlBuilder';
import { useProductOptionsTracking } from '~/client/hooks/useProductOptionsTracking';
import { PromoCode } from '~/client/components/common/QuickView/PromoCode';
import { useSocialMediaGallery } from '~/client/hooks/useSocialMediaGallery';
import { State } from 'client/components/Gallery/PreviewArea/GalleryQuickView/hooks/useCurrentDesign';
import { designPersonalizationContextSelector } from '~/client/store/designPersonalization/selectors';
import { useSelector } from 'react-redux';
import { useTemplateInteractions } from '~/client/hooks/useTemplateInteractions';
import { TEMPLATE_INTERACTION_TYPE } from '~/experiments/DesignBoost/constants';
import { useQuickViewAnalytics } from '~/client/components/Gallery/PreviewArea/GalleryQuickView/hooks/useQuickViewAnalytics';
import {
    useAdjustedPricingPresentationType,
} from 'client/components/Gallery/PreviewArea/GalleryQuickView/hooks/useAdjustedPricingPresentationType';
import { getTakeOverQV } from '~/client/store/debug/reducer';

interface ModalFooterComponentProps {
    market: string;
    locale: i18n.Locale;
    productQuantity?: number;
    pricingPresentationType: State.RenderPropertyValue;
    shouldUseDiamond: boolean;
    studioUrl: StudioUrlBuilder;
    currentEntity: State;
    startingEntity: State.TileEntity;
    adjustedDesignsVariableSelections: Gallery.ContentQuery.ProductOptions;
    impressionId: string;
    quickViewFilterNames: string[];
    engagementIdRef: MutableRefObject<string>;
    quantity: Gallery.Models.Url.ValidParsedQsValue<number>;
}

const ModalFooterComponent = ({
    market,
    locale,
    productQuantity,
    pricingPresentationType,
    shouldUseDiamond,
    studioUrl,
    currentEntity,
    startingEntity,
    adjustedDesignsVariableSelections,
    impressionId,
    quickViewFilterNames,
    engagementIdRef,
    quantity,
}: ModalFooterComponentProps): JSX.Element => {
    const shouldHideForSocialMedia = useSocialMediaGallery();
    const designPersonalizationContext = useSelector(designPersonalizationContextSelector);
    const { createInteractions } = useTemplateInteractions();
    const quickViewAnalytics = useQuickViewAnalytics();
    const isTakeOverQV = useSelector(getTakeOverQV);

    const adjustedPricingPresentationType = useAdjustedPricingPresentationType(pricingPresentationType, quantity);

    const { personalizedInstructionsUrl } = currentEntity.renderablePreviews;

    const { initialEstimatedPriceData, currentEstimatedPriceData } = useProductOptionsTracking({
        currentEntity,
        startingEntity,
        locale,
        market,
        quantity: productQuantity,
        enabled: !shouldHideForSocialMedia,
    });

    const handleSelectDesignButtonClick = (): void => {
        const dpcHasLogo = designPersonalizationContext?.images?.some((image) => image?.purpose === 'logo') || false;
        const dpcHasPhoto = designPersonalizationContext?.images?.some((image) => image?.purpose === 'photo') || false;

        let selectedPersonalizedDesignDetail = '';

        if (dpcHasLogo) {
            selectedPersonalizedDesignDetail = 'Studio opened with personalized logo';
        } else if (dpcHasPhoto) {
            selectedPersonalizedDesignDetail = 'Studio opened with personalized photo';
        }

        window.history.replaceState({
            ...window.history.state,
            variableSelections: adjustedDesignsVariableSelections,
        }, '', window.location.href);

        createInteractions({
            productKey: currentEntity.productKey,
            categoryKeys: currentEntity.categoryKeys,
            keywords: currentEntity.keywords,
            type: TEMPLATE_INTERACTION_TYPE.STUDIO_TRANSITION,
        });

        if (!isTakeOverQV) {
            quickViewAnalytics.recordSelectDesign(
                {
                    ...currentEntity,
                    studioUrl: studioUrl.build(),
                },
                impressionId,
                startingEntity.productOptions,
                quickViewFilterNames,
                engagementIdRef.current,
                initialEstimatedPriceData,
                currentEstimatedPriceData,
                productQuantity,
                productQuantity,
                selectedPersonalizedDesignDetail,
            );
        }
    };

    return (
        <ModalDialogFooter
            pinned
            className="modal-dialog-footer"
            pb="6"
            pt="5"
        >
            <Box pb="5">
                <ContextualBatchPricingDisplay
                    hideShippingMessageInVatMessage
                    component="span"
                    fullProductOptions={currentEntity.fullProductOptions}
                    lines={1}
                    locale={locale}
                    market={market}
                    pricingPresentationType={adjustedPricingPresentationType}
                    productKey={currentEntity.productKey}
                    productOptionsByProductKey={currentEntity.productOptionsByProductKey}
                    productOptionsHash={currentEntity.productOptionsHash}
                    productVersion={currentEntity.productVersion || undefined}
                    quantity={productQuantity}
                />
                {productQuantity && (
                <PromoCode
                    pricingPresentationType={pricingPresentationType}
                    quantity={productQuantity}
                    selectedProductOptions={currentEntity.fullProductOptions}
                />
                )}
            </Box>
            <SelectDesignButton
                data-section="Gallery Fly-out"
                data-translation="Fly-Out Clicked:Customise this design"
                href={(shouldUseDiamond && personalizedInstructionsUrl)
                    ? studioUrl.buildWithPersonalizedUrlBuilder(
                        personalizedInstructionsUrl,
                        PersonalizedUrlBuilder.buildDiamondStudioUrl,
                    )
                    : studioUrl.build()}
                onClick={handleSelectDesignButtonClick}
            />
        </ModalDialogFooter>
    );
};

export const ModalFooter = memo(ModalFooterComponent);
