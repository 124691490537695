import { colorSwatchSelector } from 'client/store/design';
import { productOptionsByProductKeySelector } from 'client/store/productOptionsByProductKey';
import { UseQueryResult } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useRecoilValue } from 'recoil';
import { PREVIEW_TYPE } from 'shared/constants';
import { currentFavoritesState } from '~/client/atoms/currentFavoritesAtom';

export type State = Gallery.QuickView.QuickViewCurrentDesignData & Omit<UseQueryResult<unknown, Error>, 'data'>;

export const useCurrentDesign = (
    startingEntity: State.TileEntity,
    currentDesignId: string,
    selectedProductOptions: Gallery.ContentQuery.ProductOptions | DesignVariations.VariableSelections,
    _initialProductOptions: Gallery.ContentQuery.ProductOptions = {},
): State => {
    const currentColorSwatch = useSelector(colorSwatchSelector)(
        currentDesignId,
        startingEntity.designId,
        PREVIEW_TYPE.QUICKVIEW,
    );
    const productOptionsByProductKey = useSelector(productOptionsByProductKeySelector);
    const favoriteId = useRecoilValue(currentFavoritesState).get(startingEntity.designId);
    const state = {
        ...startingEntity,
        ...currentColorSwatch,
        favoriteId,
        designId: startingEntity.designId,
        currentEntityId: startingEntity.designId,
        currentDesignId,
        currentColorSwatch,
        productOptionsByProductKey,
        productOptions: selectedProductOptions,
        isSuccess: true,
    } as unknown as State;

    return state;
};
