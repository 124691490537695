import { MAX_COLOR_WEIGHT_DIFFERENCE_PERCENT } from '~/client/constants';

// Reference: https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
export const hexToRgb = ((hex: string | undefined): Gallery.ContentQuery.RgbValue => {
    if (!hex) {
        return {
            r: 0,
            g: 0,
            b: 0,
        } as Gallery.ContentQuery.RgbValue;
    }

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

    return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
        } as Gallery.ContentQuery.RgbValue
        : {
            r: 0,
            g: 0,
            b: 0,
        } as Gallery.ContentQuery.RgbValue;
});

export const rgbDelta = (rgb1: Gallery.ContentQuery.RgbValue, rgb2: Gallery.ContentQuery.RgbValue): number => Math.sqrt(
    (rgb1.r - rgb2.r) ** 2
        + (rgb1.g - rgb2.g) ** 2
        + (rgb1.b - rgb2.b) ** 2,
);

const calculateWeightedColorDistance = (
    originalColors: Gallery.ContentQuery.DesignColors[],
    newColors: Gallery.ContentQuery.DesignColors[],
): number => originalColors.reduce((totalDelta, originalColor, index) => {
    if (index >= newColors.length) {
        return totalDelta;
    }
    // Filter out new colors that are too different in weight.
    // Max percent difference is currently set to 5 - subject to change after testing.
    const filteredNewColors = newColors
        .filter((c) => Math.abs(c.weight - originalColor.weight) < MAX_COLOR_WEIGHT_DIFFERENCE_PERCENT);

    // If none within weight threshold, compare to the color in the same index (eg. primary to primary).
    if (!filteredNewColors.length) {
        filteredNewColors.push(newColors[index]);
    }

    const smallestDelta = filteredNewColors.reduce((smallest, newColor) => {
        const colorDistance = rgbDelta(originalColor.rgb, newColor.rgb);

        return Math.min(smallest, colorDistance);
    }, Number.MAX_SAFE_INTEGER);

    return totalDelta + (smallestDelta * Math.abs(originalColor.weight));
}, 0);

export const findClosestDesignId = (
    currentColorSwatch: Gallery.Designs.CurrentColorSwatch | Gallery.ContentQuery.ColorSwatch,
    colorSwatches: Gallery.ContentQuery.ColorSwatch[],
): string | null => {
    if (!colorSwatches.length || !currentColorSwatch.designColors) {
        return null;
    }

    const result = colorSwatches.reduce((closest, colorSwatch) => {
        if (!colorSwatch.designColors) return closest;

        const delta = calculateWeightedColorDistance(
            currentColorSwatch?.designColors ?? [],
            colorSwatch.designColors,
        );

        if (delta < closest.delta) {
            return { colorSwatch, delta };
        }

        return closest;
    }, { colorSwatch: colorSwatches[0], delta: Infinity });

    return result.colorSwatch.designId;
};
