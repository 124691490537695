import { useSelector } from 'react-redux';

import { NoResultsMessage } from 'client/components/Gallery/PreviewArea/NoResultsMessage';
import { PreviewList } from 'client/components/Gallery/PreviewArea/PreviewList';
import { useTranslations } from 'client/hooks/useTranslations';
import { totalEntityCountSelector } from 'client/store/paging';
import { QuickViewModal } from 'client/components/Gallery/PreviewArea/QuickViewModal';
import { getShowSpinnerSelector } from '~/client/store/ux';
import { LoadingSpinner } from '../../common/LoadingSpinner';

export const PreviewArea = (): JSX.Element => {
    const localize = useTranslations();
    const resultCount = useSelector(totalEntityCountSelector);
    const { showSpinner } = useSelector(getShowSpinnerSelector);

    return (
        <section aria-label={localize('PreviewRegionLabel')} className="preview-area-container" id="preview-area">
            {!resultCount && (<NoResultsMessage key="no-results-message" />)}
            {!!resultCount && <PreviewList />}
            <QuickViewModal />
            {showSpinner && (<LoadingSpinner />)}
        </section>
    );
};
