import {
    SelectionSetInput, SelectionSetLabel, StandardTile, StandardTileContents, StandardTileName,
} from '@vp/swan';
import { ReactNode } from 'react';

export interface PropTypes {
    children?: ReactNode;
    title?: ReactNode;
    value?: string;
}

export const FilterSkeleton = (props: PropTypes): JSX.Element => {
    const {
        children,
        title,
        value,
    } = props;

    return (
        <div className="quick-view-filter-item" key={value}>
            <SelectionSetInput value={value || ''}>
                <SelectionSetLabel>
                    <StandardTile as="span" layout="horizontal">
                        <StandardTileContents as="span" className="quick-view-tile-contents">
                            <StandardTileName as="span">
                                {title}
                            </StandardTileName>
                            {children}
                        </StandardTileContents>
                    </StandardTile>
                </SelectionSetLabel>
            </SelectionSetInput>
        </div>
    );
};
