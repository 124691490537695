import {
    ModalDialogBody,
    ModalDialogContent,
    ModalDialogNav,
    ModalDialogCloseButton,
    ModalDialogFooter,
    FlexBox,
    Box,
} from '@vp/swan';
import { PersonalizationHeader } from 'client/components/Gallery/Filters/PersonalizationV1/PersonalizationHeader';
import { PersonalizationFilter } from 'client/components/Gallery/Filters/PersonalizationV1';
import { useContext, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useMobileSave } from 'client/components/Gallery/Filters/PersonalizationV1/useMobileSave';
import { designPersonalizationContextSelector } from '~/client/store/designPersonalization/selectors';
import { useSelector } from 'react-redux';
import { useAuth } from '../../../../../hooks/useAuth';
import { BrandSignIn } from '../../../Filters/PersonalizationV1/BrandSignIn';
import { PERSONALIZATION_UX_BRAND_VARIATIONS } from '../../../../../../experiments/Personalization/constants';
import { TRACKING_BEHAVIOR, useExperimentation } from '../../../../../hooks/useExperimentation';
import { PersonalizationReactContext } from '../../../../../contexts/PersonalizationReactContext';
import { selectedUploadsState } from '../../../../../atoms/selectedUploadsStateAtom';
import { usePurposeNames } from '../hooks/usePurposeNames';
import { dpcToTextFields } from '../../../Filters/PersonalizationV1/dpcConverters';

export const MobilePersonalizationModalContent = (): JSX.Element | null => {
    const isSignedIn = useAuth()?.isSignedIn;
    const isExperimentActive = useExperimentation();
    const isBrandExperimentEnabled = isExperimentActive(
        PERSONALIZATION_UX_BRAND_VARIATIONS.Enabled,
        TRACKING_BEHAVIOR.Suppress,
    );
    const {
        mobileTextfieldValues,
        setMobileTextfieldValues,
        setShouldDisplayBrand,
        mobileBrandEnabled,
        manualAppliedImages,
    } = useContext(PersonalizationReactContext);
    const selectedUploads = useRecoilValue(selectedUploadsState);
    const onSignInPress = useMobileSave();

    const dpc = useSelector(designPersonalizationContextSelector);
    const purposeNames = usePurposeNames();
    const setSelectedUploads = useSetRecoilState(selectedUploadsState);

    const canSave = useMemo(
        () => !!Object.values(mobileTextfieldValues).some((value) => value !== '') || !!selectedUploads?.length,
        [mobileTextfieldValues, selectedUploads?.length],
    );

    const onModalCloseButtonPress = ((): void => {
        if (!mobileBrandEnabled) {
            setMobileTextfieldValues?.(dpcToTextFields(purposeNames, dpc));
        }
        // TODO: text input needs to be restored here
        // QSP-906

        setSelectedUploads(manualAppliedImages || []);

        setShouldDisplayBrand?.(mobileBrandEnabled);
    });

    return (
        <ModalDialogContent fullBleed aria-label="mobile-personalization-content">
            <ModalDialogNav>
                <ModalDialogCloseButton accessibleText="Close" onClick={onModalCloseButtonPress} />
            </ModalDialogNav>
            <ModalDialogBody>
                <Box margin={5} marginTop={6}>
                    <PersonalizationHeader mobileStyle />
                    <PersonalizationFilter />
                </Box>
            </ModalDialogBody>

            {!isSignedIn && isBrandExperimentEnabled && (
                <ModalDialogFooter
                    pinned
                    mx={5}
                    py={0}
                >
                    <FlexBox
                        alignItems="center"
                        flexDirection="column"
                        mb={5}
                        pt={6}
                    >
                        {/* With this implementation we don't give the user the prompt to
                          * save their brandkit until after they hit 'Submit'
                          *
                          * Doing so beforehand is complicated as it would require
                          * us to save their selections to personalization state, generate a DPC
                          * AND wait for the brand call to fire off before we redirect to login
                          * Since all of these are asynchronous actions that happen via a redux dispatch
                          * we don't have a good way to guarantee those are finished before redirecting
                          */}
                        <BrandSignIn beforeSignIn={canSave ? onSignInPress : undefined} canSave={canSave} linkStyle="below" />
                    </FlexBox>
                </ModalDialogFooter>
            )}
        </ModalDialogContent>
    );
};

MobilePersonalizationModalContent.displayName = 'MobilePersonalizationModalContent';
