import { AnyAction } from 'redux';
import { createSelector } from 'reselect';

const initialState: State.FilterByCategoryState = {};

export function reducer(
    state: State.FilterByCategoryState = initialState,
    action: AnyAction,
): State.FilterByCategoryState {
    switch (action.type) {
        default:
            return state;
    }
}

export const filterByCategorySelector = createSelector(
    (state: State.GlobalState) => state.filterByCategory,
    (filterByCategory) => (id: string): string => filterByCategory[id],
);
