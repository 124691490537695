import type { DesignPersonalizationContext, DesignPersonalizationImage, DesignPersonalizationText } from '@vp/personalization-types';

import { LOCAL_STORAGE_KEYS } from '~/client/constants';

interface buildDpcArgs {
    text?: DesignPersonalizationText[],
    images?: DesignPersonalizationImage[],
}

export const buildDesignPersonalizationData = ({ text, images }: buildDpcArgs): DesignPersonalizationContext | undefined => {
    if ((!text || text.length === 0) && (!images || images.length === 0)) {
        return undefined;
    }
    const newContext: DesignPersonalizationContext = {
        designPersonalizationContextVersion: 0,
    };

    if (text && text.length > 0) {
        newContext.text = text;
    }

    if (images && images.length > 0) {
        newContext.images = images;
    }

    return newContext;
};

export function saveToLocalStorage(
    sessionId: string | undefined,
    galleryId: number | undefined,
): void {
    if (!galleryId) {
        return;
    }

    const storageKey = LOCAL_STORAGE_KEYS.PERSISTED_DPSESSION + galleryId;

    if (!sessionId) {
        // clear local storage
        localStorage.removeItem(storageKey);
        return;
    }
    localStorage.setItem(storageKey, sessionId);
}

export function saveBrandAppliedToLocalStorage(
    brandApplied: boolean,
    galleryId: number | undefined,
): void {
    if (!galleryId) {
        return;
    }

    const storageKey = LOCAL_STORAGE_KEYS.PERSISTED_BRAND_APPLIED + galleryId;

    if (!brandApplied) {
        // clear local storage
        localStorage.removeItem(storageKey);
        return;
    }
    localStorage.setItem(storageKey, 'true');
}

export const brandLocalStorageKeyBuilder = (galleryId?: number): string => LOCAL_STORAGE_KEYS.PERSISTED_BRAND_APPLIED + galleryId;
