import { Middleware } from 'redux';

import { galleryImpressionsTrackingSelector } from 'client/store/analytics/reducer';
import { CONTENT_UPDATE } from 'client/store/constants';
import { AnalyticsNames } from 'shared/constants';

export const analyticsMiddlewareFactory = (analytics: Gallery.Analytics.IAnalytics): Middleware => ({ getState }) => (next) => (action): any => {
    const result = next(action);
    const newState = getState();

    analytics.updateState(newState);

    // Originally I wanted to run a selector for all acti ons and use memoization to determine when something has
    // changed. There are a few problems with that on page load, and it's pretty fragile
    // That's why we're always logging impressions on content_update actions
    if (action.type === CONTENT_UPDATE && !action.payload.suppressImpression) {
        analytics.trackImpression(galleryImpressionsTrackingSelector(newState, analytics, AnalyticsNames.DesignTile));
    }

    return result;
};
