import { useState } from 'react';

import { UseUnwindingAlertBoxResult } from '~/experiments/DesignConsolidation/components/UnwindingAlertBox/interface';

export const useUnwindingAlertBox = (): UseUnwindingAlertBoxResult => {
    const [shouldShowUnwindingAlertBox, setShouldShowUnwindingAlertBox] = useState(false);
    const [unwindingSelectedOptionKey, setUnwindingSelectedOptionKey] = useState('');

    const showUnwindingAlertBox = (selectedOptionKey: string): void => {
        setShouldShowUnwindingAlertBox(true);
        setUnwindingSelectedOptionKey(selectedOptionKey);
    };

    const hideUnwindingAlertBox = (): void => setShouldShowUnwindingAlertBox(false);

    return {
        shouldShowUnwindingAlertBox,
        showUnwindingAlertBox,
        hideUnwindingAlertBox,
        unwindingSelectedOptionKey,
    };
};
