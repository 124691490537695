import React from 'react';
import { Typography } from '@vp/swan';

interface BoostChipProps {
    term: string;
    type: 'keyword' | 'category';
    index: number;
}

const colors = [
    '#00FFFF', '#FFFF00', '#FF69B4', '#00FF00', '#FFA500',
    '#1E90FF', '#FF1493', '#7FFFD4', '#FF00FF', '#00FA9A',
    '#FF4500', '#00CED1', '#FF6347', '#40E0D0', '#FFD700',
    '#00BFFF', '#FF8C00', '#7FFF00', '#FF1493', '#00FF7F'];

export const BoostChip: React.FC<BoostChipProps> = ({ term, type, index }) => (
    <div className="boost-chip" style={{ backgroundColor: colors[index % colors.length] }}>
        <Typography fontSize="standard" fontWeight="bold">
            {`${type === 'keyword' ? '🔑' : '🐱'} ${term}`}
        </Typography>
    </div>
);
