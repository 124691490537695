import { DesignPersonalizationContextService } from '~/services/DesignPersonalizationContextService';

export const createTemplateInteractions = async (
    {
        canonicalId,
        interactions,
        accessToken,
    }: VP.DesignPersonalization.Mutations.DesignPersonalizationContextService.CreateTemplateInteractionMutation,
): Promise<string> => DesignPersonalizationContextService
    .addTemplateInteractions(canonicalId, interactions, accessToken);
