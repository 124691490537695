import { QUERY_KEY as DIFF_QUERY_KEY } from 'client/queries/queryBatchDifferentialPricing';
import { UseQueryResult, useQuery, UseQueryOptions } from '@tanstack/react-query';
import { queryBatchEstimatedPricing } from '../queries/queryBatchEstimatedPricing';

type EstimatedKey = VP.PCT.Queries.ProductCatalogPricingService.QuantitiesPriceQueryKey;
type EstimatedResult = VP.PCT.Models.ProductCatalogPricingService.QuantitiesPricingResult | null;

export const useBatchEstimatedPricing = (
    params: VP.PCT.Queries.ProductCatalogPricingService.QuantitiesPriceQueryKeyParameters,
    options?: UseQueryOptions<EstimatedResult, Error, EstimatedResult, EstimatedKey>,
): UseQueryResult<VP.PCT.Models.ProductCatalogPricingService.QuantitiesPricingResult | null | undefined> => useQuery(
    [DIFF_QUERY_KEY, {
        ...params,
    }],
    queryBatchEstimatedPricing,
    options,
);
