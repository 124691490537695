import {
    Button,
    Card,
    FlexBox,
    FluidImage,
    ToggleSwitch,
    ToggleSwitchOffLabel,
    ToggleSwitchOnLabel,
    Typography,
} from '@vp/swan';
import {
    memo, useContext, useEffect, useRef,
} from 'react';
import { PersonalizationReactContext } from '~/client/contexts/PersonalizationReactContext';
import { useTranslations } from '~/client/hooks/useTranslations';
import { useBrandContext } from '@vp/react-brand';
import { getRawExperiments } from '~/client/store/experimentation';
import { trackExperimentEngagement } from '~/shared/ab-testing';
import { PERSONALIZATION_UX_BRAND_EXPERIMENT_NAME, PERSONALIZATION_UX_BRAND_VARIATIONS } from '~/experiments/Personalization/constants';
import { DesignPersonalizationContextService } from '~/services/DesignPersonalizationContextService';
import { useAuth } from '~/client/hooks/useAuth';
import { isSmallScreen } from '~/client/utils/deviceDetection';
import { designPersonalizationSessionUpdate } from '~/client/store/designPersonalization/actions';
import { useDispatch, useSelector } from 'react-redux';
import { designPersonalizationSessionSelector } from '~/client/store/designPersonalization/selectors';
import type { DesignPersonalizationText } from '@vp/personalization-types';
import { useUploadConfiguration } from '@design-stack-vista/upload-components';
import { contentUpdate } from '~/client/store/content';
import { getGalleryIdSelector } from '~/client/store/config';
import { PURPOSE_NAMES } from './constants';
import {
    brandContentToDpc, getTextFieldValuebyPurpose, FileTypeNotAllowedError, personalizedTextToDpcText,
} from './dpcConverters';
import { ANALYTICS_EVENT_ACTIONS, ANALYTICS_LABEL } from '../../../../../shared/constants';
import { useAnalytics } from '../../../../hooks/gallery/useAnalytics';
import { brandLocalStorageKeyBuilder, saveBrandAppliedToLocalStorage } from './designPersonalizationSessionHelpers';
import { usePersonalizationFlyoutOpen } from '../../Header/PersonalizationV1/hooks';

export const BrandSelector = memo((): JSX.Element | null => {
    const {
        brandDps,
        setBrandDps,
        shouldDisplayBrand,
        setShouldDisplayBrand,
        isSmallScreenPersonalizationEnabled,
        dispatchDesignPersonalizationManualUpdate,
        textfieldValues,
        selectedDpcImages,
        targetBrand,
    } = useContext(PersonalizationReactContext);

    const brandContext = useBrandContext();
    const dispatch = useDispatch();
    const analytics = useAnalytics();
    const rawExperiments = useSelector(getRawExperiments);
    const auth = useAuth();
    const brandDpc = brandDps?.designPersonalizationContext.designPersonalizationData;
    const currentSession = useSelector(designPersonalizationSessionSelector);
    const galleryId = useSelector(getGalleryIdSelector);
    const brandLocalStorageKey = brandLocalStorageKeyBuilder(galleryId);
    const {
        supportedFileTypes: { fileExtensions },
    } = useUploadConfiguration();
    const allowedFileExtensions = fileExtensions.filter((ext: string) => ext !== 'pdf');
    const { mobilePersonalizationModalOpen } = usePersonalizationFlyoutOpen();

    useEffect(() => {
        let isMounted = true;

        const getBrandDps = async (): Promise<void> => {
            const dpc = await brandContentToDpc(
                targetBrand,
                (logoId: string) => brandContext.api.getLogoDetails(targetBrand?.brandId ?? '', logoId),
                allowedFileExtensions,
            ).catch((e) => {
                // if a PDF (or other non-allowed file type) was found in the loaded brand,
                // use an empty DPC and log an error to segment
                if (e instanceof FileTypeNotAllowedError) {
                    analytics.trackEvent(({
                        action: ANALYTICS_EVENT_ACTIONS.PERSONALIZATION_ERROR,
                        eventLabel: ANALYTICS_LABEL.PERSONALIZATION_ERROR,
                        eventDetail: `Attempted to load brand with invalid logo: ${e.message}`,
                        ...analytics.getPageProperties(),
                    }));
                    return undefined;
                }

                // else rethrow the error
                throw (e);
            });

            if (!dpc || !auth?.accessToken) {
                setBrandDps?.(undefined);
                return;
            }

            const dps = await DesignPersonalizationContextService.createDesignPersonalizationSession(
                dpc,
                targetBrand?.brandId,
                auth?.accessToken,
            );

            if (isMounted) {
                setBrandDps?.(dps);

                const brandLocalStorageData = localStorage.getItem(brandLocalStorageKey);

                if (brandLocalStorageData === 'true') {
                    dispatch(designPersonalizationSessionUpdate(dps));
                    dispatch(contentUpdate());
                }
            }
        };

        if (
            targetBrand
            && currentSession?.brandId !== targetBrand.brandId
        ) {
            getBrandDps();
        }

        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [targetBrand, currentSession, setBrandDps, analytics, auth]);

    const desktopBrandRendered = useRef(false);

    useEffect(() => {
        if (desktopBrandRendered.current === true) {
            // don't fire tracking event for brand kit card on after render
            return;
        }

        if (brandDps) {
            if (!isSmallScreen() || mobilePersonalizationModalOpen) {
                trackExperimentEngagement(
                    PERSONALIZATION_UX_BRAND_VARIATIONS.Enabled,
                    rawExperiments,
                    analytics,
                    ANALYTICS_EVENT_ACTIONS.PERSONALIZATION_VIEWED,
                    'Brand Kit card displayed',
                    PERSONALIZATION_UX_BRAND_EXPERIMENT_NAME,
                    'Brand Kit card displayed',
                );
            }
            if (!isSmallScreen()) {
                desktopBrandRendered.current = true;
            }
        }
    }, [analytics, brandDps, mobilePersonalizationModalOpen, rawExperiments]);

    const firstRender = useRef(true);

    useEffect(() => {
        if (firstRender.current === true) {
            // don't reset to manual input on initial render
            // also when tracking is added we don't track toggling on first render
            firstRender.current = false;
            return;
        }

        const numImages = brandDpc?.images?.length;

        if (!isSmallScreen()) {
            if (shouldDisplayBrand) {
                trackExperimentEngagement(
                    PERSONALIZATION_UX_BRAND_VARIATIONS.Enabled,
                    rawExperiments,
                    analytics,
                    ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
                    'Personalization Applied',
                    PERSONALIZATION_UX_BRAND_EXPERIMENT_NAME,
                    `Brand Kit toggled on: ${targetBrand?.brandId}`,
                );

                if (numImages) {
                    trackExperimentEngagement(
                        PERSONALIZATION_UX_BRAND_VARIATIONS.Enabled,
                        rawExperiments,
                        analytics,
                        ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
                        'Brand Kit personalization images applied',
                        PERSONALIZATION_UX_BRAND_EXPERIMENT_NAME,
                        `${numImages} Brand kit images applied`,
                    );
                }

                brandDpc?.text?.forEach((element: DesignPersonalizationText) => {
                    trackExperimentEngagement(
                        PERSONALIZATION_UX_BRAND_VARIATIONS.Enabled,
                        rawExperiments,
                        analytics,
                        ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
                        'Brand Kit personalization text applied',
                        PERSONALIZATION_UX_BRAND_EXPERIMENT_NAME,
                        `Text Personalized: ${element.textField.purposeName}`,
                    );
                });

                dispatch(designPersonalizationSessionUpdate(brandDps));
                dispatch(contentUpdate());
                saveBrandAppliedToLocalStorage(true, galleryId);
            } else {
                // reset to manual input
                trackExperimentEngagement(
                    PERSONALIZATION_UX_BRAND_VARIATIONS.Enabled,
                    rawExperiments,
                    analytics,
                    ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
                    'Personalization Applied',
                    PERSONALIZATION_UX_BRAND_EXPERIMENT_NAME,
                    `Brand Kit toggled off`,
                );

                if (selectedDpcImages) {
                    analytics.trackEvent({
                        action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
                        eventLabel: 'Personalization images applied',
                        eventDetail: `${selectedDpcImages.length} Personalization images applied`,
                        ...analytics.getPageProperties(),
                    });
                }

                Object.entries(textfieldValues).forEach(([purposename, text]) => {
                    if (text) {
                        analytics.trackEvent({
                            action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
                            eventLabel: 'Text Personalized',
                            eventDetail: `Text Personalized: ${purposename}`,
                            ...analytics.getPageProperties(),
                        });
                    }
                });

                const manualDpc = {
                    text: personalizedTextToDpcText(textfieldValues),
                    images: selectedDpcImages,
                };

                dispatchDesignPersonalizationManualUpdate?.(manualDpc);
            }

            return;
        }

        // mobile tracking
        if (shouldDisplayBrand && mobilePersonalizationModalOpen) {
            trackExperimentEngagement(
                PERSONALIZATION_UX_BRAND_VARIATIONS.Enabled,
                rawExperiments,
                analytics,
                ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
                'Brand Kit toggled on',
                PERSONALIZATION_UX_BRAND_EXPERIMENT_NAME,
                `Brand Kit toggled on: ${targetBrand?.brandId}`,
            );
        } else {
            trackExperimentEngagement(
                PERSONALIZATION_UX_BRAND_VARIATIONS.Enabled,
                rawExperiments,
                analytics,
                ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
                'Brand Kit toggled off',
                PERSONALIZATION_UX_BRAND_EXPERIMENT_NAME,
                `Brand Kit toggled off: ${targetBrand?.brandId}`,
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldDisplayBrand]);

    const logoPreviewSrc = brandDpc?.images?.[0]?.image?.previewUrl;
    const companyName = getTextFieldValuebyPurpose(brandDpc?.text, PURPOSE_NAMES.COMPANY_NAME);
    const fullName = getTextFieldValuebyPurpose(brandDpc?.text, PURPOSE_NAMES.FULL_NAME);
    const logoOnly = (!companyName && !fullName);
    const localize = useTranslations();

    return brandDpc ? (
        <Card
            fullBleed
            className="brand-container"
            mb={isSmallScreenPersonalizationEnabled ? 'between-subsections' : 6}
            render={(props): JSX.Element => (
                <Button {...props} width="full-width">
                    <FlexBox className="brand-selection-label" flexDirection="row" marginRight="0">
                        {logoPreviewSrc && (
                            <FlexBox className="brand-image-container" margin="3" padding="0">
                                <FluidImage
                                    alt={localize('BrandLogoAltText')}
                                    src={logoPreviewSrc}
                                />
                            </FlexBox>
                        )}
                        <FlexBox className="brand-text-container" flexDirection="column" justifyContent="center" paddingX="3" paddingY="4" textAlign="left">
                            {companyName && <Typography noWrap truncate fontSize="standard" fontWeight="bold" margin="0">{companyName}</Typography>}
                            {fullName && <Typography noWrap truncate fontSize="small" fontWeight="normal" margin="0">{fullName}</Typography>}
                            {logoOnly && <Typography noWrap truncate fontSize="standard" fontWeight="bold" margin="0">{localize('BrandKit')}</Typography>}
                        </FlexBox>
                        <ToggleSwitch
                            hideText
                            activated={shouldDisplayBrand}
                            aria-label={localize('BrandToggleLabel')}
                            className="brand-toggle"
                            onRequestActivatedChange={
                                (): void => setShouldDisplayBrand?.(!shouldDisplayBrand)
                            }
                        >
                            <ToggleSwitchOnLabel>{localize('On')}</ToggleSwitchOnLabel>
                            <ToggleSwitchOffLabel>{localize('Off')}</ToggleSwitchOffLabel>
                        </ToggleSwitch>
                    </FlexBox>
                </Button>
            )}
            onClick={(): void => setShouldDisplayBrand?.(!shouldDisplayBrand)}
        />
    ) : null;
});

BrandSelector.displayName = 'BrandSelector';
