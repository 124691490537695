import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getBooleanFromValue } from 'shared/url';
import { personalizationFlyoutOpenState } from '~/client/atoms/personalizationFlyoutOpenAtom';
import { mobilePersonalizationOpenState } from 'src/client/atoms/mobilePersonalizationOpenAtom';
import { useRecoilState } from 'recoil';

const PERSONALIZATION_MOBILE_QSP = 'personalizationMobileFlyoutOpen';
const PERSONALIZATION_IMAGE_QSP = 'personalizationImageFlyoutOpen';

export type UsePersonalizationFlyoutOpen = {
    uploadModalOpen: boolean,
    setUploadModalOpen: (isOpen: boolean, addMobilePersonalizationQSP?: boolean) => void,
    mobilePersonalizationModalOpen: boolean,
    setMobilePersonalizationModalOpen: (isOpen: boolean) => void,
}

export const usePersonalizationFlyoutOpen = (): UsePersonalizationFlyoutOpen => {
    const [personalizationFlyoutOpen, setPersonalizationFlyoutOpen] = useRecoilState(personalizationFlyoutOpenState);
    const [mobilePersonalizationModalOpen,
        setMobilePersonalizationModalOpen] = useRecoilState(mobilePersonalizationOpenState);
    const [, setSearchParams] = useSearchParams();

    useEffect(() => {
        const openImageModalOnMount = getBooleanFromValue(
            new URLSearchParams(window.location.search).get(PERSONALIZATION_IMAGE_QSP),
        );

        const openPersonalizationModalOnMount = getBooleanFromValue(
            new URLSearchParams(window.location.search).get(PERSONALIZATION_MOBILE_QSP),
        );

        if (openImageModalOnMount) {
            // Mobile needs the personalization modal open in order for child image upload modal to work
            setMobilePersonalizationModalOpen(true);
            setPersonalizationFlyoutOpen(true);
        } else if (openPersonalizationModalOnMount) {
            setMobilePersonalizationModalOpen(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setPersonalizationFlyoutOpenWithEffect = (isOpen: boolean, addMobilePersonalizationQSP = false): void => {
        setSearchParams((params) => {
            if (isOpen) {
                params.delete(PERSONALIZATION_MOBILE_QSP);
                params.set(PERSONALIZATION_IMAGE_QSP, isOpen.toString());
            } else {
                params.delete(PERSONALIZATION_IMAGE_QSP);
                // Handle mobile preview image button and back button press
                // Because they return to mobile personalization modal
                if (addMobilePersonalizationQSP) {
                    params.set(PERSONALIZATION_MOBILE_QSP, 'true');
                }
            }

            return params;
        });
        setPersonalizationFlyoutOpen(isOpen);
    };

    const setMobilePersonalizationModalOpenWithEffect = (isOpen: boolean): void => {
        setSearchParams((params) => {
            if (isOpen) {
                params.delete(PERSONALIZATION_IMAGE_QSP);
                params.set(PERSONALIZATION_MOBILE_QSP, isOpen.toString());
            } else {
                params.delete(PERSONALIZATION_MOBILE_QSP);
            }
            return params;
        });
        setMobilePersonalizationModalOpen(isOpen);
    };

    return {
        uploadModalOpen: personalizationFlyoutOpen,
        setUploadModalOpen: setPersonalizationFlyoutOpenWithEffect,
        mobilePersonalizationModalOpen,
        setMobilePersonalizationModalOpen: setMobilePersonalizationModalOpenWithEffect,
    };
};
