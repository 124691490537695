import { useDispatch, useSelector } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';

import { designVariationsInputSelector } from '~/client/store/quickView/selectors';
import { selectQuickViewFilterNames } from '~/client/store/quickViewFilter';
import { DESIGN_VARIATIONS_QUERY_KEY_NAME, DESIGN_VARIATIONS_QUERY_SETTINGS } from '~/client/hooks/useDesignVariations';
import { queryDesignVariations } from '~/client/queries/queryDesignVariations';
import { Dispatch } from 'redux';

export const usePrefetchQuery = (tileEntity: State.TileEntity): (() => void) | undefined => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    const quickViewFilters = useSelector(selectQuickViewFilterNames);

    if (quickViewFilters.length > 0) {
        return (): void => {
            // Access state bypassing useSelector to prevent unnesesary re-renders after refinement changes
            dispatch((_: Dispatch, getState: () => State.GlobalState) => {
                const designVariationsInput = designVariationsInputSelector(getState())(
                    tileEntity,
                    tileEntity.productOptions,
                    Object.keys(tileEntity.templateUseCases),
                );

                const dvQueryKey: DesignVariations.QueryKey = [DESIGN_VARIATIONS_QUERY_KEY_NAME, designVariationsInput];

                queryClient.prefetchQuery(dvQueryKey, queryDesignVariations, DESIGN_VARIATIONS_QUERY_SETTINGS);
            });
        };
    }

    return undefined;
};
