import { forwardRef } from 'react';
import {
    AlertBox, AlertBoxDismissButton, Span, AlertBoxProps,
} from '@vp/swan';

import { useTranslations } from 'client/hooks/useTranslations';

export const UnwindingAlertBox = forwardRef<HTMLLIElement, AlertBoxProps>(({ dismissed, onRequestDismiss }, ref) => {
    const localize = useTranslations();

    return (
        <AlertBox dismissed={dismissed} mb={6} mt={0} py={4} ref={ref} skin="warning" onRequestDismiss={onRequestDismiss}>
            <Span fontWeight="normal">{localize('UnwindingAlertMessage')}</Span>
            <AlertBoxDismissButton />
        </AlertBox>
    );
});
