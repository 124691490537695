import { useDispatch, useSelector } from 'react-redux';
import {
    ColorSwatch,
    BasicCollapsible,
    SelectionSet,
    SelectionSetInput,
    SelectionSetLabel,
    ColorSwatches,
} from '@vp/swan';

import { useFilterOptions } from 'client/hooks/useFilterOptions';
import {
    buildRefinement,
    refinementAddAndRemove,
} from 'client/store/refinement';
import { ANALYTICS_LABEL, REFINEMENT_DIMENSION } from 'shared/constants';
import { scrollUpToElement } from 'client/utils/scrollToElement';
import { galleryHeaderId } from 'client/components/Gallery/Header/constants';
import { isFilterModalHidden } from 'client/components/Gallery/Header/FilterMenu/utils';
import { useRedirectToL0 } from '~/client/hooks/RedirectToL0/useRedirectToL0';
import { useAnalytics } from '~/client/hooks/gallery/useAnalytics';
import { TRACKING_BEHAVIOR, useExperimentation } from '~/client/hooks/useExperimentation';
import { getRawExperiments } from '~/client/store/experimentation';
import { PERSONALIZATION_UX_BRAND_VARIATIONS, PERSONALIZATION_UX_BRAND_EXPERIMENT_NAME } from '~/experiments/Personalization/constants';
import { trackExperimentEngagement } from '~/shared/ab-testing';

interface PropTypes {
    filter: State.Filter;
}

export const ColorFilter = (props: PropTypes): JSX.Element => {
    const { filter } = props;
    const { options, selected, visible } = useFilterOptions(filter as State.AttributeFilter);
    const dispatch = useDispatch();

    const redirectToL0 = useRedirectToL0();

    // hooks added for temporary Personalization UX experiment tracking
    const isExperimentActive = useExperimentation();
    const rawExperiments = useSelector(getRawExperiments);
    const analytics = useAnalytics();

    const handleSelectedValuesChange = (newSelectedValues: SelectedValues): void => {
        if (isExperimentActive(PERSONALIZATION_UX_BRAND_VARIATIONS.Enabled, TRACKING_BEHAVIOR.Suppress)) {
            trackExperimentEngagement(
                PERSONALIZATION_UX_BRAND_VARIATIONS.Enabled,
                rawExperiments,
                analytics,
                ANALYTICS_LABEL.GALLERY_FILTER_ENGAGEMENT,
                'Filter Update',
                PERSONALIZATION_UX_BRAND_EXPERIMENT_NAME,
                filter.name,
            );
        } else if (isExperimentActive(PERSONALIZATION_UX_BRAND_VARIATIONS.Control, TRACKING_BEHAVIOR.Suppress)) {
            trackExperimentEngagement(
                PERSONALIZATION_UX_BRAND_VARIATIONS.Control,
                rawExperiments,
                analytics,
                ANALYTICS_LABEL.GALLERY_FILTER_ENGAGEMENT,
                'Filter Update',
                PERSONALIZATION_UX_BRAND_EXPERIMENT_NAME,
                filter.name,
            );
        }

        const toAdd: State.Refinement[] = [];
        const toRemove: State.Refinement[] = [];

        for (const [value, isSelected] of Object.entries(newSelectedValues)) {
            const refinement = buildRefinement(value, REFINEMENT_DIMENSION.ATTRIBUTE);

            (isSelected ? toAdd : toRemove).push(refinement);
        }

        if (redirectToL0(toAdd, toRemove)) {
            return;
        }

        dispatch(refinementAddAndRemove(toAdd, toRemove));
        if (isFilterModalHidden()) {
            scrollUpToElement(galleryHeaderId);
        }
    };

    return (
        <>
            {!visible && null}
            {visible && (
                <BasicCollapsible
                    aria-label={filter.title}
                    className="filter"
                    collapsibleId={filter.name}
                    heading={filter.title}
                >
                    <SelectionSet
                        aria-label={filter.title}
                        selectedValues={selected}
                        variant="multi-select"
                        onSelectedValuesChange={handleSelectedValuesChange}
                    >
                        <ColorSwatches>
                            {options.map((o) => {
                                const color = o.otherProperties?.drawingColor;

                                return (
                                    <SelectionSetInput
                                        disabled={o.disabled}
                                        key={o.value}
                                        value={o.value}
                                    >
                                        <SelectionSetLabel>
                                            <ColorSwatch aria-label={o.title} as="span" primaryColor={color!} title={o.title}>
                                                {o.title && <span className="screen-reader-text">{o.title}</span>}
                                            </ColorSwatch>
                                        </SelectionSetLabel>
                                    </SelectionSetInput>
                                );
                            })}
                        </ColorSwatches>
                    </SelectionSet>
                </BasicCollapsible>
            )}
        </>
    );
};

ColorFilter.displayName = 'ColorFilter';
