import { memo } from 'react';
import {
    ColorSwatches,
    SelectionSet,
} from '@vp/swan';
import { useTranslations } from 'client/hooks/useTranslations';
import { hash } from 'client/utils/hash';
import { ColorSwatchWrapper } from '~/client/components/common/ColorSwatchPicker/components/ColorSwatchWrapper';
import { ColorSwatchPickerProps } from '~/client/components/common/ColorSwatchPicker/interface';
import { getIsColorSwatchesSimilarColors } from '~/client/utils/getColorSwatchColors';

function getRegionIdBasedOnColors(colorSwatches: Gallery.ContentQuery.ColorSwatch[]): string {
    return hash(colorSwatches.map((colorSwatch) => colorSwatch.designId).join(''));
}

export const ColorSwatchPicker = memo((props: ColorSwatchPickerProps): JSX.Element => {
    const {
        ariaLabelledBy,
        colorSwatches,
        colorSwatchesProps = {},
        entityId = null,
        onColorSwatchChange,
        selectedDesignId,
        locale,
        title,
    } = props;

    const localize = useTranslations();

    // If props.entityId is not provided, hash the available color strings.
    const colorRegionId = `colors-${entityId || getRegionIdBasedOnColors(colorSwatches)}`;

    const isColorSwatchColorsSimilar = getIsColorSwatchesSimilarColors(colorSwatches);

    const onColorSwatchChangeWrapper = (selectedValue: string | number | null): void => {
        const stringifiedValue = selectedValue?.toString() || '';

        onColorSwatchChange(stringifiedValue);
    };

    return (
        <>
            {(colorSwatches.length <= 1) && null}
            {(colorSwatches.length > 1) && (
                <div
                    aria-labelledby={ariaLabelledBy}
                    className="color-swatch-picker"
                >
                    { title && title()}
                    <SelectionSet
                        aria-label={localize('ColorSwatchGroupLabel')}
                        selectedValue={selectedDesignId}
                        variant="single-select"
                        onSelectedValueChange={onColorSwatchChangeWrapper}
                    >
                        <ColorSwatches id={colorRegionId} {...colorSwatchesProps}>
                            {colorSwatches.map((colorSwatch) => (
                                <ColorSwatchWrapper
                                    colorSwatch={colorSwatch}
                                    isColorSwatchColorsSimilar={isColorSwatchColorsSimilar}
                                    key={colorSwatch.designId}
                                    locale={locale}
                                />
                            ))}
                        </ColorSwatches>
                    </SelectionSet>
                </div>
            )}
        </>
    );
});

ColorSwatchPicker.displayName = 'ColorSwatchPicker';
