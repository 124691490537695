import { AnyAction } from 'redux';
import {
    DESIGN_PERSONALIZATION_SESSION_UPDATE,
    DPCS_LOADING_UPDATE,
} from './reducer';

export const designPersonalizationSessionUpdate = (dps?: VP.DesignPersonalization.Models.DesignPersonalizationContextService.PersonalizationSessionResponse | undefined): AnyAction => ({
    type: DESIGN_PERSONALIZATION_SESSION_UPDATE,
    payload: { dps },
});

export const dpcsLoadingUpdate = (dpcsLoading: boolean): AnyAction => ({
    type: DPCS_LOADING_UPDATE,
    payload: { dpcsLoading },
});
