/* eslint-disable jsx-a11y/anchor-is-valid */
import {
    basicImageFileTypeMappings,
    LocalizationProvider,
    UploadConfigurationProvider,
    UploadManagerProvider,
    UploadManagerProviderProps,
} from '@design-stack-vista/upload-components';
import type { DesignPersonalizationContext, DesignPersonalizationImage } from '@vp/personalization-types';
import { useDispatch, useSelector } from 'react-redux';
import {
    ComponentProps,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useMutation } from '@tanstack/react-query';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { useBrandContext } from '@vp/react-brand';
import { useAuth } from '~/client/hooks/useAuth';
import { useTranslations } from '~/client/hooks/useTranslations';
import { LOCAL_STORAGE_KEYS, MAX_PHOTO_UPLOADS, IMAGE_UPLOAD_STATES } from 'client/constants';
import { getLogger } from 'client/utils/gallery/logger';
import { useAnalytics } from 'client/hooks/gallery/useAnalytics';
import { ANALYTICS_EVENT_ACTIONS } from 'shared/constants';
import { designPersonalizationContextSelector, designPersonalizationSessionSelector } from '~/client/store/designPersonalization/selectors';
import { SubBrands, Tenants, VistaAsset } from '@design-stack-vista/vista-assets-sdk';
import { contentUpdate } from '~/client/store/content';
import { booleanRenderPropertySelector, getGalleryIdSelector } from '~/client/store/config';
import { RenderProperty } from 'shared/renderProperties';
import { imageUploadState } from '~/client/atoms/imageUploadStateAtom';
import { isSmallScreen } from '~/client/utils/deviceDetection';
import { PersonalizationReactContext } from '~/client/contexts/PersonalizationReactContext';
import { selectedUploadsState } from '~/client/atoms/selectedUploadsStateAtom';
import { createDesignPersonalizationSession, updateDesignPersonalizationSession } from '~/client/mutations/designPersonalizationSessionMutations';
import { designPersonalizationSessionUpdate, dpcsLoadingUpdate } from '~/client/store/designPersonalization/actions';
import { DesignPersonalizationContextService } from '~/services/DesignPersonalizationContextService';
import { TRACKING_BEHAVIOR, useExperimentation } from '~/client/hooks/useExperimentation';
import { PERSONALIZATION_UX_BRAND_VARIATIONS } from '~/experiments/Personalization/constants';
import { useExpectImagesToBePhotos } from './useSpecifyImageCopy';
import {
    brandLocalStorageKeyBuilder, buildDesignPersonalizationData, saveBrandAppliedToLocalStorage, saveToLocalStorage,
} from './designPersonalizationSessionHelpers';
import {
    dpcToAppliedUploads, dpcToBrandContent, dpcToTextFields,
} from './dpcConverters';
import { usePurposeNames } from '../../Header/PersonalizationV1/hooks/usePurposeNames';
import { MAX_UPLOADS } from './constants';
import { usePersonalizationFlyoutOpen } from '../../Header/PersonalizationV1/hooks';

type DPS = VP.DesignPersonalization.Models.DesignPersonalizationContextService.PersonalizationSessionResponse;

const LOGGER = getLogger();

export const PersonalizationWrapper = (props: ComponentProps<typeof UploadConfigurationProvider>): JSX.Element | null => {
    const {
        children,
    } = props;

    const analytics = useAnalytics();
    const defaultToPhoto = useExpectImagesToBePhotos();
    const [selectedUploads, setSelectedUploads] = useRecoilState(selectedUploadsState);
    const [selectedDpcImages, setSelectedDpcImages] = useState<DesignPersonalizationImage[] | undefined>(undefined);
    const designPersonalizationContext = useSelector(designPersonalizationContextSelector);
    const dispatch = useDispatch();
    const galleryId = useSelector(getGalleryIdSelector);
    const [localUploadedAssets, setLocalUploadedAssets] = useState<VistaAsset[]>();
    const setImageUploadState = useSetRecoilState(imageUploadState);
    const auth = useAuth();
    const accessToken = auth?.accessToken ?? undefined;
    const [textfieldValues, setTextfieldValues] = useState<Record<string, string>>({});
    const [mobileTextfieldValues, setMobileTextfieldValues] = useState<Record<string, string>>({});
    const isClientSide = useMemo(() => typeof window !== 'undefined', []);
    const localStorageKey = LOCAL_STORAGE_KEYS.PERSISTED_DPSESSION + galleryId;
    const brandLocalStorageKey = brandLocalStorageKeyBuilder(galleryId);
    const isExperimentActive = useExperimentation();
    const isBrandExperimentEnabled = isExperimentActive(
        PERSONALIZATION_UX_BRAND_VARIATIONS.Enabled,
        TRACKING_BEHAVIOR.Suppress,
    );
    const booleanRenderProperty = useSelector(booleanRenderPropertySelector);
    const [isSmallScreenPersonalizationEnabled,
        setIsSmallScreenPersonalizationEnabled] = useState(booleanRenderProperty(RenderProperty.ShowPersonalizationUI));
    const localize = useTranslations();
    // manualAppliedImages keeps track of current manual images actually applied onto templates
    // When the image upload modal is open, those changes are not applied to templates until apply or preview is pressed
    // We also can't get applied manual images from the dpc because it could potentially have brand logos
    const [
        manualAppliedImages,
        setManualAppliedImages,
    ] = useState<Gallery.Models.Personalization.UploadIdentifier[] | undefined>(undefined);
    // shouldDisplayBrand is the unapplied brand toggle state when within the mobile personalization modal
    const [shouldDisplayBrand, setShouldDisplayBrand] = useState(false);
    // mobileBrandEnabled should be aligned with whether the brand is currently applied to templates on mobile
    const [mobileBrandEnabled, setMobileBrandEnabled] = useState(false);
    const [brandDps, setBrandDps] = useState<DPS | undefined>(undefined);
    // manualSessionId represents the design personalization session associated with personalization widget (not brand)
    const [manualSessionId, setManualSessionId] = useState<string | undefined>(undefined);
    const [manualSessionBrandId, setManualSessionBrandId] = useState<string | undefined>(undefined);

    // currentSession is whatever personalization is actually applied to previews
    const currentSession = useSelector(designPersonalizationSessionSelector);
    const createDesignPersonalizationSessionMutation = useMutation(createDesignPersonalizationSession);
    const updateDesignPersonalizationSessionMutation = useMutation(updateDesignPersonalizationSession);
    const purposeNames = usePurposeNames();
    const brandcontext = useBrandContext();
    const { createBrand, updateBrand } = brandcontext.api;
    const shouldStoreToBrand = booleanRenderProperty(RenderProperty.PersonalizationStoreToBrand);
    const brandContext = useBrandContext();
    const { mobilePersonalizationModalOpen } = usePersonalizationFlyoutOpen();

    useEffect(() => {
        if (!isSmallScreen()) {
            setIsSmallScreenPersonalizationEnabled(false);
        }
    }, []);

    /*
     * Wrapping the targetBrand determination in a useMemo only dependent on the brand context prevents issue
     * where the Brand Selector is shown for a newly created Gallery brand by ensuring we only calulate the target
     * brand once.
     *
     * This occurs in the Provider to ensure this useMemo doesn't get run again on component mount/unmount e.g. when
     * the modal dialog closes in mobile.
     */
    const targetBrand = useMemo(
        () => brandContext.activeBrand || brandContext.brands?.[0],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [brandContext.isInitialized],
    );

    useEffect(() => {
        // Don't track exposure for logged in users with no brand
        // There is no difference in UI between control and test groups
        const trackIfBrandExists = targetBrand ? TRACKING_BEHAVIOR.Default : TRACKING_BEHAVIOR.Suppress;

        const checkAndFireExperimentExposure = (): void => {
            // logged out footer is different between experience and control
            if (!auth?.isSignedIn) {
                if (!isExperimentActive(PERSONALIZATION_UX_BRAND_VARIATIONS.Enabled)) {
                    isExperimentActive(PERSONALIZATION_UX_BRAND_VARIATIONS.Control);
                }
            } else if (!isExperimentActive(PERSONALIZATION_UX_BRAND_VARIATIONS.Enabled, trackIfBrandExists)) {
                isExperimentActive(PERSONALIZATION_UX_BRAND_VARIATIONS.Control, trackIfBrandExists);
            }
        };

        if (auth?.isSignedIn === undefined) {
            return;
        }

        if (!isSmallScreen()) {
            checkAndFireExperimentExposure();
        }

        if (isSmallScreen() && mobilePersonalizationModalOpen) {
            checkAndFireExperimentExposure();
        }
    }, [auth?.isSignedIn, brandDps, isExperimentActive, mobilePersonalizationModalOpen, targetBrand]);

    const dispatchDesignPersonalizationManualUpdate = useCallback(async (
        newData: Partial<DesignPersonalizationContext>,
    ): Promise<void> => {
        // treat newData as modifications to the current data only if the current session is for manual inputs
        const designPersonalizationData = (currentSession?.designPersonalizationSessionId === manualSessionId)
            // use buildDesignPersonalizationData to include versions and check for actual content
            ? buildDesignPersonalizationData({
                ...currentSession?.designPersonalizationContext.designPersonalizationData,
                ...newData,
            })
            : buildDesignPersonalizationData(newData);

        const brandContent = designPersonalizationData && dpcToBrandContent(designPersonalizationData);

        let newSession: DPS | undefined;
        let brandId = manualSessionBrandId;

        if (shouldStoreToBrand) {
            if (!brandId && brandcontext.brands.length === 0 && !!brandContent) {
                const newBrand = await createBrand(brandContent, false);

                brandId = newBrand.brandId;
            } else if (brandId) {
                // patchBrand will update only data specified in request
                // updateBrand will replace all data. TODO: Switch to patchBrand when it works
                updateBrand({
                    brandId,
                    isActive: false,
                    isFactual: true,
                    ...brandContent,
                });
            }
        }

        if (designPersonalizationData) {
            if (manualSessionId) {
                newSession = await updateDesignPersonalizationSessionMutation.mutateAsync({
                    sessionId: manualSessionId,
                    designPersonalizationData,
                    brandId,
                    accessToken,
                });
            } else {
                newSession = await createDesignPersonalizationSessionMutation.mutateAsync({
                    designPersonalizationData,
                    brandId,
                    accessToken,
                });
                setManualSessionId(newSession.designPersonalizationSessionId);
                setManualSessionBrandId(newSession.brandId);
            }
        } // else clear local storage and return undefined

        saveToLocalStorage(newSession?.designPersonalizationSessionId, galleryId);
        saveBrandAppliedToLocalStorage(false, galleryId);

        dispatch(designPersonalizationSessionUpdate(newSession));
        dispatch(dpcsLoadingUpdate(false));
        // This IF is a hack to make text personalization smoother.
        // If we boost on text fields, or Design Conductor otherwise needs the dps,
        // we will have to call contentUpdate on every dispatch.
        if (newData?.images) {
            dispatch(contentUpdate());
        }
    }, [currentSession?.designPersonalizationSessionId,
        currentSession?.designPersonalizationContext.designPersonalizationData,
        manualSessionId, manualSessionBrandId, shouldStoreToBrand, galleryId,
        dispatch, brandcontext.brands.length, createBrand, updateBrand,
        updateDesignPersonalizationSessionMutation, accessToken,
        createDesignPersonalizationSessionMutation]);

    const handleAssetDelete = async (asset: VistaAsset): Promise<void> => {
        // Remove deleted asset from selected photos to prevent selection state from desyncing
        const newSelectedUploads = selectedUploads.filter((upload) => upload.assetId !== asset.data?.id);

        setSelectedUploads(newSelectedUploads);

        // If applied photos doesn't include the deleted asset, don't do anything
        if (!(designPersonalizationContext?.images?.find((i) => i.image.sherbertAssetId === asset.data?.id))) {
            return;
        }

        const newDpcImages = designPersonalizationContext.images.filter(
            (i) => i.image.sherbertAssetId !== asset.data?.id,
        );

        dispatchDesignPersonalizationManualUpdate({
            images: newDpcImages,
        });

        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
            eventLabel: 'Image Deleted',
            eventDetail: `Image Deleted`,
            ...analytics.getPageProperties(),
        });
    };

    const handleUploadError = useCallback((error?: Error, uploadedAssets?: VistaAsset[]): void => {
        setImageUploadState({ status: IMAGE_UPLOAD_STATES.ERROR, message: error?.message });

        LOGGER.error(`Failed to upload image(s): ${error}`, error, { uploadedAssets });

        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.IMAGE_UPLOADED,
            eventLabel: 'Image Upload Error',
            eventDetail: `Image Upload Error: ${error}`,
            ...analytics.getPageProperties(),
        });
    }, [analytics, setImageUploadState]);

    const handleUploadComplete = useCallback(() => {
        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.IMAGE_UPLOADED,
            eventLabel: 'Image Upload Success',
            eventDetail: 'Image Upload Success',
            ...analytics.getPageProperties(),
        });
    }, [analytics]);

    const handleUploadStart: UploadManagerProviderProps['onUpload'] = async (selectedAssets, _uploadedBlobs, nonUploadedBlobs) => {
        setImageUploadState({ status: IMAGE_UPLOAD_STATES.LOADING });

        // Do nothing if there are no selected assets or there are any non-uploaded files
        if (!selectedAssets.length || nonUploadedBlobs.length) {
            setImageUploadState({ status: IMAGE_UPLOAD_STATES.READY });
            return;
        }

        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.IMAGE_UPLOADED,
            eventLabel: 'Image Upload Started',
            eventDetail: `Image Upload Started`,
            ...analytics.getPageProperties(),
        });

        /**
         * Wait for all files to finish uploading
         * onError handler could be called for any of these uploads which doesn't result in a thrown error
         */
        const uploadedAssets = (await Promise.all(selectedAssets)).filter((asset) => !!asset) as VistaAsset[];
        const allAssetsUploaded: boolean = uploadedAssets.length === selectedAssets.length;

        /**
         * If not all files resolve with an asset, don't apply any of the uploads as any failed uploads
         * would have triggered an error state.
         */
        if (!allAssetsUploaded) {
            setImageUploadState({ status: IMAGE_UPLOAD_STATES.ERROR });
            return;
        }

        setLocalUploadedAssets(uploadedAssets);
    };

    // Add uploaded images to selected images after they finish uploading
    useEffect(() => {
        const convertAndSetPreviews = async (uploadedAssets: VistaAsset[], photosToSelect: number): Promise<void> => {
            const newUploads = uploadedAssets.slice(0, photosToSelect).map((asset) => ({ assetId: asset.data?.id ?? '' }));

            setSelectedUploads([...selectedUploads, ...newUploads]);

            analytics.trackEvent({
                action: ANALYTICS_EVENT_ACTIONS.IMAGE_UPLOADED,
                eventLabel: 'Image Upload Completed',
                eventDetail: `Image Upload(s) and Processing Completed`,
                ...analytics.getPageProperties(),
            });
        };

        if (localUploadedAssets) {
            try {
                const photosToSelect = Math.max(0, MAX_UPLOADS - selectedUploads.length);

                convertAndSetPreviews(localUploadedAssets, photosToSelect);
            } catch (e) {
                handleUploadError(e as Error, localUploadedAssets);
            } finally {
                setImageUploadState({ status: IMAGE_UPLOAD_STATES.READY });
                setLocalUploadedAssets(undefined);
            }
        }
    }, [analytics,
        defaultToPhoto,
        handleUploadError,
        localUploadedAssets,
        setImageUploadState,
        selectedUploads,
        setSelectedUploads,
    ]);

    const gatherMobileInputs = useCallback((): Record<string, string> => {
        const newPersonalizedText: Record<string, string> = {};

        if (mobileTextfieldValues) {
            Object.keys(mobileTextfieldValues).forEach((purposeName) => {
                const textfieldValue = mobileTextfieldValues[purposeName];

                if (textfieldValue !== '') {
                    newPersonalizedText[purposeName] = textfieldValue;
                }

                analytics.trackEvent({
                    action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
                    eventLabel: 'Text Personalized',
                    eventDetail: `Text Personalized (mobile): ${purposeName}`,
                    ...analytics.getPageProperties(),
                });
            });
        }

        return newPersonalizedText;
    }, [analytics, mobileTextfieldValues]);

    const uploadAuthProvider = useMemo(() => (auth ? {
        getAuthHeaders: async (): Promise<{ Authorization: string }> => ({ Authorization: `Bearer ${auth?.accessToken}` }),
    } : undefined), [auth]);

    useEffect(() => { // Restore
        const localStorageData = localStorage.getItem(localStorageKey);
        const brandLocalStorageData = localStorage.getItem(brandLocalStorageKey);

        if (brandLocalStorageData === 'true' && isBrandExperimentEnabled) {
            setShouldDisplayBrand(true);
            setMobileBrandEnabled(true);
        }

        if (!localStorageData || !isClientSide || !accessToken) {
            return;
        }

        // Restore Redux state
        const restorePersistedDesignPersonalizationSession = async (setActive: boolean): Promise<void> => {
            const dpcsResponse = await DesignPersonalizationContextService.getDesignPersonalizationSession(
                localStorageData,
                accessToken,
            );
            const appliedUploads = dpcToAppliedUploads(
                dpcsResponse.designPersonalizationContext.designPersonalizationData,
            );

            if (setActive) {
                dispatch(designPersonalizationSessionUpdate(
                    dpcsResponse,
                ));
                dispatch(contentUpdate());
            }

            setTextfieldValues(dpcToTextFields(
                purposeNames,
                dpcsResponse.designPersonalizationContext.designPersonalizationData,
            ));

            setMobileTextfieldValues(dpcToTextFields(
                purposeNames,
                dpcsResponse.designPersonalizationContext.designPersonalizationData,
            ));

            setSelectedDpcImages(dpcsResponse.designPersonalizationContext.designPersonalizationData.images);
            setSelectedUploads(appliedUploads);
            setManualAppliedImages(appliedUploads);
            setManualSessionId(dpcsResponse.designPersonalizationSessionId);
            setManualSessionBrandId(dpcsResponse.brandId);
        };

        const shouldApplyManualPersonalization = !isBrandExperimentEnabled || (brandLocalStorageData !== 'true');

        restorePersistedDesignPersonalizationSession(shouldApplyManualPersonalization);
        // If purposeNames is included in dependencies restore happens while typing
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isClientSide, accessToken, localStorageKey, brandLocalStorageKey, setSelectedUploads, dispatch]);

    const contextValue = useMemo(() => ({
        textfieldValues,
        setTextfieldValues,
        isSmallScreenPersonalizationEnabled,
        mobileTextfieldValues,
        setMobileTextfieldValues,
        brandDps,
        setBrandDps,
        shouldDisplayBrand,
        setShouldDisplayBrand,
        mobileBrandEnabled,
        setMobileBrandEnabled,
        manualAppliedImages,
        setManualAppliedImages,
        gatherMobileInputs,
        dispatchDesignPersonalizationManualUpdate,
        selectedDpcImages,
        setSelectedDpcImages,
        targetBrand,
    }), [
        brandDps,
        dispatchDesignPersonalizationManualUpdate,
        gatherMobileInputs,
        isSmallScreenPersonalizationEnabled,
        mobileBrandEnabled,
        manualAppliedImages,
        mobileTextfieldValues,
        shouldDisplayBrand,
        textfieldValues,
        selectedDpcImages,
        targetBrand,
    ]);

    return (
        <PersonalizationReactContext.Provider value={contextValue}>
            <UploadConfigurationProvider
                maxUploads={MAX_PHOTO_UPLOADS}
                supportedFileTypes={basicImageFileTypeMappings.filter((type) => type.fileType !== 'application/pdf')}
            >
                <UploadManagerProvider
                    assetsExpireOn={process.env.NODE_ENV === 'production' ? 'never' : 'P1D'}
                    authProvider={uploadAuthProvider}
                    experience="gallery-brand-personalization"
                    subBrand={SubBrands.VistaPrint}
                    writeTenant={process.env.NODE_ENV === 'production' ? Tenants.VistaPrint : Tenants.VistaNonProd}
                    onAssetDeleted={handleAssetDelete}
                    onError={handleUploadError}
                    onUpload={handleUploadStart}
                    onUploadCompleted={handleUploadComplete}
                >
                    <LocalizationProvider
                        localizedValues={{
                            deletingLabel: localize('PhotoPersonalizationImageSelectionAssetDeletingLabel'),
                            deleteConfirmationKeys: {
                                closeButton: 'Close Delete Image Dialog',
                                dialogTitle: localize('PersonalizationModalDeleteImageConfirmationHeader'),
                                dialogMessage: localize('PersonalizationModalDeleteImageConfirmationDescription'),
                                cancelButton: localize('Cancel'),
                                confirmButton: localize('Confirm'),
                            },
                            deleteButtonTitle: localize('PhotoPersonalizationDeleteImageButton'),
                            assetInUseLabel: localize('PhotoPersonalizationImageUsedHoverText'),
                            loadingLabel: localize('PhotoPersonalizationImageSelectionAssetLoadingLabel'),
                            uploadingLabel: localize('PhotoPersonalizationImageSelectionAssetUploadingLabel'),
                        }}
                    >

                        {children}
                    </LocalizationProvider>
                </UploadManagerProvider>
            </UploadConfigurationProvider>
        </PersonalizationReactContext.Provider>
    );
};
