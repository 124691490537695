import { useQuery } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import DOMPurify from 'isomorphic-dompurify';
import { pricingContextAtom } from '~/client/atoms/pricingContext';
import { useBatchPricing } from '~/client/hooks/useBatchPricing';
import { QUERY_KEY, queryPricingText } from '~/client/queries/queryPricingText';
import { PRICING_PRESENTATION_TYPES } from '~/client/constants';
import { PricingParameters, TemplatePricingProps } from '~/client/components/Gallery/DesignTile/interface';
import { booleanRenderPropertySelector, getLocaleSelector } from '~/client/store/config';
import { useSelector } from 'react-redux';
import { getPricingPresentationType } from '~/client/utils/getPricingPresentationType';
import { PRICING_ONE_LINE, PRICING_TWO_LINES } from '~/client/components/Gallery/DesignTile/constants';
import { getProductOptionsHash } from '~/client/utils/getProductOptionsHash';
import { RenderProperty } from '~/shared/renderProperties';

export const usePricing = ({
    tileEntity,
    differentialPrice,
    pricingPresentationType,
    market,
    quantity,
    hideVatMessage,
    hideShippingMessageInVatMessage,
    selectedProductOptions,
    productOptionsByProductKey = null,
    pricingPresentationHTMLModifier = (html: string): string => (html),
}: TemplatePricingProps): PricingParameters => {
    const locale = useSelector(getLocaleSelector);
    const pricingContext = useRecoilValue(pricingContextAtom);
    const retrievedPricingContext = pricingContext?.getPricingContext();
    const booleanRenderProperty = useSelector(booleanRenderPropertySelector);

    const {
        productKey,
        productVersion,
        fullProductOptions,
        productOptionsHash: productOptionsHashProp,
        comparativeName,
    } = tileEntity;

    const showComparativeNames = booleanRenderProperty(RenderProperty.ShowComparativeNames);
    const shouldShowComparativeNames = showComparativeNames && !!comparativeName;

    const templateTileTitle = comparativeName || '';
    const resolvedPricingPresentation = differentialPrice && pricingPresentationType
        ? getPricingPresentationType(pricingPresentationType)
        : pricingPresentationType;

    const { differentialPriceData, productQuantity } = useBatchPricing({
        productOptionsByProductKey,
        productKey,
        productVersion,
        differentialPrice,
        fullProductOptions,
        locale,
        market,
        quantity,
        pricingContext,
        retrievedPricingContext,
    });

    const enabled = !!differentialPriceData
        && !!productKey
        && !!productVersion
        && !!productQuantity;

    const {
        isLoading,
        isError,
        data: formattedPrices,
    } = useQuery(
        [QUERY_KEY, {
            pricingResponse: differentialPriceData || undefined,
            quantity: productQuantity,
            locale,
            vatInc: retrievedPricingContext?.vatInclusive || false,
            hideVatMessage: hideVatMessage || differentialPrice,
            pricingPresentationType: resolvedPricingPresentation || '',
            hideShippingMessageInVatMessage: hideShippingMessageInVatMessage || false,
        }],
        queryPricingText,
        { enabled },
    );

    const productOptionsHash = productOptionsHashProp || getProductOptionsHash(selectedProductOptions || {});
    const pricingHtml = (productOptionsHash && formattedPrices)
        ? pricingPresentationHTMLModifier(formattedPrices[productOptionsHash])
        : undefined;
    const shouldRenderSpacingBlock = (!isLoading && (!pricingHtml || isError));
    const sanitizedPricingHtml = pricingHtml ? DOMPurify.sanitize(pricingHtml) : '';
    const lines = pricingPresentationType === PRICING_PRESENTATION_TYPES.RAW_PRICE
        ? PRICING_ONE_LINE
        : PRICING_TWO_LINES;

    return {
        shouldRenderSpacingBlock,
        sanitizedPricingHtml,
        lines,
        templateTileTitle,
        isLoading,
        shouldShowComparativeNames,
    };
};
