/* eslint-disable react/no-array-index-key */
import { HTMLProps } from 'react';
import { useSelector } from 'react-redux';

import { tileEntityByIdSelector } from 'client/store/tileEntity';
import { getDebugModeEnabled } from 'client/store/debug';
import { getHighlightCategories, getHighlightKeywords } from '~/client/store/debug/reducer';
import { Typography } from '@vp/swan';
import { BoostChip } from '~/client/components/Gallery/PreviewArea/BoostedTerms/BoostChip';

export interface PropTypes extends HTMLProps<HTMLDivElement> {
    entityId: string;
}

export const BoostedTerms = (props: PropTypes): JSX.Element | null => {
    const { entityId } = props;
    const debugEnabled = useSelector(getDebugModeEnabled);
    const tileEntityById = useSelector(tileEntityByIdSelector);
    const tileEntity = tileEntityById(entityId);

    const highlightedKeywords = useSelector(getHighlightKeywords);
    const highlightedCategories = useSelector(getHighlightCategories);

    const allHighlightedTerms = [
        ...(highlightedKeywords?.map((keyword, index) => ({ term: keyword, type: 'keyword', index })) || []),
        ...(highlightedCategories?.map((category, index) => ({ term: category, type: 'category', index: index + (highlightedKeywords?.length || 0) })) || []),
    ];

    const tileHighlightedTerms = allHighlightedTerms.filter(
        (term) => tileEntity?.keywords?.some((k) => k.toLowerCase() === term.term.toLowerCase())
        || tileEntity?.categoryNames?.some((c) => c.toLowerCase() === term.term.toLowerCase()),
    );

    if (!debugEnabled || !tileHighlightedTerms?.length) {
        return null;
    }

    return (
        <div className="boosted-terms">
            <Typography fontSize="standard" fontWeight="bold">Boosted Terms</Typography>
            <div className="boosted-terms__chipContainer">
                {tileHighlightedTerms.map((term) => (
                    <BoostChip index={term.index} key={term.type + term.term} term={term.term} type={term.type as 'keyword' | 'category'} />
                ))}
            </div>
        </div>
    );
};
