export const findClosestVariation = (
    usableVariations: DesignVariations.DesignVariation[],
    selections: DesignVariations.VariableSelections,
): DesignVariations.DesignVariation => {
    let maxOverlappingSelections = 0;
    let winningVariation = usableVariations[0];

    usableVariations.forEach((variation) => {
        const overlappingSelections = Object.entries(selections)
            .filter((option) => variation.variationSelections[option[0]] === option[1]).length;

        if (overlappingSelections > maxOverlappingSelections) {
            maxOverlappingSelections = overlappingSelections;
            winningVariation = variation;
        }
    });

    return winningVariation;
};
