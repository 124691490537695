import { Button, Icon } from '@vp/swan';
import { useAnalytics } from '~/client/hooks/gallery/useAnalytics';
import { ANALYTICS_EVENT_ACTIONS } from '~/shared/constants';
import { useTranslations } from 'client/hooks/useTranslations';
import { useAuth } from '~/client/hooks/useAuth';
import { usePersonalizationFlyoutOpen } from '../hooks';

export const HeaderPersonalizationButton = (): JSX.Element => {
    const { setMobilePersonalizationModalOpen } = usePersonalizationFlyoutOpen();
    const localize = useTranslations();
    const analytics = useAnalytics();
    const auth = useAuth();

    const handleModalOpen = ((): void => {
        if (!auth) {
            return;
        }
        setMobilePersonalizationModalOpen(true);

        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.FLY_OUT_VIEWED,
            eventLabel: 'Personalization Button',
            eventDetail: 'Personalization Mobile Modal Open',
            ...analytics.getPageProperties(),
        });
    });

    return (
        <Button
            aria-labelledby="personalization-button-label"
            buttonShape="round"
            className="mobile-rounded-icon-button mobile-personalization-button"
            iconPosition="left"
            paddingX="5"
            skin="primary"
            width="full-width"
            onClick={handleModalOpen}
        >
            {localize('PersonalizationFilterHeader')}
            <Icon className="personalization-button-icon" iconType="magic" />
        </Button>

    );
};

HeaderPersonalizationButton.displayName = 'HeaderPersonalizationButton';
