import { useDispatch, useSelector } from 'react-redux';

import { refinementRemove } from 'client/store/refinement';
import { useRedirectToL0 } from '~/client/hooks/RedirectToL0/useRedirectToL0';
import { REFINEMENT_DIMENSION } from '~/shared/constants';
import { getIsL1orL2 } from '../store/config';

type CurryClearAllFunc = (selected: Refinements.Refinements) => (event: React.MouseEvent<HTMLButtonElement>) => void;

export const useClearRefinements = (): { curryClearAll: CurryClearAllFunc } => {
    const dispatch = useDispatch();
    const isL1orL2 = useSelector(getIsL1orL2);
    const redirectToL0 = useRedirectToL0();

    const curryClearAll = (selected: Refinements.Refinements) => (event: React.MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();
        const selectedRefinements = { ...selected };

        if (isL1orL2) {
            for (const refinement in selectedRefinements) {
                if (
                    Object.hasOwn(selectedRefinements, refinement)
                    && selectedRefinements[refinement].dimension === REFINEMENT_DIMENSION.CATEGORY
                ) {
                    delete selectedRefinements[refinement];
                }
            }
        }

        const refinements = Object.values(selectedRefinements);

        if (redirectToL0([], refinements)) {
            return;
        }

        dispatch(refinementRemove(refinements));
    };

    return { curryClearAll };
};
