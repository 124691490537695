/* eslint-disable jsx-a11y/anchor-is-valid */
import {
    ModalDialog,
} from '@vp/swan';
import { useContext } from 'react';
import { usePersonalizationFlyoutOpen } from 'client/components/Gallery/Header/PersonalizationV1/hooks';
import { useAnalytics } from 'client/hooks/gallery/useAnalytics';
import { ANALYTICS_EVENT_ACTIONS } from 'shared/constants';
import { PersonalizationReactContext } from '~/client/contexts/PersonalizationReactContext';
import { selectedUploadsState } from '~/client/atoms/selectedUploadsStateAtom';
import { useSetRecoilState } from 'recoil';
import { PersonalizationImageModalContent } from './PersonalizationImageModalContent';
import { ImageModalOpenButton } from './ImageModalOpenButton';

export const PersonalizationImageModal = (): JSX.Element => {
    const {
        isSmallScreenPersonalizationEnabled,
        manualAppliedImages,
    } = useContext(PersonalizationReactContext);
    const analytics = useAnalytics();

    const setSelectedUploads = useSetRecoilState(selectedUploadsState);
    const { uploadModalOpen, setUploadModalOpen } = usePersonalizationFlyoutOpen();

    const handleDialogClose = (): void => {
        // This fires whenever the modal closes, including when uploads are submitted
        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.FLY_OUT_CLOSED,
            eventLabel: 'Personalization modal closed',
            eventDetail: `Personalization modal closed`,
            ...analytics.getPageProperties(),
        });
        setUploadModalOpen(false);

        if (!isSmallScreenPersonalizationEnabled) {
            setSelectedUploads(manualAppliedImages || []);
        }
    };

    return (
        <>
            <ImageModalOpenButton />
            {!isSmallScreenPersonalizationEnabled
                && (
                    <ModalDialog
                        onlyRenderWhenOpen
                        bodyWidth="capped"
                        className="personalization-image-modal"
                        isOpen={uploadModalOpen}
                        variant="panel-right"
                        onRequestDismiss={handleDialogClose}
                    >
                        <PersonalizationImageModalContent />
                    </ModalDialog>
                )}
        </>
    );
};
