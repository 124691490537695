export enum BOOST_VARIATIONS {
    Control = 'BoostControl',
    Enabled = 'BoostEnabled',
}

export const MIN_RELEVANT_BOOST_COUNT = 2;

export enum TEMPLATE_INTERACTION_TYPE {
    QUICKVIEW = 'QuickView',
    QUICKVIEW_QSP = 'QuickViewQSP',
    STUDIO_TRANSITION = 'StudioTransition',
}

export const RELEVANT_BOOST_TYPES = [TEMPLATE_INTERACTION_TYPE.QUICKVIEW, TEMPLATE_INTERACTION_TYPE.QUICKVIEW_QSP, TEMPLATE_INTERACTION_TYPE.STUDIO_TRANSITION];

export enum TEMPLATE_INTERACTION_SUBTYPE {
    KEYWORD = 'keyword',
    CATEGORY = 'category',
}
