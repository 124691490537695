import { useMemo, FunctionComponent, memo } from 'react';
import config from 'config';
import { POT, Locale, PageId } from '@vp/pot-component';
import type {
    ClientConfiguration,
    ProductsConfiguration,
    TenantConfiguration,
    UserConfiguration,
    TrackingData,
} from '@vp/pot-component';
import { useAuth } from 'client/hooks/useAuth';
import { useSelector } from 'react-redux';
import {
    getLocaleSelector, getProductKey,
} from 'client/store/config';
import { useRecoilValue } from 'recoil';
import { pricingContextAtom } from 'client/atoms/pricingContext';
import { getFormattedLocale } from 'shared/localeUtils';
import { useAnalytics } from 'client/hooks/gallery/useAnalytics';
import { ANALYTICS_CATEGORY } from 'shared/constants';
import { PromoCodeProps } from 'client/components/common/QuickView/PromoCode/interface';

const PromoCodeConfigurationComponent: FunctionComponent<PromoCodeProps> = ({
    selectedProductOptions,
    quantity,
}) => {
    const auth = useAuth();
    const analytics = useAnalytics();
    const productKey = useSelector(getProductKey);
    const pricingContext = useRecoilValue(pricingContextAtom);
    const retrievedPricingContext = pricingContext?.getPricingContext();
    const locale = useSelector(getLocaleSelector);

    const userConfiguration: UserConfiguration = useMemo(() => ({
        authToken: auth?.accessToken || '',
        vatInclusivity: retrievedPricingContext?.vatInclusive || false,
    }), [auth, retrievedPricingContext]);

    const tenantConfiguration: TenantConfiguration = useMemo(() => ({
        locale: getFormattedLocale(locale).locale as Locale,
        tenant: config.tenant,
    }), [locale]);

    const clientConfiguration: ClientConfiguration = useMemo(() => ({
        requestor: PageId.Gallery,
        pageId: PageId.Gallery,
    }), []);

    const productsConfiguration: ProductsConfiguration = useMemo(() => ([
        { productId: productKey, quantity, selections: selectedProductOptions },
    ]), [productKey, quantity, selectedProductOptions]);
    const trackingData: TrackingData = useMemo(() => ({
        ...analytics.getPageProperties(),
        category: ANALYTICS_CATEGORY.GALLERY,
    }), [analytics]);

    return (
        <POT
            clientConfiguration={clientConfiguration}
            productsConfiguration={productsConfiguration}
            tenantConfiguration={tenantConfiguration}
            trackingData={trackingData}
            userConfiguration={userConfiguration}
        />
    );
};

export const PromoCodeConfiguration = memo(PromoCodeConfigurationComponent);
