import { useDesignVariations } from 'client/hooks/useDesignVariations';
import { useSelector } from 'react-redux';
import { getProductOptionsHash } from 'client/utils/getProductOptionsHash';
import { designVariationsInputSelector } from 'client/store/quickView/selectors';

export const useCurrentDesignVariationColors = (
    startingEntity: State.TileEntity,
    selectedProductOptions: DesignVariations.VariableSelections,
): string[] => {
    // Unique to DVs
    // The return value is memo'd and dependent on the entity and the resulting query should only run once
    // per unique entityId
    const designVariationInput = useSelector(designVariationsInputSelector)(
        startingEntity,
        startingEntity.productOptions,
        Object.keys(startingEntity.templateUseCases),
    );
    const { data } = useDesignVariations({
        ...designVariationInput,
        templateToken: startingEntity.previewInfo.templateToken,
    });

    const currentDesignVariation = data[getProductOptionsHash(selectedProductOptions)];

    return currentDesignVariation?.colorSwatches.map((c) => c.designId) ?? [];
};
