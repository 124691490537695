import {
    memo, useMemo, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useRecoilValue } from 'recoil';
import DOMPurify from 'isomorphic-dompurify';
import { PREVIEW_TYPE } from 'shared/constants';
import { pricingContextAtom } from 'client/atoms/pricingContext';
import {
    getLocaleSelector,
    getMarket,
    getQuantitySelector,
    stringRenderPropertySelector,
} from 'client/store/config';
import { RenderProperty } from 'shared/renderProperties';
import {
    getH1Title, getProductKey, getProductVersion,
} from 'client/store/config/reducer';
import { renderablePreviewInfoSelector } from 'client/store/design';
import { productOptionsByProductKeySelector } from 'client/store/productOptionsByProductKey';
import { useBatchPricing } from 'client/hooks/useBatchPricing';
import { useQuantities } from 'client/hooks/useQuantities';
import { getPriceData } from 'client/components/Gallery/ProductStructuredData/utils';
import { useRatingsAndReviews } from '~/client/hooks/useRatingsAndReviews';
import {
    useAdjustedPricingPresentationType,
} from 'client/components/Gallery/PreviewArea/GalleryQuickView/hooks/useAdjustedPricingPresentationType';
import { getQuickViewId } from 'client/store/debug';
import { tileEntityByIdSelector } from 'client/store/tileEntity';

export interface PropTypes {
    entities: State.TileEntity[];
    galleryMetadata: State.ModifiedGalleryConfigMetadata;
}

export const ProductStructuredDataWriter = memo((props: PropTypes): JSX.Element | null => {
    const {
        entities, galleryMetadata,
    } = props;

    const productKey = useSelector(getProductKey);
    const getPreview = useSelector(renderablePreviewInfoSelector);

    const imageUrls = useMemo(() => entities.map(({ selectedColorSwatchDesignId, designId }) => getPreview(
        selectedColorSwatchDesignId ?? designId,
        designId,
        PREVIEW_TYPE.TILE,
    ).previewsUrls.size2x), [entities, getPreview]);

    const h1Title = useSelector(getH1Title);
    const quantity = useSelector(getQuantitySelector);
    const locale = useSelector(getLocaleSelector);
    const market = useSelector(getMarket);
    const productVersion = useSelector(getProductVersion);
    const productOptionsByProductKey = useSelector(productOptionsByProductKeySelector);
    const tileEntityById = useSelector(tileEntityByIdSelector);
    const quickViewId = useSelector(getQuickViewId);

    const ratingsAndReviews = useRatingsAndReviews();

    const { productQuantity } = useQuantities({
        productKey,
        productVersion: productVersion || undefined,
        locale,
        quantity,
    });

    const pricingContext = useRecoilValue(pricingContextAtom);
    const retrievedPricingContext = pricingContext?.getPricingContext();

    const entity = useMemo(() => {
        const defaultEntity = entities[0];

        return quickViewId ? (tileEntityById(quickViewId) ?? defaultEntity) : defaultEntity;
    }, [entities, quickViewId, tileEntityById]);
    const { differentialPriceData } = useBatchPricing({
        productOptionsByProductKey,
        productKey,
        productVersion: productVersion || undefined,
        differentialPrice: !!quickViewId,
        fullProductOptions: entity.fullProductOptions,
        locale,
        market,
        quantity,
        pricingContext,
        retrievedPricingContext,
    });

    // Pricing
    const stringRenderProperty = useSelector(stringRenderPropertySelector);
    const pricingPresentationType = stringRenderProperty(RenderProperty.PricingPresentationType);
    const adjustedPricingPresentationType = useAdjustedPricingPresentationType(
        pricingPresentationType,
        quantity,
    );
    const {
        price,
        priceQuantity,
    } = getPriceData(differentialPriceData, productQuantity, adjustedPricingPresentationType);

    const [structuredData] = useState({
        '@context': 'http://schema.org/',
        '@type': 'Product',
        '@id': productKey,
        name: h1Title,
        sku: productKey,
        mpn: productKey,
        image: imageUrls,
        brand: {
            '@type': 'Brand',
            name: 'Vistaprint',
        },
        description: galleryMetadata.primaryContent,
        offers: {
            '@type': 'Offer',
            price,
            priceCurrency: differentialPriceData?.currency,
            itemCondition: 'NewCondition',
            availability: 'InStock',
            potentialAction: {
                '@type': 'BuyAction',
            },
            url: galleryMetadata.url,
            priceQuantity,
            unitCode: 'UNT',
        },
        ...(!!ratingsAndReviews && {
            aggregateRating: {
                '@type': 'AggregateRating',
                ratingValue: ratingsAndReviews.summary.averageRating,
                reviewCount: ratingsAndReviews.summary.reviewsCount,
            },
        }),
    });

    const sanitizedStructuredData = DOMPurify.sanitize(JSON.stringify(structuredData));

    return (
        <script
            /* eslint-disable-next-line react/no-danger */
            dangerouslySetInnerHTML={{ __html: sanitizedStructuredData }}
            id="product-structured-data"
            type="application/ld+json"
        />
    );
});
