import {
    FunctionComponent, memo, useCallback, useEffect, useMemo, useState,
} from 'react';
import { Buttonbar, ButtonbarButton, ButtonbarButtonLabel } from '@vp/swan';
import { useVortexContext, VortexAction } from '@rendering/vortex-react';
import { useTranslations } from 'client/hooks/useTranslations';
import {
    VORTEX_ACCEPTABLE_ACTIONS,
    VORTEX_ACTION_ID,
    VORTEX_ANIMATION_CONFIG,
    VORTEX_ANIMATION_DURATION,
    VORTEX_TOGGLEABLE_ACTIONS,
} from 'client/components/Gallery/PreviewArea/GalleryQuickView/components/VortexPreview/constants';

type PropType = {
    actions: VortexAction[],
}

const VortexPreviewActionButtonsComponent: FunctionComponent<PropType> = ({ actions }) => {
    const localize = useTranslations();
    const localizeButtonText = (actionName: string): string => localize(`Vortex${actionName === 'Close' || actionName === 'Fold' ? 'Folded' : actionName}ButtonText`);

    const [activeAction, setActiveAction] = useState<string>(VORTEX_ACTION_ID.Front);
    const [disableToggleAction, setDisableToggleAction] = useState<boolean>(false);
    const filteredActions = useMemo(
        () => actions.filter((action) => VORTEX_ACCEPTABLE_ACTIONS.includes(action.id)),
        [actions],
    );
    const { loadingModel, updatingCanvas } = useVortexContext();

    useEffect(() => {
        setActiveAction(VORTEX_ACTION_ID.Front);
        setDisableToggleAction(false);
    }, [loadingModel]);

    const createClickHandler = useCallback((action: VortexAction, isToggleable: boolean) => async () => {
        if (isToggleable) {
            setDisableToggleAction(true);
            setTimeout(
                () => setDisableToggleAction(false),
                VORTEX_ANIMATION_DURATION * 1000,
            );
        } else {
            setActiveAction(action.id);
        }

        await action.onAnimate(VORTEX_ANIMATION_CONFIG);
    }, []);

    return (
        <Buttonbar className="action-buttons-wrapper" selectedValue={activeAction}>
            {filteredActions.map((action) => {
                const isToggleable = VORTEX_TOGGLEABLE_ACTIONS.includes(action.id);
                const isDisabled = (isToggleable && disableToggleAction) || loadingModel || updatingCanvas;

                return (
                    <ButtonbarButton
                        disabled={isDisabled}
                        fontFamily="primary"
                        key={isToggleable ? 'toggle' : action.id} // strict key for saving animation
                        value={action.id}
                        onClick={createClickHandler(action, isToggleable)}
                    >
                        <ButtonbarButtonLabel>{localizeButtonText(action.name)}</ButtonbarButtonLabel>
                    </ButtonbarButton>
                );
            })}
        </Buttonbar>
    );
};

export const VortexPreviewActionButtons = memo(VortexPreviewActionButtonsComponent);
