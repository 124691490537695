import { Link, LinkProps } from '@vp/swan';
import { useAuth } from 'client/hooks/useAuth';
import { useAnalytics } from '~/client/hooks/gallery/useAnalytics';
import { ANALYTICS_EVENT_ACTIONS } from '~/shared/constants';

type SignInButtonProps = Omit<LinkProps, 'ref'> & {
    signInText: string;
    beforeSignIn?: () => void;
}

export const SignInButton = (props: SignInButtonProps): JSX.Element | null => {
    const auth = useAuth();
    const { beforeSignIn, signInText, ...rest } = props;
    const analytics = useAnalytics();

    const handleOnClick = (): void => {
        beforeSignIn?.();
        auth?.signIn();

        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
            eventLabel: 'Sign in button clicked',
            eventDetail: 'Sign in button clicked',
            ...analytics.getPageProperties(),
        });
    };

    return (
        <Link
            onClick={handleOnClick}
            {...rest}
        >
            {signInText}
        </Link>
    );
};
