import config from 'config';
import { InitializationOptions } from '@rendering/vortex-react/dist/esm/previewOptions';

const { appName } = config;

export const VORTEX_DEFAULT_TENANT = {
    id: 'vistaprint-prod',
    type: 'merchants',
};

export const VORTEX_USER = {
    referer: appName,
    tenant: VORTEX_DEFAULT_TENANT,
};

export const VORTEX_ANIMATION_DURATION = 2;
export const VORTEX_DEFAULT_ANIMATION_DURATION = 1;
export const VORTEX_INSTANT_ANIMATION_DURATION = 0.0000001;

export const DEFAULT_VORTEX_CAMERA_OPTIONS = {
    panning: false,
    hover: true,
    containerPadding: 32,
    minZoom: 0.75,
    maxZoom: 1.6,
};

export const VORTEX_CAMERA_OPTIONS = {
    initialOrientation: { azimuthAngle: 0, polarAngle: Math.PI / 2 },
    ...DEFAULT_VORTEX_CAMERA_OPTIONS,
};

export const PAN_TO_SIDE_ANIMATION = 'panToSide';
export const SWIVEL_ANIMATION = 'swivel';
export const DEFAULT_ANIMATION = 'default';

export const PAN_TO_SIDE_ANIMATION_CONFIG = {
    cameraOrientation: {
        azimuthAngle: -0.62,
        polarAngle: 1.25,
    },
    scaleToInitialState: true,
};

export const SWIVEL_ANIMATION_CONFIG = {
    swivel: {
        enabled: true,
        range: 0.6,
        dumping: 0.55,
    },
    scaleToInitialState: true,
};

export const DEFAULT_INITIALIZATION_CONFIG = {
    scaleToInitialState: true,
    cameraOrientation: {
        azimuthAngle: 0,
        polarAngle: Math.PI / 2,
        duration: VORTEX_DEFAULT_ANIMATION_DURATION,
    },
};

export const ANIMATION_CONFIGS: Record<string, InitializationOptions> = {
    [PAN_TO_SIDE_ANIMATION]: PAN_TO_SIDE_ANIMATION_CONFIG,
    [SWIVEL_ANIMATION]: SWIVEL_ANIMATION_CONFIG,
    [DEFAULT_ANIMATION]: DEFAULT_INITIALIZATION_CONFIG,
};

export const VORTEX_ANIMATION_CONFIG = {
    scaleToFitGeometry: true,
    centerGeometry: true,
    animationDuration: VORTEX_ANIMATION_DURATION,
};

export enum VORTEX_ACTION_ID {
    Front = 'front',
    Folded = 'Folded',
    Unfold = 'Unfolded',
    Back = 'back',
    Open = 'Open',
    Close = 'Close',
}

export const VORTEX_TOGGLEABLE_ACTIONS: string[] = [
    VORTEX_ACTION_ID.Open,
    VORTEX_ACTION_ID.Close,
    VORTEX_ACTION_ID.Folded,
    VORTEX_ACTION_ID.Unfold,
];
export const VORTEX_ACCEPTABLE_ACTIONS: string[] = [
    VORTEX_ACTION_ID.Front,
    VORTEX_ACTION_ID.Back,
    VORTEX_ACTION_ID.Open,
    VORTEX_ACTION_ID.Close,
    VORTEX_ACTION_ID.Folded,
];

export const CANVAS_DEFAULT_STYLE = {
    maxWidth: '100%',
    maxHeight: '100%',
    minWidth: '100%',
    minHeight: '100%',
};
