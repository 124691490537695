import { FilterMenu } from 'client/components/Gallery/Header/FilterMenu';
import { SearchBox } from 'client/components/Gallery/Header/Searchbox';
import { FavoritesCTA } from 'client/components/Gallery/Header/Favorites';
import { useAuth } from 'client/hooks/useAuth';
import { useFavorites } from 'client/hooks/features/useFavorites';
import { SearchButton } from 'client/components/Gallery/Header/SearchButton';
import { WarningBox } from 'client/components/Gallery/Header/WarningBox';
import { HeaderPersonalizationButton } from '~/client/components/Gallery/Header/PersonalizationV1/MobilePersonalization/HeaderPersonalizationButton';
import { useIsPersonalizationEnabled } from '~/client/hooks/useIsPersonalizationEnabled';

export const StaticHeader = (): JSX.Element => {
    const showFavorites = useFavorites();
    const auth = useAuth();

    const shouldShowFavorites = showFavorites && auth;
    const { isMobilePersonalizationEnabled, shouldShowColorFilterFirst } = useIsPersonalizationEnabled();

    return (
        <div className="header-wrapper">
            <div className="subheader-actions">
                <SearchBox />
                <SearchButton shouldHideText={isMobilePersonalizationEnabled} />
                {shouldShowFavorites && (<FavoritesCTA userInteractionLocation="staticHeader" />)}
                <FilterMenu
                    shouldRenderSmallMobileIcon={isMobilePersonalizationEnabled}
                    showColorFilterFirst={shouldShowColorFilterFirst}
                />
                {isMobilePersonalizationEnabled && <HeaderPersonalizationButton />}
            </div>
            <WarningBox />
        </div>
    );
};

StaticHeader.displayName = 'StaticHeader';
