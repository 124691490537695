import { ASPExperiment } from './ASPExperiment';

export enum ASP_NEWSNESS_VARIATIONS {
    NewnessEnabledTen = 'ASPNewnessEnabledTen',
    NewnessEnabledFive = 'ASPNewnessEnabledFive',
    NewnessControl = 'ASPNewnessControl',
}

export const ASPNewnessExperiment = {
    experimentKey: 'newest_exploration',
    variationsValueMap: {
        [ASP_NEWSNESS_VARIATIONS.NewnessEnabledTen]: '0.10',
        [ASP_NEWSNESS_VARIATIONS.NewnessEnabledFive]: '0.05',
        [ASP_NEWSNESS_VARIATIONS.NewnessControl]: '0',
    },
} as ASPExperiment;
