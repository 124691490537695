import { useSelector } from 'react-redux';
import { stringRenderPropertySelector } from '~/client/store/config';
import { useTranslations } from '~/client/hooks/useTranslations';
import { RenderProperty } from 'shared/renderProperties';
import { isPurposeName, TemplateField } from '../../../Filters/PersonalizationV1/purposeHelpers';
import { PURPOSE_NAMES } from '../../../Filters/PersonalizationV1/constants';

export function parsePurposeFields(textFieldsString: string, localize: i18n.Localize): TemplateField[] {
    const purposeNames: PURPOSE_NAMES[] = textFieldsString?.split(',').filter(isPurposeName);

    return purposeNames.map((purposeName) => ({ purposeName, placeholder: localize(`PersonalizationPlaceholderPurpose${purposeName.charAt(0).toUpperCase()}${purposeName.slice(1)}`) })) ?? [];
}

export const usePurposeNames = (): TemplateField[] => {
    const localize = useTranslations();
    const stringRenderProperty = useSelector(stringRenderPropertySelector);
    const textFieldsString = stringRenderProperty(RenderProperty.PersonalizationTextFields) ?? '';

    return parsePurposeFields(textFieldsString, localize);
};
