import { instrumenter } from 'client/utils/instrumentation';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { designPersonalizationContextSelector } from '~/client/store/designPersonalization/selectors';

const debounceImagesLoaded = (timeout = 2000): ((a: boolean, b: boolean) => void) => {
    let timer: NodeJS.Timeout | undefined;

    return (textPersonalized: boolean, imagePersonalized: boolean): void => {
        if (!timer) {
            timer = setTimeout(() => {
                timer = undefined;
                instrumenter.recordImagesLoaded(textPersonalized, imagePersonalized);
            }, timeout);
        }
    };
};

const debouncedRecordImagesLoaded = debounceImagesLoaded();

const perfObserver = (
    textPersonalized: boolean,
    imagePersonalized: boolean,
) => (list: PerformanceObserverEntryList): void => {
    list.getEntries().forEach((entry) => {
        if (entry.name.includes('rendering')) {
            window.imageResources.push(entry as PerformanceResourceTiming);
            debouncedRecordImagesLoaded(textPersonalized, imagePersonalized);
        }
    });
};

export const ImageLoadedInstrumenter = (): null => {
    const personalizedData = useSelector(designPersonalizationContextSelector);
    const imagePersonalized = !!personalizedData?.images?.length;
    const textPersonalized = !!personalizedData?.text?.length;

    useEffect(() => {
        window.windowObserver.disconnect();
        window.windowObserver = new PerformanceObserver(perfObserver(textPersonalized, imagePersonalized));
        window.windowObserver.observe({ type: 'resource' });
    }, [textPersonalized, imagePersonalized]);

    useEffect(() => {
        // run this once when the page loads, assuming we have loaded all of the initial images
        instrumenter.recordImagesLoaded(textPersonalized, imagePersonalized);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};
