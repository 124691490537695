import {
    lazy, Suspense, useCallback, useDeferredValue,
    useEffect,
} from 'react';
import { QuickView } from 'client/components/common/QuickView';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { GalleryQuickViewSpinner } from 'client/components/Gallery/PreviewArea/GalleryQuickView/GalleryQuickViewSpinner';
import { getQuickViewId } from '~/client/store/debug';
import { resetQuickViewId } from '~/client/store/debug/actions';
import { useGalleryBaseURL } from '~/client/hooks/useGalleryBaseURL';
import { useGalleryDesignIdFromUrl } from '~/client/hooks/useGalleryDesignId';
import { tileEntityByIdSelector } from '~/client/store/tileEntity';
import { getTakeOverQV } from 'client/store/debug/reducer';
import { FULL_SCREEN_QV_MODAL } from '~/experiments/FullscreenQVModal/constants';
import { TRACKING_BEHAVIOR, useExperimentation } from '~/client/hooks/useExperimentation';
import { FullscreenQuickViewModal } from '~/client/components/Gallery/PreviewArea/FullscreenQuickViewModal';

const RouteableQuickView = lazy(() => import('client/components/Gallery/PreviewArea/RouteableQuickView'));

export const QuickViewModal = (): JSX.Element => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const designId = useGalleryDesignIdFromUrl();
    const galleryBaseURL = useGalleryBaseURL();
    const quickViewDesignID = useSelector(getQuickViewId);
    const isTakeOverQV = useSelector(getTakeOverQV);
    const tileEntityById = useSelector(tileEntityByIdSelector);
    const isExperimentActive = useExperimentation();

    const fullScreenQVModalEnabled = isExperimentActive(FULL_SCREEN_QV_MODAL.Enabled, TRACKING_BEHAVIOR.Suppress);

    const deferredDesignId = useDeferredValue(designId);

    const shouldOpenQuickView = !!designId;

    const handleOnRequestDismiss = useCallback((): void => {
        // Wrapped in setTimeout in order to improve INP performance
        setTimeout(() => {
            if (quickViewDesignID) {
                dispatch(resetQuickViewId());
            }
        });

        if (isTakeOverQV) {
            // go back one step in the history stack
            navigate(-1);
            return;
        }

        navigate({
            ...location,
            pathname: galleryBaseURL,
        });
    }, [location, galleryBaseURL, quickViewDesignID, navigate, dispatch, isTakeOverQV]);

    useEffect(() => {
        if (!quickViewDesignID) {
            return;
        }

        const qvTile = tileEntityById(quickViewDesignID);
        const newUrl = new URL(window.location.href);

        if (!qvTile || newUrl.pathname.includes('/qv/')) {
            return;
        }

        newUrl.searchParams.delete('quickView');
        newUrl.pathname = `${newUrl.pathname}/qv/${quickViewDesignID}`;

        const qvUrl = `${newUrl.pathname}${newUrl.search}`;

        navigate(qvUrl, { replace: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quickViewDesignID]);

    if (fullScreenQVModalEnabled) {
        return (
            <QuickView
                isModal
                onlyRenderWhenOpen
                deferredDesignId={deferredDesignId}
                id={`quickview-${designId}`}
                isOpen={shouldOpenQuickView}
                onRequestDismiss={handleOnRequestDismiss}
            >
                {deferredDesignId && (
                    <FullscreenQuickViewModal designId={deferredDesignId} />
                )}
                {!deferredDesignId && (
                    <GalleryQuickViewSpinner />
                )}
            </QuickView>
        );
    }

    return (
        <QuickView
            onlyRenderWhenOpen
            id={`quickview-${designId}`}
            isOpen={shouldOpenQuickView && !fullScreenQVModalEnabled}
            onRequestDismiss={handleOnRequestDismiss}
        >
            {deferredDesignId && (
                <Suspense fallback={<GalleryQuickViewSpinner />}>
                    <RouteableQuickView designId={deferredDesignId} />
                </Suspense>
            )}
            {!deferredDesignId && (
                <GalleryQuickViewSpinner />
            )}
        </QuickView>

    );
};
