import { getProductOptionsHash } from 'client/utils/getProductOptionsHash';
import { QUICKVIEW_FILTER_TYPE } from '~/shared/constants';

export const useQuickViewFilterOptions = (
    filters: (State.QuickViewAttributeFilter | State.QuickViewTemplateUseCaseFilter)[],
    getOption: (id: string) => Gallery.ConfigApi.FilterOption,
    getTemplateUseCaseOption: (id: string) => State.TemplateUseCase,
    variableSelections: DesignVariations.VariableSelections = {},
    designVariationsState: DesignVariations.DesignVariationsByHash = {},
): Util.StringDictionary<Gallery.QuickView.RenderableFilter> => (filters || []).reduce(
    (
        accum: Util.StringDictionary<Gallery.QuickView.RenderableFilter>,
        filter: State.QuickViewAttributeFilter | State.QuickViewTemplateUseCaseFilter,
    ) => {
        const res: Gallery.QuickView.RenderableFilterOption[] = [];

        for (const o of filter.options) {
            let option = getOption(o) as Gallery.QuickView.RenderableFilterOption
                || {} as Gallery.QuickView.RenderableFilterOption;
            const { productOption } = option;

            if (productOption) {
                option.filterKey = productOption.optionName;
                option.filterValue = productOption.optionValue;
            } else if (filter.quickViewFilterType === QUICKVIEW_FILTER_TYPE.ATTRIBUTE) {
                const [filterKey, filterValue] = option.value.split('_');

                option.filterKey = filterKey;
                option.filterValue = filterValue;
            } else if (filter.quickViewFilterType === QUICKVIEW_FILTER_TYPE.TEMPLATE_USE_CASE) {
                option = getTemplateUseCaseOption(o) as State.TemplateUseCase || {};
                const castFilter = filter as State.QuickViewTemplateUseCaseFilter;

                option.filterKey = castFilter.templateUseCaseId;
                option.filterValue = o;
            }

            option.selected = variableSelections[option.filterKey] === option.filterValue;
            option.disabled = false;

            if (!option.selected) {
                const variableOptions = {
                    ...variableSelections,
                    [option.filterKey]: option.filterValue,
                };
                const hash = getProductOptionsHash(variableOptions);

                option.disabled = !designVariationsState[hash];
            }

            res.push(option);
        }

        return {
            ...accum,
            [filter.name]: { options: res },
        };
    },
    {} as Util.StringDictionary<Gallery.QuickView.RenderableFilter>,
);
