import {
    ChangeEvent, useMemo, useState, useRef, useEffect, memo,
    Fragment,
} from 'react';
import {
    GridContainer,
    Row,
    Column,
    ModalDialogNav,
    ModalDialogHeader,
    ModalDialogTitle,
    Typography, SelectedValue,
    Box,
    ModalDialogCloseButton,
} from '@vp/swan';
import config from 'config';
import { useSelector } from 'react-redux';
import { v4 } from 'uuid';
import { TileEntityFavorite } from 'client/components/Gallery/PreviewArea/TileEntityFavorite';
import { useCurrentDesign } from 'client/components/Gallery/PreviewArea/GalleryQuickView/hooks/useCurrentDesign';
import { quickViewFilterByIdSelector, selectQuickViewFilterNames } from 'client/store/quickViewFilter';
import { useQuickViewFilterOptions } from 'client/components/Gallery/PreviewArea/GalleryQuickView/hooks/useQuickViewFilterOptions';
import { useCurrentDesignOrVariation } from 'client/components/Gallery/PreviewArea/GalleryQuickView/hooks/useCurrentDesignOrVariation';
import { filterByIdSelector } from 'client/store/filter';
import { filterOptionByIdSelector } from 'client/store/filterOption';
import { getProductOptionsHash } from 'client/utils/getProductOptionsHash';
import { findClosestDesignId } from 'client/utils/quickview/findClosestDesignId';
import { globalQuickViewDataSelector, quickViewControlFlagsSelector } from 'client/store/quickView/selectors';
import { generateFilterGroupData, ReturnState } from 'client/utils/quickview/generateFilterGroupData';
import {
    ColorSwatches,
    FilterGroup,
    ShippingInfo,
} from 'client/components/common/QuickView';
import { tileEntityByIdSelector } from 'client/store/tileEntity';
import { buildFiltersFromName } from 'client/utils/quickview/buildFiltersFromName';
import { useQuickViewAnalytics } from 'client/components/Gallery/PreviewArea/GalleryQuickView/hooks/useQuickViewAnalytics';
import { newVariationHasCurrentColorSwatch } from 'client/utils/quickview/newVariationHasCurrentColorSwatch';
import { getDebugModeEnabled, getQuickViewId } from 'client/store/debug';
import { StudioUrlBuilder } from 'client/components/Gallery/PreviewArea/GalleryQuickView/utils/studioUrl';
import { useFavorites } from 'client/hooks/features/useFavorites';
import {
    ANALYTICS_LABEL, DESIGN_CREATION_TYPE, PREVIEW_TYPE,
} from 'shared/constants';
import { instrumenter } from 'client/utils/instrumentation';
import { PAGE_ACTION_TYPES, QV_PREVIEW_ID } from 'client/constants';
import { useIconography } from 'client/hooks/useIconography';
import {
    filterDesignVariationToVariableSelections,
} from 'client/components/Gallery/PreviewArea/GalleryQuickView/utils/filterDesignVariationToVariableSelections';
import { optionNameByFilterNameSelector } from 'client/store/optionNameByFilterName/reducer';
import { DesignVariationFilter } from 'client/components/Gallery/PreviewArea/GalleryQuickView/DesignVariationFilter';
import { useQuantities } from 'client/hooks/useQuantities';
import { booleanRenderPropertySelector, getH1Title } from 'client/store/config';
import { RenderProperty } from 'shared/renderProperties';
import { OptionsSkeleton } from 'client/components/common/QuickView/Filters/OptionsSkeleton';
import { useCurrentDesignVariationColors } from 'client/hooks/useCurrentDesignVariationColors';
import { useCurrentDesignColors } from 'client/hooks/useCurrentDesignColors';
import { galleryImpressionIdSelector } from '~/client/store/analytics/reducer';
import {
    DESIGN_CONSOLIDATION_EXPERIMENT_NAME,
    DESIGN_CONSOLIDATION_VARIATIONS,
} from '~/experiments/DesignConsolidation/constants';
import { attributeNameByFilterNameSelector } from '~/client/store/attributeNameByFilterName';
import { templateUseCaseIdByFilterNameSelector } from '~/client/store/templateUseCaseIdByFilterName';
import {
    findClosestVariation,
} from '~/client/components/Gallery/PreviewArea/GalleryQuickView/utils/findClosestVariation';
import { trackExperimentEngagement } from '~/shared/ab-testing';
import { getRawExperiments } from '~/client/store/experimentation';
import { useAnalytics } from '~/client/hooks/gallery/useAnalytics';
import { templateUseCaseByIdSelector } from '~/client/store/filterTemplateUseCases';
import { UnwindingAlertBox } from '~/experiments/DesignConsolidation/components/UnwindingAlertBox';
import { useUnwindingAlertBox } from '~/experiments/DesignConsolidation/components/UnwindingAlertBox/hooks';
import { useSocialMediaGallery } from '~/client/hooks/useSocialMediaGallery';
import { useTemplateInteractions } from '~/client/hooks/useTemplateInteractions';
import { isFeatureEnabled, whenAvailable } from '@vp/ab-reader';
import classnames from 'classnames';
import { ModalPreview } from '~/client/components/Gallery/PreviewArea/FullscreenQuickViewModal/components/ModalPreview';
import {
    CurrentDesignOrVariationData, FullscreenModalContentComponentProps, SelectionState,
} from '~/client/components/Gallery/PreviewArea/FullscreenQuickViewModal/interface';
import { designPersonalizationContextSelector } from '~/client/store/designPersonalization/selectors';
import { ModalFooter } from '~/client/components/Gallery/PreviewArea/FullscreenQuickViewModal/components/ModalFooter';
import { useTranslations } from '~/client/hooks/useTranslations';
import { TEMPLATE_INTERACTION_TYPE } from '~/experiments/DesignBoost/constants';
import { getTakeOverQV } from '~/client/store/debug/reducer';
import { normalizeQuickViewUrl } from '~/client/components/Gallery/PreviewArea/utils';

// Constants
const { tenant } = config;

const FullscreenModalContentComponent = ({
    startingEntityId,
    initialColorSwatchId,
}: FullscreenModalContentComponentProps): JSX.Element => {
    const startingEntity = useSelector(tileEntityByIdSelector)(startingEntityId);
    const {
        locale,
        quantity,
        market,
    } = useSelector(globalQuickViewDataSelector);
    const {
        shouldRenderVortex,
        showComparativeNames,
        shouldRenderIconography,
        pricingPresentationType,
    } = useSelector(quickViewControlFlagsSelector);

    const showFavorites = useFavorites();
    const shouldHideForSocialMedia = useSocialMediaGallery();
    const getOption = useSelector(filterOptionByIdSelector);
    const getTemplateUseCaseOption = useSelector(templateUseCaseByIdSelector);
    const selectFilterById = useSelector(filterByIdSelector);
    const selectQuickViewFilterById = useSelector(quickViewFilterByIdSelector);
    const quickViewFilterNames = useSelector(selectQuickViewFilterNames);
    const initialRender = useRef(true);
    const changeFilterInput = useRef({ oldOptionValue: '', newOptionValue: '', optionName: '' });
    const debugEnabled = useSelector(getDebugModeEnabled);
    const quickViewDesignID = useSelector(getQuickViewId);
    const filterByOptionNameById = useSelector(optionNameByFilterNameSelector);
    const attributeByFilterName = useSelector(attributeNameByFilterNameSelector);
    const templateUseCaseIdByFilterName = useSelector(templateUseCaseIdByFilterNameSelector);
    const getTemplateUseCaseById = useSelector(templateUseCaseByIdSelector);
    const isTakeOverQV = useSelector(getTakeOverQV);
    const h1Title = useSelector(getH1Title);
    const localize = useTranslations();

    const engagementIdRef = useRef(v4());
    const studioUrlBuilder = useRef(new StudioUrlBuilder());
    const rawExperiments = useSelector(getRawExperiments);
    const analytics = useAnalytics();
    const booleanRenderProperty = useSelector(booleanRenderPropertySelector);
    const designPersonalizationContext = useSelector(designPersonalizationContextSelector);
    const allowUnwinding = booleanRenderProperty(RenderProperty.ConsolidateContent);
    const { createInteractions } = useTemplateInteractions();

    // This should only run once per component life
    const memodVariableSelections = useMemo(() => filterDesignVariationToVariableSelections(
        startingEntity.productOptions,
        quickViewFilterNames.map(filterByOptionNameById),
        startingEntity.templateUseCases,
        startingEntity.numberOfPlaceholders,
        quickViewFilterNames.map(attributeByFilterName),
        quickViewFilterNames.map(templateUseCaseIdByFilterName),
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [quickViewFilterNames, startingEntity.productOptions, startingEntity.numberOfPlaceholders,
        startingEntity.templateUseCases]);

    // Local State
    const [{ currentDesignId, variableSelections }, setSelectionState] = useState<SelectionState>({
        currentDesignId: initialColorSwatchId,
        variableSelections: memodVariableSelections,
    });

    // UI Density Update flag
    const [isNewQuickViewUiDensityEnabled, setIsNewQuickViewUiDensityEnabled] = useState(false);

    // Unwinding
    const unwindingAlertBoxRef = useRef<HTMLLIElement>(null);

    const {
        shouldShowUnwindingAlertBox,
        showUnwindingAlertBox,
        hideUnwindingAlertBox,
        unwindingSelectedOptionKey,
    } = useUnwindingAlertBox();

    useEffect(() => {
        if (shouldShowUnwindingAlertBox) {
            unwindingAlertBoxRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [shouldShowUnwindingAlertBox, unwindingAlertBoxRef, currentDesignId]);

    // Memos
    const filters = useMemo(() => (
        buildFiltersFromName(quickViewFilterNames, selectFilterById, selectQuickViewFilterById, getTemplateUseCaseById)
    ), [quickViewFilterNames, selectFilterById, selectQuickViewFilterById, getTemplateUseCaseById]);

    const useColorSwatches = useMemo(
        () => (!filters.length ? useCurrentDesignColors : useCurrentDesignVariationColors),
        [filters],
    );

    const adjustedDesignsVariableSelections = (typeof window !== 'undefined'
        && (window.history.state?.variableSelections || window.history.state?.usr?.variableSelections))
        ? window.history.state.variableSelections ?? window.history.state.usr.variableSelections
        : variableSelections;

    const currentColorSwatches = useColorSwatches(
        startingEntity,
        adjustedDesignsVariableSelections,
    );

    // eslint-disable-next-line no-nested-ternary
    const hackCurrentDesignId = !currentColorSwatches.includes(currentDesignId)
        ? (typeof window !== 'undefined' && window.history.state?.designId)
            ? window.history.state.designId
            : initialColorSwatchId
        : currentDesignId;

    useEffect(() => {
        let updatedDesignId = currentDesignId;

        if (initialColorSwatchId !== currentDesignId) {
            updatedDesignId = (typeof window !== 'undefined' && window.history.state?.designId)
                ? window.history.state.designId
                : initialColorSwatchId;
        }
        setSelectionState({
            currentDesignId: updatedDesignId,
            variableSelections: (typeof window !== 'undefined' && window.history.state?.variableSelections)
                ? window.history.state.variableSelections
                : variableSelections,
        });

        // eslint-disable-next-line
    }, [initialColorSwatchId]);

    // When filters are configured for the QuickView, then we need to use the useCurrentDesignOrVariation hook to build
    // out the current entity from the design variation represented by the selected options and color swatch; otherwise
    // we need to use useCurrentDesign to find the current entity from redux based on the just the current color swatch.
    // Memo the selection of the hook on `filters` - the variable is never expected to change
    const useData = useMemo(() => (!filters.length ? useCurrentDesign : useCurrentDesignOrVariation), [filters]);
    const currentEntity = useData(
        startingEntity,
        hackCurrentDesignId,
        adjustedDesignsVariableSelections,
        startingEntity.productOptions,
    );
    const impressionId = useSelector((state: State.GlobalState) => galleryImpressionIdSelector(
        state,
        startingEntity.designId,
    ));
    const quickViewAnalytics = useQuickViewAnalytics();

    const isPersonalizationUIEnabled = booleanRenderProperty(RenderProperty.ShowPersonalizationUI);

    // Use Diamond for personalized rendering/URLs when the personalization sidebar is active
    // Or we have a dynamic design
    const shouldUseDiamond = (isPersonalizationUIEnabled && !!designPersonalizationContext)
        || currentEntity.designCreationType === DESIGN_CREATION_TYPE.DYNAMIC;

    const { favoriteId } = currentEntity;

    const { productQuantity } = useQuantities({
        productKey: currentEntity.productKey,
        productVersion: currentEntity.productVersion,
        locale,
        quantity,
    });

    useEffect(() => {
        if (initialRender.current) {
            window.history.replaceState({
                ...window.history.state,
                designId: hackCurrentDesignId,
                productOptions: adjustedDesignsVariableSelections,
                fromQSPEntity: window.history.state?.fromQSPEntity
                    ?? window.history.state?.usr?.fromQSPEntity
                    ?? currentEntity.isQSPEntity,
            }, '', window.location.href);

            const payload = {
                vortexEnabled: shouldRenderVortex,
                productKey: currentEntity.productKey,
                productVersion: currentEntity.productVersion,
                productOptions: currentEntity.fullProductOptions,
            };

            // Track quickView opening to test rate against Vortex failures
            instrumenter.recordPageAction(PAGE_ACTION_TYPES.QUICKVIEW_OPENED, payload);

            // Track impression if we opened quickView because of a QSP
            if (quickViewDesignID && !isTakeOverQV) {
                quickViewAnalytics.recordImpression(currentEntity, impressionId);
            }

            createInteractions({
                productKey: currentEntity.productKey,
                categoryKeys: currentEntity.categoryKeys,
                keywords: currentEntity.keywords,
                type: quickViewDesignID ? TEMPLATE_INTERACTION_TYPE.QUICKVIEW_QSP : TEMPLATE_INTERACTION_TYPE.QUICKVIEW,
            });

            initialRender.current = false;
            return;
        }

        const { oldOptionValue, newOptionValue, optionName } = changeFilterInput.current;
        const filterPersonalizedDetail = designPersonalizationContext ? 'Quick view filter selection with personalized design' : '';

        if (!isTakeOverQV) {
            quickViewAnalytics.recordChangeFilter(
                currentEntity,
                optionName,
                oldOptionValue,
                newOptionValue,
                productQuantity,
                impressionId,
                filterPersonalizedDetail,
            );
        }

        // eslint-disable-next-line
    }, [variableSelections]);

    useEffect(() => {
        let mounted = true;

        whenAvailable(() => {
            if (mounted) {
                if (isFeatureEnabled('cp_ui_density_updates')) {
                    setIsNewQuickViewUiDensityEnabled(true);
                }
            }
        }, 1000);
        return () => {
            mounted = false;
        };
    }, []);

    // Derive Data from `currentEntity`
    // const { templateToken } = (
    //     currentEntity?.renderablePreviews?.previewInfo || {}
    // ) as Gallery.ContentQuery.PreviewInfo | Record<string, undefined>;
    const filterToRenderableOptionsMap = useQuickViewFilterOptions(
        filters,
        getOption,
        getTemplateUseCaseOption,
        variableSelections,
        (currentEntity as CurrentDesignOrVariationData).designVariations,
    );

    // BUILD STUDIO URL
    const studioUrl = useMemo(
        () => studioUrlBuilder.current.reset()
            .setUrl(currentEntity.studioUrl)
            .addImpressionId(impressionId)
            .addEngagementId(engagementIdRef.current),
        [currentEntity.studioUrl, impressionId],
    );

    if (productQuantity) {
        studioUrl.addQuantity(productQuantity.toString());
    }
    // END BUILD STUDIO URL

    const colorSwatchPersonalizedDetail = designPersonalizationContext ? 'Quick view color selection with personalized design' : '';

    // Handlers
    /**
     * Updates the current design ID based on the color swatch the user clicks on
     * A color swatch is an entity in the redux store represented by a unique design ID
     * @param newDesignId
     */
    const handleColorSwatchChange = (newDesignId: string): void => {
        if (!isTakeOverQV) {
            quickViewAnalytics.recordChangeColorSwatch(
                currentEntity,
                newDesignId,
                impressionId,
                colorSwatchPersonalizedDetail,
            );
        }

        window.history.replaceState({
            ...window.history.state,
            designId: newDesignId,
            variableSelections,
        }, '', normalizeQuickViewUrl(window.location.href, 'selectedDesignId'));

        setSelectionState({
            variableSelections,
            currentDesignId: newDesignId,
        });
    };

    /**
     * Handles a user click on a quick view filter to updated the selected options set with the selected
     * option, as well as tracks this user interaction.
     * This function is only called if there are QuickView filters and assumes that the current entity is a
     * design variation.
     * @param filterKey
     * @returns
     */
    const curryHandleSelectFilter = (filterKey: string) => (
        selectedValue: SelectedValue,
        event: ChangeEvent<HTMLInputElement>,
    ): void => {
        event.preventDefault();

        // shouldn't happen, but let's not update a radio button with an invalid value
        if (!selectedValue || !(currentEntity as CurrentDesignOrVariationData).designVariations) {
            return;
        }

        let updatedSelections = { ...variableSelections };

        changeFilterInput.current = {
            oldOptionValue: updatedSelections[filterKey],
            newOptionValue: selectedValue,
            optionName: filterKey,
        };

        updatedSelections[filterKey] = selectedValue.toString();

        const newHash = getProductOptionsHash(updatedSelections);
        let newDesignVariation = (currentEntity as CurrentDesignOrVariationData).designVariations[newHash];

        // Unwind selected options if switching to the new option alone is invalid
        hideUnwindingAlertBox();

        if (!newDesignVariation) {
            trackExperimentEngagement(
                DESIGN_CONSOLIDATION_VARIATIONS.Enabled,
                rawExperiments,
                analytics,
                ANALYTICS_LABEL.QV_OPTIONS_UNWINDING,
                'QV Update',
                DESIGN_CONSOLIDATION_EXPERIMENT_NAME,
                `${filterKey}: ${selectedValue}`,
            );

            const allVariations = Object.values((currentEntity as CurrentDesignOrVariationData).designVariations);
            const usableVariations = allVariations.filter((v) => v.variationSelections[filterKey] === selectedValue);

            newDesignVariation = findClosestVariation(usableVariations, updatedSelections);
            updatedSelections = Object.keys(memodVariableSelections)
                .reduce((obj, key) => (
                    {
                        ...obj,
                        [key]: newDesignVariation.variationSelections[key],
                    }
                ), {} as DesignVariations.VariableSelections);

            showUnwindingAlertBox(filterKey);
        }
        let newDesignId = currentDesignId;

        // If current swatch exists on new design, keep it; otherwise we need to try to find the current color swatch
        // (design ID) or the swatch with the closest color to the original color swatch inside the design variation
        // and update the current design ID state
        if (!newVariationHasCurrentColorSwatch(currentEntity, newDesignVariation)) {
            newDesignId = findClosestDesignId(
                currentEntity.currentColorSwatch,
                newDesignVariation.colorSwatches,
            ) || newDesignVariation.previewInfo.designId;

            setSelectionState({
                variableSelections: updatedSelections,
                currentDesignId: newDesignId,
            });
        } else {
            setSelectionState({
                variableSelections: updatedSelections,
                currentDesignId: newDesignId,
            });
        }

        window.history.replaceState({
            ...window.history.state,
            designId: newDesignId,
            variableSelections: updatedSelections,
        }, '', window.location.href);
    };

    const iconDictionary = useIconography(
        currentEntity.productKey,
        currentEntity.productVersion,
        tenant,
        locale,
        shouldRenderIconography,
        undefined,
        undefined,
    );

    const shouldRenderFilters = !!filters.length && !currentEntity.isFetching;
    const shouldRenderFiltersSkeleton = !!filters.length && currentEntity.isFetching;

    return (
        <GridContainer dividers className="modal-content-wrapper" gutter="tight">
            <Row className="modal-row-wrapper">
                <ModalDialogCloseButton
                    accessibleText={localize('FilterMenuCloseButtonText')}
                    aria-label={localize('FilterMenuCloseButtonText')}
                    className="new-quick-view-close-modal"
                    data-section="Gallery Fly-out"
                    data-translation="Fly-Out Closed"
                />
                <Column backgroundColor="strong" className="preview-wrapper" span={8}>
                    <Box className={classnames('quick-view-preview', 'new-quick-view-preview')} id={QV_PREVIEW_ID}>
                        <ModalPreview
                            currentEntity={currentEntity}
                            shouldRenderVortex={shouldRenderVortex}
                            shouldUseDiamond={shouldUseDiamond}
                            startingEntity={startingEntity}
                        />
                    </Box>
                    {showFavorites && (
                        <TileEntityFavorite
                            colorSwatchObjects={currentEntity.colorSwatches}
                            entityId={startingEntityId}
                            favoriteId={favoriteId}
                            previewType={PREVIEW_TYPE.QUICKVIEW}
                        />
                    )}
                </Column>
                <Column className="content-column" p={0} px={4} span={4}>
                    <ModalDialogNav className="modal-nav-wrapper" m={0} py={5}>
                        <ModalDialogHeader>
                            {(showComparativeNames && currentEntity.comparativeName || h1Title) && (
                                <ModalDialogTitle className="modal-title" fontSize="x2large">
                                    {currentEntity.comparativeName || h1Title}
                                </ModalDialogTitle>
                            )}
                            {debugEnabled && (
                                <Typography className="quick-view-entity-id" component="span" fontSize="standard" fontWeight="normal">
                                    <p>
                                        {`EntityId: ${currentEntity.currentEntityId || currentEntity.designId}`}
                                    </p>
                                    <p>
                                        {`${currentEntity.designCreationType === DESIGN_CREATION_TYPE.DYNAMIC
                                            ? 'DynamicLineId'
                                            : 'TemplateToken'}: ${currentEntity.externalId}`}
                                    </p>
                                    <p>
                                        {`DesignCreationType: ${currentEntity.designCreationType}`}
                                    </p>
                                </Typography>
                            )}
                        </ModalDialogHeader>
                    </ModalDialogNav>
                    <Box className="new-quick-view-modal-body">
                        {!shouldHideForSocialMedia && (
                            <Box fontSize="standard" mb="6">
                                {productQuantity && (
                                <ShippingInfo
                                    locale={locale}
                                    quantity={productQuantity}
                                    selectedProductOptions={currentEntity.fullProductOptions}
                                />
                                )}
                            </Box>
                        )}
                        {currentEntity.colorSwatches?.length > 1 && (
                            <ColorSwatches
                                className={classnames({ 'new-quick-view-ui-density-enabled': isNewQuickViewUiDensityEnabled })}
                                colorSwatches={currentEntity.colorSwatches}
                                isNewQuickViewUiDensityEnabled={isNewQuickViewUiDensityEnabled}
                                selectedDesignId={currentEntity.currentDesignId}
                                onChange={handleColorSwatchChange}
                            />
                        )}
                        {shouldRenderFiltersSkeleton && <OptionsSkeleton />}
                        {shouldRenderFilters && (filters.map((filter) => {
                            const filterGroupData: ReturnState | null = generateFilterGroupData(
                                filter,
                                filterToRenderableOptionsMap,
                                (currentEntity as CurrentDesignOrVariationData).designVariations,
                                iconDictionary || undefined,
                            );

                            if (!filterGroupData || !filterGroupData.selectedOption || currentEntity.isFetching) {
                                return null;
                            }

                            const { filterKey, filterValue } = filterGroupData.selectedOption;
                            const key = `${filter.name}-${filterValue}`;
                            const shouldShowUnwinding = unwindingSelectedOptionKey === filterKey;

                            return (
                                <Fragment key={key}>
                                    {shouldShowUnwinding && (
                                        <UnwindingAlertBox
                                            dismissed={!shouldShowUnwindingAlertBox}
                                            ref={unwindingAlertBoxRef}
                                            onRequestDismiss={hideUnwindingAlertBox}
                                        />
                                    )}

                                    <FilterGroup
                                        className={classnames({ 'new-quick-view-ui-density-enabled': isNewQuickViewUiDensityEnabled })}
                                        isNewQuickViewUiDensityEnabled={isNewQuickViewUiDensityEnabled}
                                        selectedValue={filterValue}
                                        title={filter.title}
                                        onChange={curryHandleSelectFilter(filterKey)}
                                    >
                                        {filterGroupData.options.map(({
                                            disabled,
                                            filterKey: optionFilterKey,
                                            filterValue: optionFilterValue,
                                            title,
                                            iconography,
                                        }) => (
                                            <DesignVariationFilter
                                                currentEntity={currentEntity as CurrentDesignOrVariationData}
                                                enableUnreachable={allowUnwinding}
                                                filterKey={optionFilterKey}
                                                filterValue={optionFilterValue}
                                                iconography={iconography}
                                                isNewQuickViewUiDensityEnabled={isNewQuickViewUiDensityEnabled}
                                                key={`${optionFilterKey}-${optionFilterValue}`}
                                                title={title}
                                                unreachable={disabled}
                                                variableSelections={variableSelections}
                                            />
                                        ))}
                                    </FilterGroup>
                                </Fragment>
                            );
                        }))}
                    </Box>
                    <ModalFooter
                        adjustedDesignsVariableSelections={adjustedDesignsVariableSelections}
                        currentEntity={currentEntity}
                        engagementIdRef={engagementIdRef}
                        impressionId={impressionId}
                        locale={locale}
                        market={market}
                        pricingPresentationType={pricingPresentationType}
                        productQuantity={productQuantity}
                        quantity={quantity}
                        quickViewFilterNames={quickViewFilterNames}
                        shouldUseDiamond={shouldUseDiamond}
                        startingEntity={startingEntity}
                        studioUrl={studioUrl}
                    />
                </Column>
            </Row>
        </GridContainer>
    );
};

export const FullscreenModalContent = memo(FullscreenModalContentComponent);
