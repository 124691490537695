import { AnyAction } from 'redux';

const INITIAL_STATE: State.DesignPersonalizationState = {
    designPersonalizationSession: undefined,
    dpcsLoading: false,
};

export const DESIGN_PERSONALIZATION_SESSION_UPDATE = 'DESIGN_PERSONALIZATION_SESSION_UPDATE';
export const DPCS_LOADING_UPDATE = 'DPCS_LOADING_UPDATE';

export function reducer(
    state: State.DesignPersonalizationState = INITIAL_STATE,
    action: AnyAction,
): State.DesignPersonalizationState {
    switch (action.type) {
        case DESIGN_PERSONALIZATION_SESSION_UPDATE: {
            return {
                ...state,
                designPersonalizationSession: action.payload.dps,
            };
        }
        case DPCS_LOADING_UPDATE: {
            return {
                ...state,
                dpcsLoading: action.payload.dpcsLoading,
            };
        }
        default:
            return state;
    }
}
