import qs from 'query-string';

const baseURL = 'https://design-specifications.design.vpsvc.com/';
const instructionsDocUrl = 'https://instructions.documents.cimpress.io/v3/instructions:preview?documentUri=';

type OptionValues = Record<string, string>;

export const renderingInstructionsUrlGenerator = (
    productKey: string,
    productVersion: number,
    templateToken: string | undefined,
    locale: string,
    completeProductOptions: OptionValues,
): string => {
    const optionSelections = Object.entries(completeProductOptions).reduce(
        (acc, [key, value]) => ({
            ...acc,
            [`optionSelections[${key}]`]: value,
        }),
        {},
    );

    const params = {
        ...optionSelections,
        culture: locale,
        useFakeFamily: true,
    };

    const url = `${baseURL}v2/renderDocuments/products/${productKey}/${productVersion}/templates/${templateToken}?${qs.stringify(params)}`;

    return `${instructionsDocUrl}${encodeURIComponent(url)}`;
};
