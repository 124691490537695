import { Typography } from '@vp/swan';
import { useSelector } from 'react-redux';
import { getDebugModeEnabled } from '~/client/store/debug';
import { getHighlightKeywords, getHighlightCategories } from '~/client/store/debug/reducer';
import { BoostChip } from '~/client/components/Gallery/PreviewArea/BoostedTerms/BoostChip';

export const BoostedTermsHeader = (): JSX.Element| null => {
    const debugEnabled = useSelector(getDebugModeEnabled);
    const highlightedKeywords = useSelector(getHighlightKeywords);
    const highlightedCategories = useSelector(getHighlightCategories);

    const allBoostedTerms: any[] = [
        ...(highlightedKeywords?.map((keyword, index) => ({ term: keyword, type: 'keyword', index })) || []),
        ...(highlightedCategories?.map((category, index) => ({ term: category, type: 'category', index: index + (highlightedKeywords?.length || 0) })) || []),
    ];

    if (!debugEnabled || !allBoostedTerms?.length) {
        return null;
    }

    return (
        <>
            <Typography fontSize="xlarge" fontWeight="bold">All Boosted Terms: </Typography>
            { allBoostedTerms.map(({ index, type, term }) => (
                <BoostChip index={index} key={`${type}${term}`} term={term} type={type as 'keyword' | 'category'} />
            )) }
        </>
    );
};
