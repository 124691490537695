import { useSelector } from 'react-redux';
import { selectQueryState, selectQueryString } from '~/client/store/locationState';
import { getBreadcrumbs, getTlpLevel } from '~/client/store/config/reducer';
import { mutateQueryStateParamsWithRefinementToAdd, mutateQueryStateParamsWithRefinementToRemove } from '~/client/hooks/RedirectToL0/utils';
import { getIsL1orL2 } from '~/client/store/config';
import { REFINEMENT_DIMENSION } from '~/shared/constants';
import { getMetadataQueryParams } from '~/client/store/content/utils';

type UseRedirectToL0ReturnValue = (
    refinementToAdd: State.Refinement[],
    refinementToRemove: State.Refinement[],
) => boolean;

export const useRedirectToL0 = (): UseRedirectToL0ReturnValue => {
    const breadcrumbs = useSelector(getBreadcrumbs);
    const queryState = useSelector((state: State.GlobalState) => selectQueryState(state, { isRedirectToL0: true }));
    const isL1orL2 = useSelector(getIsL1orL2);
    const tlpLevel = useSelector(getTlpLevel);
    const { keywords: metadataKeywords, attributes: metadataAttributes } = useSelector(getMetadataQueryParams);

    return (
        refinementToAdd: State.Refinement[],
        refinementToRemove: State.Refinement[],
    ): boolean => {
        if (!isL1orL2) {
            return false;
        }

        const breadcrumbsOffset = tlpLevel === 'l1' ? -2 : -3;
        const tlpL0Url = breadcrumbs && breadcrumbs.slice(breadcrumbsOffset)[0]?.url;

        const newQueryStateParams = {
            ...queryState.parameters,
            keyword: [queryState.parameters.keyword, ...metadataKeywords].join(' '),
            attributes: [...queryState.parameters.attributes, ...Object.values(metadataAttributes).flat()],
        };

        delete newQueryStateParams.page;

        if (
            ![
                ...refinementToAdd,
                ...refinementToRemove,
            ].some((element) => element.dimension === REFINEMENT_DIMENSION.CATEGORY)
        ) {
            return false;
        }

        mutateQueryStateParamsWithRefinementToAdd(newQueryStateParams, refinementToAdd);
        mutateQueryStateParamsWithRefinementToRemove(newQueryStateParams, refinementToRemove);

        const queryString = selectQueryString({
            parameters: newQueryStateParams,
            replace: false,
        }, '');

        if (tlpL0Url) {
            window.location.href = tlpL0Url + queryString;
            return true;
        }

        return false;
    };
};
