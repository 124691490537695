import config from 'config';
import classnames from 'classnames';
import {
    Button, ButtonProps, Link, LinkProps, Icon,
} from '@vp/swan';
import { useTranslations } from 'client/hooks/useTranslations';
import { ComponentProps, useMemo } from 'react';
import { getLocaleSelector } from 'client/store/config/reducer';
import { useSelector } from 'react-redux';
import { useAnalytics } from 'client/hooks/gallery/useAnalytics';
import { ANALYTICS_EVENT_ACTIONS, ANALYTICS_LABEL } from 'shared/constants';
import { getProductKey } from 'client/store/config';
import { SELECTED_PRODUCT_QSP } from 'client/constants';
import { FAVORITES_PAGE_URL_ID } from '~/client/components/Gallery/Header/Favorites/constants';
import { useQueryUrl } from '~/client/components/Gallery/Header/Favorites/useQueryUrl';
import { buildFavoritesUrl } from './utils';

export type PropTypes = Gallery.Analytics.ButtonLocationProp & ComponentProps<typeof Button>;

export const FavoritesCTA = (props: PropTypes): JSX.Element => {
    const { className, userInteractionLocation, ...rest } = props;
    const localize = useTranslations();
    const locale = useSelector(getLocaleSelector);
    const analytics = useAnalytics();
    const productKey = useSelector(getProductKey).toUpperCase();

    const { data, isLoading } = useQueryUrl(locale, FAVORITES_PAGE_URL_ID, {
        staleTime: Infinity,
    });

    const favoritesUrl = useMemo(() => (
        buildFavoritesUrl(data, locale, `${SELECTED_PRODUCT_QSP}=${productKey}`)
    ), [data, locale, productKey]);

    const onClick = (): void => {
        analytics.trackEvent({
            userInteractionLocation,
            action: ANALYTICS_EVENT_ACTIONS.NAVIGATION_CLICKED,
            eventLabel: ANALYTICS_LABEL.FAVORITES_CTA,
            route: config.client.segmentRoute,
        });

        window.location.href = favoritesUrl;
    };

    const favoritesButtonProps: ButtonProps = {
        ...rest,
        component: 'a',
        disabled: isLoading,
        iconPosition: 'left',
        render: (linkProps: LinkProps): JSX.Element => (
            <Link
                aria-label={localize('FavoriteButtonA11yText')}
                className={linkProps.className}
                href={favoritesUrl}
                onClick={onClick}
            >
                {linkProps.children}
            </Link>
        ),
    };

    return (
        <>
            <Button {...favoritesButtonProps} buttonShape="round" className={classnames('favorites-cta-mobile-only', className)}>
                <Icon alt="" iconType="heartEmpty" />
            </Button>
            <Button {...favoritesButtonProps} className={classnames('favorites-cta-desktop-only', className)}>
                <Icon iconType="heartEmpty" />
                {localize('Favorites')}
            </Button>
        </>
    );
};

FavoritesCTA.displayName = 'FavoritesCTA';
