import { RenderProperty } from 'shared/renderProperties';
import {
    ANIMATION_CONFIGS,
    DEFAULT_ANIMATION, SWIVEL_ANIMATION,
} from 'client/components/Gallery/PreviewArea/GalleryQuickView/components/VortexPreview/constants';
import { stringRenderPropertySelector } from 'client/store/config';
import { useSelector } from 'react-redux';

export const usePreviewAnimation = (): string => {
    const stringRenderProperty = useSelector(stringRenderPropertySelector);
    const previewAnimationProperty = stringRenderProperty(RenderProperty.VortexPreviewAnimation);

    if (typeof previewAnimationProperty === 'string') {
        return ANIMATION_CONFIGS[previewAnimationProperty] ? previewAnimationProperty : DEFAULT_ANIMATION;
    }

    return SWIVEL_ANIMATION;
};
