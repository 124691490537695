import classnames from 'classnames';
import { Box, Typography } from '@vp/swan';
import { ColorSwatchPicker } from 'client/components/common/ColorSwatchPicker';
import { useTranslations } from 'client/hooks/useTranslations';
import { HTMLProps, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import { useSelector } from 'react-redux';
import { getLocaleSelector } from 'client/store/config';

export interface PropTypes extends Omit<HTMLProps<HTMLDivElement>, 'onChange'> {
    colorSwatches: Gallery.ContentQuery.ColorSwatch[];
    onChange: (selectedValue: string) => void;
    selectedDesignId: string;
    isNewQuickViewUiDensityEnabled: boolean;
}

export const ColorSwatches = (props: PropTypes): JSX.Element => {
    const colorLabelRef = useRef<string>(uuid());
    const {
        className,
        colorSwatches,
        onChange,
        selectedDesignId,
        isNewQuickViewUiDensityEnabled,
        ...rest
    } = props;
    const localize = useTranslations();
    const locale = useSelector(getLocaleSelector);

    const colorLabelId = `color-swatch-${colorLabelRef.current}`;

    return (
        <div {...rest} className={classnames('quick-view-color-swatches', className)}>
            {isNewQuickViewUiDensityEnabled && (
                <Box mb={2}>
                    <Typography
                        as="label"
                        className="color-swatch-label"
                        fontSkin="body-standard-bold"
                        id={colorLabelId}
                    >
                        {localize('QuickViewColorsTitle')}
                    </Typography>
                </Box>
            )}
            {!isNewQuickViewUiDensityEnabled && (
                <Typography
                    className="color-swatch-label"
                    component="p"
                    fontSize="standard"
                    fontWeight="bold"
                    id={colorLabelId}
                >
                    {localize('QuickViewColorsTitle')}
                </Typography>
            )}
            <ColorSwatchPicker
                ariaLabelledBy={colorLabelId}
                colorSwatches={colorSwatches}
                colorSwatchesProps={{
                    size: 'super',
                }}
                locale={locale}
                selectedDesignId={selectedDesignId}
                onColorSwatchChange={onChange}
            />
        </div>
    );
};
