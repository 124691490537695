import React, {
    FunctionComponent, HTMLAttributes, memo, ReactElement, useCallback, useMemo,
} from 'react';

import {
    VortexContainerProps,
    VortexContainer,
    VortexCanvas,
    VortexActions,
} from '@rendering/vortex-react';
import classnames from 'classnames';
import { FlexBox, Spinner } from '@vp/swan';
import { useSelector } from 'react-redux';
import { globalQuickViewDataSelector } from 'client/store/quickView/selectors';
import { State as CurrentDesignOrVariationState } from 'client/components/Gallery/PreviewArea/GalleryQuickView/hooks/useCurrentDesignOrVariation';
import { State as CurrentDesignState } from 'client/components/Gallery/PreviewArea/GalleryQuickView/hooks/useCurrentDesign';
import {
    renderingInstructionsUrlGenerator,
} from 'client/components/Gallery/PreviewArea/GalleryQuickView/utils/renderingInstuctionUrlGenerator';
import { designPersonalizationContextSelector } from '~/client/store/designPersonalization/selectors';
import { getUseRealisationEngineService } from 'client/store/debug/reducer';
import { instrumenter } from 'client/utils/instrumentation';
import { PAGE_ACTION_TYPES } from 'client/constants';
import { getLogger } from 'client/utils/gallery/logger';
import { useTranslations } from 'client/hooks/useTranslations';
import {
    VortexPreviewActionButtons,
} from 'client/components/Gallery/PreviewArea/GalleryQuickView/components/VortexPreview/components/VortexPreviewActionButtons';
import {
    usePreviewAnimation,
} from 'client/components/Gallery/PreviewArea/GalleryQuickView/components/VortexPreview/hooks/usePreviewAnimation';
import { VortexContext } from '@rendering/vortex-react/dist/esm/vortexContext';
import {
    ANIMATION_CONFIGS, CANVAS_DEFAULT_STYLE, DEFAULT_ANIMATION, DEFAULT_VORTEX_CAMERA_OPTIONS,
    VORTEX_CAMERA_OPTIONS,
    VORTEX_DEFAULT_TENANT, VORTEX_USER,
} from './constants';

export type PropTypes = {
    currentEntity: CurrentDesignOrVariationState | CurrentDesignState;
    templateToken?: string;
    shouldUseDiamond: boolean;
    fallbackComponent: ReactElement;
    canvasAttributes?: HTMLAttributes<HTMLCanvasElement>
};

const VortexPreviewComponent: FunctionComponent<PropTypes> = ({
    currentEntity, templateToken, shouldUseDiamond, fallbackComponent, canvasAttributes,
}): React.JSX.Element => {
    const { locale } = useSelector(globalQuickViewDataSelector);
    const designPersonalizationContext = useSelector(designPersonalizationContextSelector);
    const useRealisationEngineService = useSelector(getUseRealisationEngineService);
    const { personalizedInstructionsUrl } = currentEntity.renderablePreviews;
    const previewAnimation = usePreviewAnimation();
    const {
        productKey, fullProductOptions, productVersion, designId, previewInfo,
    } = currentEntity;

    const initializationOptions = ANIMATION_CONFIGS[previewAnimation];
    const cameraOptions = previewAnimation === DEFAULT_ANIMATION
        ? DEFAULT_VORTEX_CAMERA_OPTIONS
        : VORTEX_CAMERA_OPTIONS;

    const localize = useTranslations();

    const handleVortexError = useCallback(() => (error: Error, info: {
        componentStack: string;
    }): void => {
        const payload = {
            templateToken,
            info,
            error,
            productKey,
            productVersion,
            productOptions: fullProductOptions,
        };

        instrumenter.recordPageAction(PAGE_ACTION_TYPES.VORTEX_RENDER_FAILURE, { ...payload, error });
        getLogger().warning(`Vortex failed to load for entity ${designId}`, error, {
            ...payload,
        });
    }, [designId, fullProductOptions, productKey, productVersion, templateToken]);

    const productConfig: VortexContainerProps['productConfig'] = useMemo(() => ({
        sku: productKey,
        attributes: fullProductOptions,
        version: productVersion,
        tenant: VORTEX_DEFAULT_TENANT,
    }), [fullProductOptions, productKey, productVersion]);

    const shouldUseInstructionsUrl = shouldUseDiamond || useRealisationEngineService;

    const instructionsUrl = designPersonalizationContext
        ? personalizedInstructionsUrl
        : previewInfo.instructionsUrl;

    const renderingUrl = useMemo(() => (shouldUseInstructionsUrl ? instructionsUrl : renderingInstructionsUrlGenerator(
        productKey,
        productVersion,
        templateToken,
        locale,
        fullProductOptions,
    )), [
        shouldUseInstructionsUrl,
        instructionsUrl,
        productKey,
        productVersion,
        templateToken,
        locale,
        fullProductOptions,
    ]);

    return (
        <div className={classnames('vortex-container', 'vortex-no-controls', 'vortex', 'swan')}>
            <VortexContainer
                preservePreview
                cameraOptions={cameraOptions}
                fallback={fallbackComponent}
                initializationOptions={initializationOptions}
                productConfig={productConfig}
                renderingInstructionsUrl={renderingUrl}
                user={VORTEX_USER}
                onError={(handleVortexError) as (error: Error) => void}
            >
                {({
                    updatingCanvas, loadingModel, error,
                }: VortexContext): React.JSX.Element => {
                    const canvasClassName = error ? 'has-error' : '';
                    const canvasStyle = error ? undefined : CANVAS_DEFAULT_STYLE;

                    return (
                        <>
                            {!!error && fallbackComponent}
                            {((updatingCanvas || loadingModel) && !error) && (
                                <div className="mcp-img-loading">
                                    <Spinner accessibleText={localize('Loading')} size="tiny" />
                                </div>
                            )}
                            <VortexCanvas {...canvasAttributes} className={canvasClassName} style={canvasStyle} />
                            {!error && (
                                <VortexActions animations cameraViews>
                                    {
                                        ({ actions }): React.JSX.Element => (
                                            <FlexBox
                                                aria-label={localize('Preview')}
                                                justifyContent="center"
                                                role="radiogroup"
                                            >
                                                <VortexPreviewActionButtons actions={actions} />
                                            </FlexBox>
                                        )
                                    }
                                </VortexActions>
                            )}
                        </>
                    );
                }}
            </VortexContainer>
        </div>
    );
};

export const VortexPreview = memo(
    VortexPreviewComponent,
    (prevProps, nextProps) => prevProps.currentEntity.studioUrl === nextProps.currentEntity.studioUrl
        && prevProps.templateToken === nextProps.templateToken
        && prevProps.shouldUseDiamond === nextProps.shouldUseDiamond,
);
