import config from 'config';
import {
    getMarket,
    getMpvid,
    stringRenderPropertySelector,
    booleanRenderPropertySelector,
    getLocaleSelector,
    getQuantitySelector,
    selectedOptionsSelector,
    getProductKey,
} from 'client/store/config';
import { getBypassApproval, getNoCache, getUseConstraints } from 'client/store/debug';
import { maxPreviewHeightSelector } from 'client/store/design';
import { RenderProperty } from 'shared/renderProperties';
import { QUICKVIEW_FILTER_TYPE, REFINEMENT_DIMENSION, SCENE_SOUCE } from '~/shared/constants';
import { templateUseCaseRefinementsSelector } from 'client/store/refinement/selectors';
import { createSelector } from 'reselect';
import { filterByIdSelector, selectFilters } from '../filter';
import { filterOptionByIdSelector } from '../filterOption';
import { quickViewFilterByIdSelector, selectQuickViewFilterNames } from '../quickViewFilter';
import {
    getDebugModeEnabled, getDesignCreationTypes, getSearchBackend, getUseRealisationEngineService,
} from '../debug/reducer';

const { client: { features } } = config;

export const globalQuickViewDataSelector = (state: State.GlobalState): Gallery.QuickView.QuickViewGlobalData => {
    const market = getMarket(state);
    const mpvId = getMpvid(state);
    const locale = getLocaleSelector(state);
    const quantity = getQuantitySelector(state);
    const maxPreviewHeight = maxPreviewHeightSelector(state);
    const bypassedApproval = getBypassApproval(state);

    return {
        market,
        mpvId,
        locale,
        quantity,
        maxPreviewHeight,
        bypassedApproval,
    };
};

export const quickViewControlFlagsSelector = (state: State.GlobalState): Gallery.QuickView.QuickViewControlFlags => {
    const stringRenderProperty = stringRenderPropertySelector(state);
    const booleanRenderProperty = booleanRenderPropertySelector(state);
    const pricingPresentationType = stringRenderProperty(RenderProperty.PricingPresentationType);
    const shouldRenderVortex = features.EnableVortex && booleanRenderProperty(RenderProperty.VortexEnabled);
    const shouldRenderIconography = features.EnableIconography
    && booleanRenderProperty(RenderProperty.IconographyEnabled);
    const showComparativeNames = booleanRenderProperty(RenderProperty.ShowComparativeNames);

    return {
        pricingPresentationType,
        shouldRenderVortex,
        showComparativeNames,
        shouldRenderIconography,
    };
};

export const designVariationsInputSelector = createSelector(
    getProductKey,
    getMpvid,
    getLocaleSelector,
    getSearchBackend,
    getNoCache,
    getUseConstraints,
    selectQuickViewFilterNames,
    selectFilters,
    selectedOptionsSelector,
    getUseRealisationEngineService,
    getDesignCreationTypes,
    templateUseCaseRefinementsSelector,
    getDebugModeEnabled,
    filterOptionByIdSelector,
    stringRenderPropertySelector,
    quickViewFilterByIdSelector,
    filterByIdSelector,
    (
        productKey,
        mpvId,
        locale,
        searchBackend,
        noCache,
        useConstraints,
        quickviewFilterNames,
        allFilters,
        selectedOptionsFromUrl,
        useRealisationEngineService,
        designCreationTypes,
        templateUseCaseRefinements,
        debugEnabled,
        getOption,
        stringRenderProperty,
        quickViewFilterById,
        filterById,
    ) => (
        tileEntity: State.TileEntity,
        selectedPartialProductOptions: Gallery.ContentQuery.ProductOptions,
        selectedTemplateUseCases?: string[],
    ): DesignVariations.DesignVariationsInput => {
        const sceneSource = stringRenderProperty(RenderProperty.SceneSource) ?? SCENE_SOUCE.GALLERY_SOURCED;
        const quickviewFilters = quickviewFilterNames.map((f) => filterById(f));
        const quickviewFilterMetadata = quickviewFilterNames.map((f) => quickViewFilterById(f));
        const filters = allFilters.map((f) => filterById(f.name));

        const selectedOptions = {} as Gallery.ContentQuery.ProductOptions;
        const templateUseCases = selectedTemplateUseCases as string[];
        let variableOptions = [] as string[];

        Object.entries(selectedPartialProductOptions)
            .forEach(([productOptionName, productOptionValue]) => {
                const quickViewFilterExists = quickviewFilters
                    .filter((x) => x.dimension !== REFINEMENT_DIMENSION.TEMPLATE_USE_CASE)
                    .some((f) => {
                        const option = getOption((f as State.AttributeFilter).options[0]);

                        return option.productOption?.optionName === productOptionName;
                    });
                const filterExists = filters.some((f) => {
                    if (!('options' in f)) {
                        return false;
                    }

                    const option = getOption((f as State.AttributeFilter).options[0]);

                    return option.productOption?.optionName === productOptionName;
                });

                if (quickViewFilterExists) {
                    variableOptions = variableOptions.concat(productOptionName);
                } else if (selectedOptionsFromUrl[productOptionName] || filterExists) {
                    selectedOptions[productOptionName] = productOptionValue;
                }
            });

        const variableAttributes = quickviewFilterMetadata
            .filter((f) => f.quickViewFilterType === QUICKVIEW_FILTER_TYPE.ATTRIBUTE)
            .map((f) => {
                const filter = filterById(f.name) as State.AttributeFilter;
                const option = getOption((filter).options[0]);

                return option.value.split('_')[0];
            });

        const variableTemplateUseCases = quickviewFilterMetadata
            .filter((f) => f.quickViewFilterType === QUICKVIEW_FILTER_TYPE.TEMPLATE_USE_CASE
            && templateUseCases?.includes(
                (filterById(f.name) as State.TemplateUseCaseFilter).templateUseCaseId,
            ))
            .map((f) => (filterById(f.name) as State.TemplateUseCaseFilter).templateUseCaseId);

        return {
            templateToken: tileEntity.previewInfo.templateToken,
            useRealisationEngineService,
            designCreationTypes,
            locale,
            selectedOptions,
            variableOptions,
            variableAttributes,
            productKey,
            mpvId,
            noCache,
            useConstraints,
            sceneSource,
            searchBackend,
            templateUseCases: templateUseCaseRefinements.map((r) => r.value),
            variableTemplateUseCases,
            debug: debugEnabled,
        };
    },
);
