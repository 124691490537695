import {
    ChangeEvent, FunctionComponent, useEffect, useState,
} from 'react';
import { Checkbox, Box } from '@vp/swan';
import classnames from 'classnames';

import { useTranslations } from 'client/hooks/useTranslations';
import { instrumenter } from 'client/utils/instrumentation';
import { PAGE_ACTION_TYPES } from 'client/constants';
import { getFavoritesAction } from 'client/components/common/FavoriteButton/utils';
import { DismissableAlertBox } from 'client/components/common/DismissableAlertBox';

interface FavoriteButtonProps {
    onChange: (event: ChangeEvent<HTMLInputElement>) => Promise<void>,
    isFavorite: boolean,
}

export const FavoriteButton: FunctionComponent<FavoriteButtonProps> = ({ onChange, isFavorite }) => {
    const localize = useTranslations();
    const [isDisabled, setDisabled] = useState(false);
    const [isChecked, setChecked] = useState(isFavorite);
    const [errorMessage, setErrorMessage] = useState<string|null>(null);

    const ariaLabel = isFavorite ? localize('FavoritesRemoveMessage') : localize('FavoritesAddMessage');

    useEffect(() => {
        setChecked(isFavorite);
    }, [isFavorite]);

    const handleStopPropagation = (e: React.MouseEvent): void => e.stopPropagation();

    const handleChange = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
        try {
            if (isDisabled) {
                return;
            }

            setDisabled(true);
            setChecked(!isFavorite);
            // Record success
            instrumenter.recordPageAction(PAGE_ACTION_TYPES.FAVORITE_BUTTON_CLICKED, {
                action: getFavoritesAction(isFavorite),
            });
            await onChange(e);
        } catch (error) {
            setErrorMessage(localize(isFavorite ? 'FavoritesRemoveErrorMessage' : 'FavoritesAddErrorMessage'));
            // Roll back previous state of error
            setChecked(isFavorite);
            // Record failure
            instrumenter.recordPageAction(PAGE_ACTION_TYPES.FAVORITES_UPDATE_FAILED, {
                info: error,
                action: getFavoritesAction(isFavorite),
            });
        } finally {
            setDisabled(false);
        }
    };

    const handleErrorDismiss = (): void => {
        setErrorMessage(null);
    };

    return (
        <>
            {errorMessage && (
                <DismissableAlertBox
                    toast
                    className="favorite-button-alert-box"
                    dismissVisuallyHiddenLabel={localize('DismissAlert')}
                    skin="error"
                    onClick={handleStopPropagation}
                    onRequestDismiss={handleErrorDismiss}
                >
                    {errorMessage}
                </DismissableAlertBox>
            )}
            <Box className={classnames('favorite-button-area', { 'favorite-button-area-no-pointer': isDisabled })}>
                <Checkbox
                    aria-label={ariaLabel}
                    checked={isChecked}
                    className="favorite-button"
                    skin="favorite"
                    onChange={handleChange}
                />
            </Box>

        </>
    );
};
