import { ModalDialog } from '@vp/swan';
import { useAnalytics } from '~/client/hooks/gallery/useAnalytics';
import { ANALYTICS_EVENT_ACTIONS } from '~/shared/constants';
import { PersonalizationImageModalContent } from '../../../Filters/PersonalizationV1/PersonalizationImageModalContent';
import { MobilePersonalizationModalContent } from './MobilePersonalizationModalContent';
import { usePersonalizationFlyoutOpen } from '../hooks';

export const MobilePersonalizationDialogV1 = (): JSX.Element => {
    const {
        uploadModalOpen,
        setUploadModalOpen,
        mobilePersonalizationModalOpen,
        setMobilePersonalizationModalOpen,
    } = usePersonalizationFlyoutOpen();
    const analytics = useAnalytics();

    const handleModalClose = ((): void => {
        if (!uploadModalOpen) {
            analytics.trackEvent({
                action: ANALYTICS_EVENT_ACTIONS.FLY_OUT_CLOSED,
                eventLabel: 'Personalization Close Button',
                eventDetail: 'Personalization Mobile Modal Closed',
                ...analytics.getPageProperties(),
            });
        }

        setMobilePersonalizationModalOpen(false);
        setUploadModalOpen(false);
    });

    return (
        <ModalDialog
            className={uploadModalOpen ? 'personalization-image-modal' : 'mobile-personalization-dialog'}
            isOpen={mobilePersonalizationModalOpen}
            variant="panel-right"
            onRequestDismiss={handleModalClose}
        >
            {uploadModalOpen ? (
                <PersonalizationImageModalContent />
            ) : (
                <MobilePersonalizationModalContent />
            )}
        </ModalDialog>
    );
};

MobilePersonalizationDialogV1.displayName = 'MobilePersonalizationDialogV1';
