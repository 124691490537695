import { useSelector } from 'react-redux';
import { globalQuickViewDataSelector, quickViewControlFlagsSelector } from 'client/store/quickView/selectors';
import { ContextualBatchPricingDisplay } from 'client/components/common/PricingDisplay/ContextualBatchPricingDisplay';

type Props = Pick<Gallery.QuickView.QuickViewCurrentDesignData,
'productKey'|
'productVersion'|
'fullProductOptions'|
'productOptionsByProductKey' |
'productOptions'>;

export const QuickViewFilterPricing = (props: Props): JSX.Element => {
    const {
        productKey,
        productOptions,
        productOptionsByProductKey,
        productVersion,
        fullProductOptions,
    } = props;

    const {
        locale,
        market,
        quantity,
    } = useSelector(globalQuickViewDataSelector);

    const {
        pricingPresentationType,
    } = useSelector(quickViewControlFlagsSelector);

    return (
        <ContextualBatchPricingDisplay
            differentialPrice
            hideShippingMessageInVatMessage
            hideVatMessage
            fullProductOptions={fullProductOptions}
            lines={1}
            locale={locale}
            market={market}
            pricingPresentationType={pricingPresentationType}
            productKey={productKey}
            productOptionsByProductKey={productOptionsByProductKey}
            productVersion={productVersion || undefined}
            quantity={quantity}
            selectedProductOptions={productOptions}
        />
    );
};
