import { SelectionSet, Typography } from '@vp/swan';
import classnames from 'classnames';
import { ComponentProps, HTMLProps } from 'react';
import { Skeleton } from '../../Skeleton';
import { FilterSkeleton } from './FilterSkeleton';

export interface PropTypes extends Omit<HTMLProps<HTMLDivElement>, 'onChange'> {
    typographyProps?: ComponentProps<typeof Typography>;
    variant?: ComponentProps<typeof SelectionSet>['variant'];
}

export const FilterGroupSkeleton = (props: PropTypes): JSX.Element => {
    const {
        children,
        className,
        variant = 'single-select',
        ...rest
    } = props;

    return (
        <div {...rest} aria-label="Filter Group Skeleton" className={classnames('quick-view-filter-group', className)}>
            <Skeleton className={classnames('quick-view-filter-label')} />
            <SelectionSet
                className={classnames('quick-view-filter')}
                variant={variant}
            >
                <FilterSkeleton title={<Skeleton />}>
                    <Skeleton />
                </FilterSkeleton>
                <FilterSkeleton title={<Skeleton />}>
                    <Skeleton />
                </FilterSkeleton>
            </SelectionSet>
        </div>
    );
};
