import { useMutation } from '@tanstack/react-query';
import { useAuth } from 'client/hooks/useAuth';
import { createTemplateInteractions } from '~/client/mutations/createTemplateInteraction';
import { TEMPLATE_INTERACTION_TYPE, TEMPLATE_INTERACTION_SUBTYPE } from '~/experiments/DesignBoost/constants';

interface CreateInteractionsParams {
    productKey: string;
    categoryKeys: string[];
    keywords: string[];
    type: typeof TEMPLATE_INTERACTION_TYPE[keyof typeof TEMPLATE_INTERACTION_TYPE];
}

export const useTemplateInteractions = (): { createInteractions: (params: CreateInteractionsParams) => Promise<void> } => {
    const auth = useAuth();
    const createTemplateInteractionsMutation = useMutation(createTemplateInteractions);

    const createInteractions = async ({
        productKey, categoryKeys = [], keywords = [], type,
    }: CreateInteractionsParams): Promise<void> => {
        if (!auth?.canonicalId
            || (keywords.length === 0 && categoryKeys.length === 0)) {
            return;
        }

        const timestamp = new Date().toISOString();
        const interactions = [
            ...keywords.map((keyword) => ({
                productKey,
                type,
                subtype: TEMPLATE_INTERACTION_SUBTYPE.KEYWORD,
                data: keyword,
                timestamp,
                count: 1,
            })),
            ...categoryKeys.map((categoryKey) => ({
                productKey,
                type,
                subtype: TEMPLATE_INTERACTION_SUBTYPE.CATEGORY,
                data: categoryKey,
                timestamp,
                count: 1,
            })),
        ];

        await createTemplateInteractionsMutation.mutateAsync({
            canonicalId: auth.canonicalId,
            interactions,
            accessToken: auth?.accessToken || undefined,
        });
    };

    return { createInteractions };
};
