import config from 'config';
import { QueryFunctionContext } from '@tanstack/react-query';
import { ProductCatalogPricingService } from 'services/ProductCatalogPricingService';

import { getLogger } from 'client/utils/gallery/logger';

export const QUERY_KEY = 'estimated_pricing';

const productCatalogPricingService = new ProductCatalogPricingService(
    config.services.productCatalogPricingService,
    getLogger,
);

export const queryBatchEstimatedPricing = async (
    context: QueryFunctionContext<VP.PCT.Queries.ProductCatalogPricingService.QuantitiesPriceQueryKey>,
): Promise<VP.PCT.Models.ProductCatalogPricingService.QuantitiesPricingResult | null> => {
    const { queryKey } = context;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, {
        market,
        productKey,
        quantities,
        productVersion,
        pricingContext,
        selections,
    }] = queryKey;

    const prices = await productCatalogPricingService.getQuantityPrices(
        market,
        productKey,
        quantities,
        productVersion,
        pricingContext,
        selections,
    );

    if (!prices || !prices.estimatedPrices) {
        return null;
    }

    return prices;
};
