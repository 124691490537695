import { REFINEMENT_DIMENSION } from '~/shared/constants';

export const excludeRefinement = (refinements: Refinements.Refinements, refinementToExclude: REFINEMENT_DIMENSION): Refinements.RefinementItem[] => {
    const filteredRefinements = [];

    for (const key in refinements) {
        if (
            Object.hasOwn(refinements, key)
            && refinements[key].dimension !== refinementToExclude
        ) {
            filteredRefinements.push(refinements[key]);
        }
    }
    return filteredRefinements;
};
