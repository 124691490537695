import { AnyAction } from 'redux';
import { CONTENT_UPDATE } from 'client/store/constants';
import { createSelector } from 'reselect';

const initialState: State.TileEntityState = {
    byId: {},
    allIds: [],
};

const updateState = (
    action: AnyAction,
    state: State.TileEntityState = initialState,
): State.TileEntityState => ({
    ...state,
    byId: action.payload.tileEntities.byId,
    allIds: action.payload.tileEntities.allIds,
});

export function reducer(
    state: State.TileEntityState = initialState,
    action: AnyAction,
): State.TileEntityState {
    switch (action.type) {
        case CONTENT_UPDATE:
            return updateState(action, state);
        default:
            return state;
    }
}

export const tileEntityAllIdsSelector = (state: State.GlobalState): string[] => (state.tileEntities.allIds);

export const tileEntityByIdSelector = createSelector(
    (state: State.GlobalState) => state.tileEntities,
    (tileEntities) => (id: string): State.TileEntity => tileEntities.byId[id],
);
