import { QUICKVIEW_FILTER_TYPE } from '~/shared/constants';

export const buildFiltersFromName = (
    names: string[],
    selectFilterById: (name: string) => State.Filter,
    selectQuickViewFilterById: (name: string) => State.QuickViewFilter,
    getTemplateUseCaseById: (id: string) => State.TemplateUseCase,
): State.QuickViewAttributeFilter[] => (names
    .map((f) => {
        const filter = selectFilterById(f);
        const result = {
            ...filter,
            ...selectQuickViewFilterById(f),
        } as State.QuickViewAttributeFilter;

        if (result.quickViewFilterType === QUICKVIEW_FILTER_TYPE.TEMPLATE_USE_CASE) {
            const templateUseCase = getTemplateUseCaseById((filter as State.TemplateUseCaseFilter).templateUseCaseId);

            result.options = templateUseCase.children;
        }

        return result as State.QuickViewAttributeFilter;
    })
    .sort((a, b) => a.quickViewSortOrder - b.quickViewSortOrder)
);
