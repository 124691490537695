import { Typography } from '@vp/swan';
import { SignInButton } from '../../../common/SignInButton';
import { useTranslations } from '../../../../hooks/useTranslations';

export interface BrandSignInProps {
    canSave: boolean;
    linkStyle: 'inline' | 'below';
    beforeSignIn?: () => void;
}

export const BrandSignIn = (props: BrandSignInProps): JSX.Element => {
    const { canSave, linkStyle, beforeSignIn } = props;
    const localize = useTranslations();

    if (linkStyle === 'inline') {
        const localizedSignInString = canSave
            ? localize('PersonalizationSaveBrandFooter')
            : localize('PersonalizationBrandFooter');
        const [signInPreText, signInText, signInPostText] = localizedSignInString.split(/<\/?link>/);

        return (
            <Typography fontSkin="body-small" mt="to-actions" textAlign="center">
                {signInPreText}
                <SignInButton
                    component="button"
                    fontSkin="body-small"
                    signInText={signInText}
                />
                {signInPostText}
            </Typography>
        );
    }

    const signInText = canSave
        ? localize('PersonalizationMobileSaveBrandFooter')
        : localize('PersonalizationMobileBrandFooter');

    return (
        <>
            <Typography fontSkin="body-small" mb="to-actions" textAlign="center">
                {signInText}
            </Typography>

            <SignInButton
                beforeSignIn={beforeSignIn}
                component="button"
                fontSkin="body-standard-bold"
                signInText={localize('SignInButtonText')}
                skin="cta"
            />
        </>
    );
};
