import { memo, useMemo } from 'react';
import { useInView } from 'react-intersection-observer';
import { StickyHeader } from 'client/components/Gallery/Header/StickyHeader';
import { StaticHeader } from 'client/components/Gallery/Header/StaticHeader';
import { galleryHeaderId } from 'client/components/Gallery/Header/constants';
import { useIsPersonalizationEnabled } from '~/client/hooks/useIsPersonalizationEnabled';
import { PersonalizationWrapper } from '../Filters/PersonalizationV1/PersonalizationWrapper';
import { MobilePersonalizationDialogV1 } from './PersonalizationV1/MobilePersonalization/MobilePersonalizationDialog';
import { PersonalizationModalV2 } from '../PersonalizationV2/PersonalizationModal';

export const Header = memo((): JSX.Element => {
    const { ref: headerRef, inView, entry } = useInView();
    const headerIsVisible = useMemo(() => (inView || !entry), [inView, entry]);
    const { isMobilePersonalizationEnabled, isPersonalizationV2Enabled } = useIsPersonalizationEnabled();

    return (
        <div className="gallery-header" id={galleryHeaderId} ref={headerRef}>
            {!headerIsVisible && <StickyHeader />}
            {headerIsVisible && <StaticHeader />}
            {isMobilePersonalizationEnabled && !isPersonalizationV2Enabled && (
                <PersonalizationWrapper>
                    <MobilePersonalizationDialogV1 />
                </PersonalizationWrapper>
            )}
            {isMobilePersonalizationEnabled && isPersonalizationV2Enabled && <PersonalizationModalV2 />}

        </div>
    );
});

Header.displayName = 'Header';
