import { Box, Card, Typography } from '@vp/swan';
// import { useEffect } from 'react';
import { Filters } from 'client/components/Gallery/Filters';
import { useTranslations } from 'client/hooks/useTranslations';
import { useIsPersonalizationEnabled } from '~/client/hooks/useIsPersonalizationEnabled';
// import { useAnalytics } from '~/client/hooks/gallery/useAnalytics';
// import { ANALYTICS_EVENT_ACTIONS, ANALYTICS_LABEL } from '~/shared/constants';

// TODO: PostRefactor: Audit CSS
export const PersonalizationSidebarV2 = (): JSX.Element => {
    const localize = useTranslations();
    // const analytics = useAnalytics();
    const { shouldShowColorFilterFirst } = useIsPersonalizationEnabled();

    // useEffect(() => {
    //     analytics.trackEvent({
    //         action: ANALYTICS_EVENT_ACTIONS.PERSONALIZATION_VIEWED,
    //         eventLabel: ANALYTICS_LABEL.PERSONALIZATION_VIEWED,
    //         eventDetail: 'Personalization Sidebar Viewed',
    //         ...analytics.getPageProperties(),
    //     });
    // }, [analytics]);

    return (
        <div className="personalization-sidebar-container">
            <Card
                bgc="accent"
                className="personalization-sidebar-collapsible"
                mb="between-subsections"
                p={6}
            >
                <div>V2</div>
            </Card>
            <Box>
                <Typography fontSkin="title-subsection" pt={6}>
                    {localize('PersonalizationAlternativeFiltersMenuHeader')}
                </Typography>
                <Filters showColorFirst={shouldShowColorFilterFirst} />
            </Box>
        </div>
    );
};

PersonalizationSidebarV2.displayName = 'PersonalizationSidebarV2';
