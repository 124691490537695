import {
    Box,
    Card,
    Typography,
} from '@vp/swan';
import { useEffect } from 'react';
import { Filters } from 'client/components/Gallery/Filters';
import { PersonalizationFilter } from 'client/components/Gallery/Filters/PersonalizationV1';
import { useTranslations } from 'client/hooks/useTranslations';
import { useAnalytics } from '~/client/hooks/gallery/useAnalytics';
import { ANALYTICS_EVENT_ACTIONS, ANALYTICS_LABEL } from '~/shared/constants';
import { useIsPersonalizationEnabled } from '~/client/hooks/useIsPersonalizationEnabled';
import { PersonalizationHeader } from './PersonalizationHeader';
import { PersonalizationWrapper } from './PersonalizationWrapper';

export const PersonalizationSidebarV1 = (): JSX.Element => {
    const localize = useTranslations();
    const analytics = useAnalytics();
    const { shouldShowColorFilterFirst } = useIsPersonalizationEnabled();

    useEffect(() => {
        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.PERSONALIZATION_VIEWED,
            eventLabel: ANALYTICS_LABEL.PERSONALIZATION_VIEWED,
            eventDetail: 'Personalization Sidebar Viewed',
            ...analytics.getPageProperties(),
        });
    }, [analytics]);

    return (
        <div className="personalization-sidebar-container">
            <Card
                bgc="accent"
                className="personalization-sidebar-collapsible"
                mb="between-subsections"
                p={6}
            >
                <PersonalizationWrapper>
                    <PersonalizationHeader />
                    <PersonalizationFilter />
                </PersonalizationWrapper>
            </Card>
            <Box>
                <Typography fontSkin="title-subsection" pt={6}>
                    {localize('PersonalizationAlternativeFiltersMenuHeader')}
                </Typography>
                <Filters showColorFirst={shouldShowColorFilterFirst} />
            </Box>
        </div>
    );
};

PersonalizationSidebarV1.displayName = 'PersonalizationSidebarV1';
