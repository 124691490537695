import {
    ModalDialog, ModalDialogBody, ModalDialogCloseButton, ModalDialogContent, ModalDialogNav,
} from '@vp/swan';
import { usePersonalizationFlyoutOpen } from '../../Header/PersonalizationV1/hooks';
// import { useAnalytics } from '~/client/hooks/gallery/useAnalytics';
// import { ANALYTICS_EVENT_ACTIONS } from '~/shared/constants';

// TODO: PostRefactor: Audit CSS
// TODO: PostRefactor: Audit usePersonalizationFlyoutOpen
export const PersonalizationModalV2 = (): JSX.Element => {
    const {
        uploadModalOpen,
        setUploadModalOpen,
        mobilePersonalizationModalOpen,
        setMobilePersonalizationModalOpen,
    } = usePersonalizationFlyoutOpen();
    // const analytics = useAnalytics();

    const handleModalClose = ((): void => {
        // if (!uploadModalOpen) {
        //     analytics.trackEvent({
        //         action: ANALYTICS_EVENT_ACTIONS.FLY_OUT_CLOSED,
        //         eventLabel: 'Personalization Close Button',
        //         eventDetail: 'Personalization Mobile Modal Closed',
        //         ...analytics.getPageProperties(),
        //     });git ad
        // }

        setMobilePersonalizationModalOpen(false);
        setUploadModalOpen(false);
    });

    return (
        <ModalDialog
            className={uploadModalOpen ? 'personalization-image-modal' : 'mobile-personalization-dialog'}
            isOpen={mobilePersonalizationModalOpen}
            variant="panel-right"
            onRequestDismiss={handleModalClose}
        >
            <ModalDialogContent>
                <ModalDialogNav>
                    <ModalDialogCloseButton accessibleText="Close" onClick={(): void => setMobilePersonalizationModalOpen(false)} />
                </ModalDialogNav>
                <ModalDialogBody>
                    <div>V2</div>
                </ModalDialogBody>
            </ModalDialogContent>

        </ModalDialog>
    );
};

PersonalizationModalV2.displayName = 'PersonalizationModalV2';
