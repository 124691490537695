import { useContext } from 'react';
import { PersonalizationReactContext } from '~/client/contexts/PersonalizationReactContext';
import { useBrandContext } from '@vp/react-brand';
import { ANALYTICS_EVENT_ACTIONS } from '~/shared/constants';
import { selectedUploadsState } from '~/client/atoms/selectedUploadsStateAtom';
import { useRecoilValue } from 'recoil';
import { useAnalytics } from '~/client/hooks/gallery/useAnalytics';
import { personalizedTextToDpcText } from './dpcConverters';

// Used for mobile log in button press
// Gathers mobile text fields and selected images and saves it to brand and DPCS
export const useMobileSave = (): () => Promise<void> => {
    const {
        isSmallScreenPersonalizationEnabled,
        gatherMobileInputs,
        dispatchDesignPersonalizationManualUpdate,
        selectedDpcImages,
    } = useContext(PersonalizationReactContext);
    const brandContext = useBrandContext();
    const analytics = useAnalytics();
    const selectedUploads = useRecoilValue(selectedUploadsState);

    const onSignInPress = async (): Promise<void> => {
        if (!isSmallScreenPersonalizationEnabled || !brandContext.isInitialized) {
            return;
        }

        const newPersonalizedText: Record<string, string> = gatherMobileInputs?.() || {};

        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
            eventLabel: 'Personalization modal images applied (mobile)',
            eventDetail: `${selectedUploads.length} Mobile personalization modal images applied`,
            ...analytics.getPageProperties(),
        });

        await dispatchDesignPersonalizationManualUpdate?.({
            text: personalizedTextToDpcText(newPersonalizedText),
            images: selectedDpcImages,
        });
    };

    return onSignInPress;
};
