import { Filters } from 'client/components/Gallery/Filters';
import { useIsPersonalizationEnabled } from '~/client/hooks/useIsPersonalizationEnabled';

import { PersonalizationSidebarV1 } from './PersonalizationV1/PersonalizationSidebar';
import { PersonalizationSidebarV2 } from '../PersonalizationV2/PersonalizationSidebar';

export const FiltersSidebar = (): JSX.Element => {
    const { isSidebarPersonalizationEnabled, isPersonalizationV2Enabled } = useIsPersonalizationEnabled();

    return (
        <nav className="left-nav">
            {isSidebarPersonalizationEnabled && !isPersonalizationV2Enabled && <PersonalizationSidebarV1 />}
            {isSidebarPersonalizationEnabled
            && isPersonalizationV2Enabled && <PersonalizationSidebarV2 />}
            {!isSidebarPersonalizationEnabled && <Filters />}
        </nav>
    );
};

FiltersSidebar.displayName = 'FiltersSidebar';
