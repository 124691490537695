import { useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import { queryUrl, QUERY_KEY } from '~/client/components/Gallery/Header/Favorites/queryUrl';

type QueryKey = VP.MTT.Queries.Url.UrlQueryKey;

export const useQueryUrl = (
    locale: i18n.Locale,
    urlId: string,
    options?: UseQueryOptions<string, Error, string, QueryKey>,
): UseQueryResult<string, Error> => useQuery<string, Error, string, QueryKey>(
    [QUERY_KEY, {
        locale, urlId,
    }],
    queryUrl,
    { ...options },
);
