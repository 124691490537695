import {
    Button, ButtonProps, Link, LinkProps,
} from '@vp/swan';
import classnames from 'classnames';
import { useTranslations } from 'client/hooks/useTranslations';

type Skin = 'primary' | 'secondary';

type AnchorTextButtonProps = Omit<ButtonProps, 'ref'> & {
    className?: string;
    href: string;
    skin?: Skin;
    onClick?: React.MouseEventHandler<HTMLAnchorElement>;
    width?: ButtonProps['width'];
};

export const SelectDesignButton = (props: AnchorTextButtonProps): JSX.Element => {
    const {
        className, href, skin = 'primary', width = 'full-width', onClick, ...rest
    } = props;
    const localize = useTranslations();

    return (
        <Button
            className={classnames(`quick-view-${skin}-button`, className)}
            mb={0}
            render={(linkProps: LinkProps): JSX.Element => (
                <Link
                    className={linkProps.className}
                    href={href}
                    rel="nofollow"
                    onClick={onClick}
                >
                    {linkProps.children}
                </Link>
            )}
            skin={skin}
            width={width}
            {...rest}
        >
            {localize('SelectDesign')}
        </Button>
    );
};
