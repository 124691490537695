/* eslint-disable no-underscore-dangle */
import type { PersonalizedUrlBuilder } from '~/client/utils/tilePersonalization/personalizedUrlBuilder';

export const removeQueryParameterFromUrl = (url: string, param: string): string => {
    const newUrl = new URL(url);
    const params = new URLSearchParams(newUrl.search);

    // Delete the parameter.
    params.delete(param);

    newUrl.search = params.toString();

    return newUrl.toString();
};

export class StudioUrlBuilder {
    private _url: URL | undefined;

    public get URL(): URL {
        if (!this._url) {
            throw new Error('InvalidAccessError: "url" is not defined. Did you set the base URL?');
        }

        return this._url;
    }

    public build(): string {
        if (!this._url) {
            throw new Error('InvalidAccessError: "url" is not defined. Did you set the base URL?');
        }

        return this._url.toString();
    }

    // Personalization
    public buildWithPersonalizedUrlBuilder(
        instructions: string,
        builder: typeof PersonalizedUrlBuilder.buildDiamondStudioUrl,
    ): string {
        if (!this._url) {
            throw new Error('InvalidAccessError: "url" is not defined. Did you set the base URL?');
        }

        return builder(instructions, this._url).toString();
    }
    // End Personalization

    public reset(): this {
        this._url = undefined;

        return this;
    }

    public setUrl(url: string): this {
        this._url = new URL(url);

        return this;
    }

    public addImpressionId(impressionId: string): this {
        if (!this._url) {
            throw new Error('InvalidAccessError: "url" is not defined. Did you set the base URL?');
        }

        this._url.searchParams.set('metadata', JSON.stringify({
            ...this._url.searchParams.has('metadata') ? JSON.parse(this._url.searchParams.get('metadata') as string) : null,
            galleryImpressionId: impressionId,
        }));

        return this;
    }

    public addEngagementId(engagementId: string): this {
        if (!this._url) {
            throw new Error('InvalidAccessError: "url" is not defined. Did you set the base URL?');
        }

        this._url.searchParams.set('metadata', JSON.stringify({
            ...this._url.searchParams.has('metadata') ? JSON.parse(this._url.searchParams.get('metadata') as string) : null,
            galleryEngagementId: engagementId,
        }));

        return this;
    }

    public addQuantity(quantity: string): this {
        if (!this._url) {
            throw new Error('InvalidAccessError: "url" is not defined. Did you set the base URL?');
        }

        this._url.searchParams.set('qty', quantity);

        return this;
    }
}
