import { Typography } from '@vp/swan';
import { useContext } from 'react';
import { PersonalizationReactContext } from '~/client/contexts/PersonalizationReactContext';
import { useAuth } from '~/client/hooks/useAuth';
import { TRACKING_BEHAVIOR, useExperimentation } from '~/client/hooks/useExperimentation';
import { useTranslations } from '~/client/hooks/useTranslations';
import { PERSONALIZATION_UX_BRAND_VARIATIONS } from '~/experiments/Personalization/constants';

export const PersonalizationHeader = (props: { mobileStyle?: boolean }): JSX.Element => {
    const localize = useTranslations();
    const { mobileStyle } = props;
    const {
        brandDps,
    } = useContext(PersonalizationReactContext);

    const auth = useAuth();

    const isExperimentActive = useExperimentation();
    const isBrandExperimentEnabled = isExperimentActive(
        PERSONALIZATION_UX_BRAND_VARIATIONS.Enabled,
        TRACKING_BEHAVIOR.Suppress,
    );
    const subheaderText = (isBrandExperimentEnabled && auth?.isSignedIn && brandDps) ? localize('PersonalizationFilterLoggedInSubtext') : localize('PersonalizationFilterSubtext');

    return (
        <div className="personalization-header">
            <Typography as="h3" fontSkin={mobileStyle ? 'title-section' : 'title-subsection'} marginBottom={2}>
                {localize('PersonalizationFilterHeader')}
            </Typography>
            <Typography fontSkin={mobileStyle ? 'body-standard' : 'body-small'} marginBottom={mobileStyle ? 5 : 6}>{subheaderText}</Typography>
        </div>
    );
};

PersonalizationHeader.displayName = 'PersonalizationHeader';
