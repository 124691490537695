import { MIN_RELEVANT_BOOST_COUNT, TEMPLATE_INTERACTION_TYPE } from '~/experiments/DesignBoost/constants';

// Normalize the weights of the results so that all boosts total 1

// Exclude results where the count is 1 or less. Split into keywords and categories.
export const normalizeTemplateInteractionsToBoosts = (templateInteractions: VP.DesignPersonalization.Models.DesignPersonalizationContextService.TemplateInteraction[]): State.BoostsState => {
    let totalCount = 0;
    const boostedKeywords: Gallery.ContentQuery.Boost[] = [];
    const boostedCategories: Gallery.ContentQuery.Boost[] = [];

    const groupedTemplateInteractions = templateInteractions.reduce((acc, interaction) => {
        const key = `${interaction.subtype}-${interaction.data}`;
        const multiplier = interaction.type === TEMPLATE_INTERACTION_TYPE.STUDIO_TRANSITION ? 2 : 1;
        const weightedValue = interaction.count * multiplier;

        acc[key] = (acc[key] || 0) + weightedValue;
        totalCount += weightedValue;
        return acc;
    }, {} as Record<string, number>);

    for (const [key, count] of Object.entries(groupedTemplateInteractions)) {
        const [subtype, value] = key.split('-');

        if (count >= MIN_RELEVANT_BOOST_COUNT) {
            const normalizedWeight = count / totalCount;

            if (subtype === 'keyword') {
                boostedKeywords.push({
                    value,
                    weight: normalizedWeight,
                });
            } else if (subtype === 'category') {
                boostedCategories.push({
                    value,
                    weight: normalizedWeight,
                });
            }
        }
    }

    return {
        boostedKeywords,
        boostedCategories,
    };
};
