import config from 'config';
import { createSelector } from 'reselect';

import { tileEntityAllIdsSelector, tileEntityByIdSelector } from 'client/store/tileEntity';
import {
    getGalleryIdSelector,
    getGalleryNameSelector,
    getMpvid,
    getForcedRankingStrategySelector,
    getViewId,
    getProductKey,
} from 'client/store/config';
import { filterRefinementsSelector, keywordRefinementSelector } from 'client/store/refinement/selectors';
import { REFINEMENT_DIMENSION } from 'shared/constants';
import { designByIdSelector } from '~/client/store/design';
import { getEntityImpressionId } from '~/client/store/analytics/entityImpressionId';
import { totalEntityCountSelector } from '~/client/store/paging';
import { getLatsLoadMoreLength } from 'client/store/config/reducer';

export const generateImpressionId = (
    viewId: string,
    tileEntityImpressionId: string,
): string => (`${viewId}..${tileEntityImpressionId}`);

export const getSelectedFilters = createSelector(
    (state: State.GlobalState) => filterRefinementsSelector(state),
    (state: State.GlobalState) => state.filterCategories,
    (state: State.GlobalState) => state.filterByCategory,
    (state: State.GlobalState) => state.filterByTemplateUseCase,
    (state: State.GlobalState) => state.filterTemplateUseCases,
    (state: State.GlobalState) => state.filterOptions,
    (state: State.GlobalState) => state.filterByOption,
    (
        refinements,
        filterCategories,
        filterByCategory,
        filterByTemplateUseCase,
        filterTemplateUseCases,
        filterOptions,
        filterByOption,
    ) => (
        Object.values(refinements).map((refinement) => {
            if (refinement.dimension === REFINEMENT_DIMENSION.ATTRIBUTE) {
                const filterOption = filterOptions.byId[refinement.value];
                const filter = filterByOption[refinement.value];

                return {
                    id: refinement.value,
                    title: filterOption?.title,
                    parent: filter,
                    isDefault: !!refinement.isImplicitDefault,
                    type: refinement.dimension,
                } as Gallery.Analytics.Refinement;
            }

            if (refinement.dimension === REFINEMENT_DIMENSION.TEMPLATE_USE_CASE) {
                const tuc = filterTemplateUseCases[refinement.value];
                const filter = filterByTemplateUseCase[refinement.value];

                return {
                    id: refinement.value,
                    title: tuc.title,
                    parent: filter,
                    isDefault: false,
                    type: refinement.dimension,
                } as Gallery.Analytics.Refinement;
            }

            const category = filterCategories[refinement.value];
            const filter = filterByCategory[refinement.value];

            return {
                id: refinement.value,
                title: category?.title,
                parent: filter,
                isDefault: false,
                type: refinement.dimension,
            } as Gallery.Analytics.Refinement;
        })
    ),
);

export const galleryImpressionIdSelector = createSelector(
    getViewId,
    (_state: State.GlobalState, tileEntityId: string): string => getEntityImpressionId(tileEntityId),
    generateImpressionId,
);

export const galleryImpressionsTrackingSelector = createSelector(
    [
        tileEntityAllIdsSelector,
        tileEntityByIdSelector,
        designByIdSelector,
        getGalleryNameSelector,
        getGalleryIdSelector,
        getMpvid,
        getProductKey,
        getSelectedFilters,
        getForcedRankingStrategySelector,
        keywordRefinementSelector,
        getViewId,
        totalEntityCountSelector,
        getLatsLoadMoreLength,
        (
            _state: State.GlobalState,
            analytics: Gallery.Analytics.IAnalytics,
        ): number => analytics.getIncrementedSessionStep(),
        (
            _state: State.GlobalState,
            _analytics: Gallery.Analytics.IAnalytics,
            location: string,
        ): string => location,
    ],
    (
        tileEntityIds,
        tileEntityById,
        designById,
        galleryName,
        internalGalleryId,
        mpvId,
        galleryProductKey,
        refinements,
        forcedRankingStrategy,
        keywordRefinement,
        viewId,
        totalEntityCount,
        latsLoadMoreLength,
        sessionStep,
        location,
    ) => {
        const productOptionsMap = new Map<string, Gallery.Analytics.ProductOptionsSet>();
        const adjustedTileEntityIds = tileEntityIds.slice(-latsLoadMoreLength);

        return {
            galleryId: galleryName,
            galleryName,
            internalGalleryId,
            productKeys: [galleryProductKey],
            sessionStep,
            refinements,
            forcedRankingStrategy,
            totalEntityCount,
            entities: adjustedTileEntityIds.map((tileEntityId) => {
                const tileEntity = tileEntityById(tileEntityId);

                const {
                    fullProductOptions,
                    productKey,
                    product,
                    colorSwatches,
                    isForceRanked,
                    designCreationType,
                    position,
                    designConceptId,
                    externalId,
                    templateUseCase,
                    boostInfo,
                } = tileEntity;
                const colorSwatchValues = colorSwatches.map((cs) => designById(cs).color?.split('#')[1]).filter(Boolean);

                let optionsSetId = productOptionsMap.get(tileEntity.productOptionsHash)?.id;

                if (!optionsSetId) {
                    // start IDs at 1
                    optionsSetId = productOptionsMap.size + 1;

                    productOptionsMap.set(tileEntity.productOptionsHash, {
                        id: optionsSetId,
                        options: fullProductOptions,
                    });
                }

                return {
                    mpvId,
                    productKey,
                    product,
                    galleryImpressionId: generateImpressionId(viewId, getEntityImpressionId(tileEntityId)),
                    colorSwatchValues,
                    designId: tileEntityId,
                    position,
                    isForceRanked,
                    productOptionsSetId: optionsSetId,
                    designCreationType,
                    designConceptId,
                    externalId,
                    templateUseCase,
                    isBoosted: boostInfo?.boosted || false,
                };
            }),
            route: config.client.segmentRoute,
            location,
            isAutosuggestSearch: !!keywordRefinement?.isAutosuggest,
            productOptionsSets: Array.from(productOptionsMap.values()),
        } as Gallery.Analytics.GalleryImpressions;
    },
);
