import { memo, FunctionComponent } from 'react';
import { CouponCallout } from '@vp/pot-component';
import { Box } from '@vp/swan';
import { useSelector } from 'react-redux';
import { getProductKey } from 'client/store/config';
import { PromoCodeConfiguration } from 'client/components/common/QuickView/PromoCode/PromoCodeConfiguration';
import { PromoCodeProps } from 'client/components/common/QuickView/PromoCode/interface';
import { PRICING_PRESENTATION_TYPES } from '~/client/constants';

const PromoCodeComponent: FunctionComponent<PromoCodeProps> = ({
    selectedProductOptions,
    quantity,
    pricingPresentationType,
}) => {
    const productKey = useSelector(getProductKey);
    const showAsLowAsPrice = pricingPresentationType === PRICING_PRESENTATION_TYPES.AS_LOW_AS_PRICE_PER_PIECE;

    return (
        <Box className="promo-code">
            <PromoCodeConfiguration quantity={quantity} selectedProductOptions={selectedProductOptions} />
            <CouponCallout asLowAsPrice={showAsLowAsPrice} productId={productKey} />
        </Box>
    );
};

export const PromoCode = memo(PromoCodeComponent);
