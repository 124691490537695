import { calculateOffset } from 'services/ContentService';
import { createSelector } from 'reselect';
import { RenderProperty } from 'shared/renderProperties';
import { numberRenderPropertySelector } from 'client/store/config/reducer';
import { DEFAULT_PAGING_SIZES, INITIAL_STATE, MINIMUM_TEMPLATES_SIZE } from 'client/store/paging/constants';
import { shouldHideCareTileSelector, shouldHideFbuTileSelector } from 'client/store/refinement/selectors';
import { isExperimentActive } from '~/shared/ab-testing';
import { NEW_DIFY_TILE_POSITION_VARIATIONS, OFFSET_AFTER_LOAD_MORE_COUNT } from '~/experiments/NewDIFYTilePosition/constants';
import { getRawExperiments } from '../experimentation';

export const getCurrentOffsetModifier = (state: State.GlobalState): number => {
    const shouldHideFbuTile = shouldHideFbuTileSelector(state);
    const shouldHideCareTile = shouldHideCareTileSelector(state);

    // FBU + ExpertService tiles
    let offset = 2;

    if (shouldHideFbuTile) {
        offset -= 1;
    }
    if (shouldHideCareTile) {
        offset -= 1;
    }

    return offset;
};

export const currentPageInfoSelector = createSelector(
    [
        (state: State.GlobalState): State.PagingState => state.paging,
        shouldHideCareTileSelector,
        getCurrentOffsetModifier,
        getRawExperiments,
        (state, loadedPosition = 0): number => loadedPosition,
    ],
    (paging, shouldHideCareTile, currentOffsetModifier, rawExperiments, loadedPosition) => {
        const { page, pageSize, loadMoreCount } = paging;
        let offsetModifier = currentOffsetModifier;
        const currentOffset = calculateOffset(page, pageSize);
        const isFirstPage = page === 1;

        // DIFY Tile experiment start
        const NewDIFYTileVar1Enabled = isExperimentActive(NEW_DIFY_TILE_POSITION_VARIATIONS.Enabled1, rawExperiments);
        const NewDIFYTileVar2Enabled = isExperimentActive(NEW_DIFY_TILE_POSITION_VARIATIONS.Enabled2, rawExperiments);

        let difyExpOffset = 0;

        // offset 1 after second load more count when DIFY at the end
        if (isFirstPage
        && loadMoreCount === OFFSET_AFTER_LOAD_MORE_COUNT
        && NewDIFYTileVar2Enabled
        && !shouldHideCareTile
        ) {
            difyExpOffset = 1;
        }

        if (isFirstPage && NewDIFYTileVar1Enabled && !shouldHideCareTile) {
            offsetModifier -= 1;
            difyExpOffset = 1;
        }
        // DIFY Tile experiment end

        const newPageSize = MINIMUM_TEMPLATES_SIZE
            - ((isFirstPage && !loadedPosition) ? offsetModifier : 0) - difyExpOffset;
        const offset = currentOffset - (currentOffset > 0 ? offsetModifier : 0);
        const localOffset = Math.max(loadedPosition * MINIMUM_TEMPLATES_SIZE - (isFirstPage ? offsetModifier : 0), 0);

        return {
            page,
            pageSize: newPageSize,
            offset: offset + localOffset,
        };
    },
);

export const totalEntityCountSelector = (state: State.GlobalState): number => state.paging.totalEntities;

/**
 * Returns the current offset based on the page and pageSize state
 * @param state
 */
export const currentOffsetSelector = (state: State.GlobalState): number => calculateOffset(state.paging.page, state.paging.pageSize);

/**
 * Selects the current `pageSize` from the state
 * @param state
 */
export const currentPageSizeSelector = (state: State.GlobalState): number => state.paging.pageSize;

/**
 * Selects the current `pageSize` from the state if it is different than the initialState `pagesize`, otherwise null
 * @param state
 */
export const nonDefaultPageSizeSelector = (state: State.GlobalState): number | null => {
    const configuredPageSize = numberRenderPropertySelector(
        state,
        { renderPropertyName: RenderProperty.DefaultPageSize },
    );

    if (configuredPageSize) {
        return state.paging.pageSize === configuredPageSize ? null : state.paging.pageSize;
    }
    return state.paging.pageSize === INITIAL_STATE.pageSize ? null : state.paging.pageSize;
};

/**
 * Selects the current `page` from the state
 * @param state
 */
export const currentPageSelector = (state: State.GlobalState): number => state.paging.page;

/**
 * Selects the current `page` from the state if it is different than the initialState `page`, otherwise null
 * @param state
 */
export const nonDefaultPageSelector = (state: State.GlobalState): number | null => (
    state.paging.page === INITIAL_STATE.page ? null : state.paging.page
);

/**
 * Selects the total number of pages available, based on the total number
 * of items available to page including FBU and potentially Care and Layout tiles and the currently set page size
 * @param state
 */
export const numberOfPagesSelector = createSelector(
    (state: State.GlobalState) => state.paging.totalEntities,
    (state: State.GlobalState) => state.paging.pageSize,
    (state: State.GlobalState) => getCurrentOffsetModifier(state),
    (totalEntities: number, pageSize: number, offsetModifier: number) => (
        Math.ceil((totalEntities + offsetModifier) / pageSize)
    ),
);

/**
 * Using `reselect`, creates a selector that returns a list of available
 * `pageSize`s. Only `pageSizes` up to the the minimal maximum `pageSize`
 * is returned. For example, if there are only 30 entities, then we only
 * need to return sizes [24, 48] since 96 wouldn't show anything more
 * than 48.
 */
export const pageSizeOptionsSelector = createSelector(
    (state: State.GlobalState) => state.paging.totalEntities,
    (totalEntities: number) => {
        const pageSizes: number[] = [];

        DEFAULT_PAGING_SIZES.some((size: number) => {
            pageSizes.push(size);

            return size > totalEntities;
        });

        return pageSizes;
    },
);

export const loadMoreCountSelector = (state: State.GlobalState): number => state.paging.loadMoreCount;

export const hasMoreTemplatesSelector = (state: State.GlobalState): boolean => state.paging.hasMoreTemplates;
