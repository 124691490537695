import { Pagination } from 'client/components/Gallery/Pagination';
import { useSelector } from 'react-redux';
import {
    booleanRenderPropertySelector, getMetadata, getTLPL0Url, getPageTitle,
} from 'client/store/config/reducer';
import config from 'config';
import { getMarket, getTenant } from 'client/store/config';
import { RenderProperty } from 'shared/renderProperties';
import { ImageLoadedInstrumenter } from 'client/components/common/ImageLoadedInstrumenter';
import { CareCallout } from 'client/components/Gallery/Footer/CareCallout';
import { Faq } from 'client/components/Gallery/Footer/Faq';
import { ProductOptionsDescriptions } from 'client/components/Gallery/Footer/ProductOptionsDescriptions';
import { QuickLinks } from 'client/components/Gallery/Footer/QuickLinks';
import { TLPL0Callout } from 'client/components/Gallery/Footer/TLPL0Callout';
import { ReviewsCallout } from 'client/components/Gallery/Footer/ReviewsCallout';
import { PreviewArea } from 'client/components/Gallery/PreviewArea';
import { ProductStructuredData } from 'client/components/Gallery/ProductStructuredData';
import { SecondaryContent } from 'client/components/Gallery/SecondaryContent';
import {
    SwanProvider,
    SWAN_BASE_URL_MAP,
    ScreenClassProvider,
    SiteMain,
    MainContentStart,
    BoundedContent,
    TextInput,
    useBrowserClasses,
    SwanSSRProvider,
    GridContainer,
    Row,
    Column,
} from '@vp/swan';
import { ErrorBoundary } from 'react-error-boundary';
import { getLastContentRequestUrl } from 'client/store/debug/reducer';
import { GalleryErrorPage } from '~/client/pages/error/GalleryErrorPage';
import { useInitPricingContext } from '~/client/hooks/useInitPricingContext';
import {
    memo,
    useEffect,
} from 'react';
import { instrumenter } from '~/client/utils/instrumentation';
import { useExperimentation } from 'client/hooks/useExperimentation';
import { Header } from 'client/components/Gallery/Header';
import { HeroHeader } from 'client/components/Gallery/Header/HeroHeader';
import { Subheader } from 'client/components/Gallery/Subheader';
import { REVISED_DESIGN_FIRST_FLOW_VARIATIONS } from '~/experiments/RevisedDesignFirstFlow/constants';
import { currentPageSelector } from '~/client/store/paging';
import { useTranslations } from '~/client/hooks/useTranslations';
import { getPageTitleWithPagePostfix } from '~/client/utils/pageTitle';
import { MANUAL_VARIETY_BC_VARIATIONS } from '~/experiments/ManualVarietyBC/constants';
import { useEventListener } from '~/client/hooks/useEventListener';
import { getLogger } from 'client/utils/gallery/logger';
import { NEW_DIFY_TILE_POSITION_VARIATIONS } from '~/experiments/NewDIFYTilePosition/constants';
import { BOOST_VARIATIONS } from '~/experiments/DesignBoost/constants';
import { boostsSelector } from '~/client/store/boosts';
import { initialize } from '@vp/ab-reader';
import { initCA } from '@vp/chat-anywhere';
import { DYNAMIC_DESIGN_SIGNAGE_VARIATIONS } from '~/experiments/DynamicDesignSignage/constants';
import { FULL_SCREEN_QV_MODAL } from '~/experiments/FullscreenQVModal/constants';
import { useLocation } from 'react-router-dom';
import { matchGalleryParams } from '~/client/utils/matchGalleryParams';
import { BrandProfileProvider } from '@vp/react-brand';
import { useBrandAuth } from '~/client/hooks/useBrandAuth';
import { ASP_NEWSNESS_VARIATIONS } from '~/experiments/ASPSearch/ASPNewnessExperiment';
import { FiltersSidebar } from './Filters/FiltersSidebar';

export const Index = memo((): JSX.Element => {
    const localize = useTranslations();
    const galleryMetadata = useSelector(getMetadata);
    const lastContentRequestUrl = useSelector(getLastContentRequestUrl);
    const market = useSelector(getMarket);
    const tenant = useSelector(getTenant);
    const tlpL0Url = useSelector(getTLPL0Url);
    const pageTitle = useSelector(getPageTitle);
    const page = useSelector(currentPageSelector);
    const boosts = useSelector(boostsSelector);
    const location = useLocation();
    const brandAuth = useBrandAuth();

    // Render props
    const booleanRenderProperty = useSelector(booleanRenderPropertySelector);
    const showProductInfo = booleanRenderProperty(RenderProperty.ShowProductInfo);
    const showQuicklinks = booleanRenderProperty(RenderProperty.ShowQuicklinks);
    const showTLPL0Url = booleanRenderProperty(RenderProperty.ShowTLPL0Quicklink);

    const shouldShowTlp0Callout = !showQuicklinks && showTLPL0Url && tlpL0Url;

    useBrowserClasses(true);
    useInitPricingContext(market, tenant);
    const isExperimentActive = useExperimentation();

    useEffect(() => {
        // Fire experiment impression event for server side experiments
        instrumenter.recordAppHydratedEvent();
        isExperimentActive(REVISED_DESIGN_FIRST_FLOW_VARIATIONS.Control);
        isExperimentActive(REVISED_DESIGN_FIRST_FLOW_VARIATIONS.Enabled);
        isExperimentActive(MANUAL_VARIETY_BC_VARIATIONS.Control);
        isExperimentActive(MANUAL_VARIETY_BC_VARIATIONS.Enabled);
        isExperimentActive(NEW_DIFY_TILE_POSITION_VARIATIONS.Control);
        isExperimentActive(NEW_DIFY_TILE_POSITION_VARIATIONS.Enabled1);
        isExperimentActive(NEW_DIFY_TILE_POSITION_VARIATIONS.Enabled2);
        if (boosts) {
            isExperimentActive(BOOST_VARIATIONS.Control);
            isExperimentActive(BOOST_VARIATIONS.Enabled);
        }
        isExperimentActive(DYNAMIC_DESIGN_SIGNAGE_VARIATIONS.Control);
        isExperimentActive(DYNAMIC_DESIGN_SIGNAGE_VARIATIONS.Enabled);
        isExperimentActive(ASP_NEWSNESS_VARIATIONS.NewnessEnabledTen);
        isExperimentActive(ASP_NEWSNESS_VARIATIONS.NewnessEnabledFive);
        isExperimentActive(ASP_NEWSNESS_VARIATIONS.NewnessControl);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const { designId } = matchGalleryParams(location.pathname);

        if (designId) {
            isExperimentActive(FULL_SCREEN_QV_MODAL.Control);
            isExperimentActive(FULL_SCREEN_QV_MODAL.Enabled);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    useEffect(() => {
        document.title = getPageTitleWithPagePostfix(pageTitle, page, localize('PageTitle'));
    }, [localize, page, pageTitle]);

    useEffect(() => {
        initialize();
    }, []);

    useEffect(() => {
        initCA();
    }, []);

    useEventListener('unhandledrejection', (event) => {
        const { reason } = event as unknown as PromiseRejectionEvent;

        getLogger().warning(
            `Unhandled Promise Rejection \nMessage: ${reason?.message} \nStack: ${reason?.stack}`,
            new Error(reason),
            {
                message: reason?.message,
                stack: reason?.stack,
            },
        );
    });

    return (
        <SwanProvider swanBaseUrl={SWAN_BASE_URL_MAP.relative}>
            <SwanSSRProvider>
                <ScreenClassProvider>
                    <SiteMain>
                        <MainContentStart />
                        <ErrorBoundary FallbackComponent={GalleryErrorPage}>
                            <BoundedContent className="app">
                                <ImageLoadedInstrumenter />
                                <BrandProfileProvider
                                    includeSpeculations
                                    authorization={brandAuth}
                                    requestor={config.appName}
                                >
                                    <GridContainer>
                                        <Row>
                                            <Column span={12}>
                                                <HeroHeader />
                                                <Header />
                                            </Column>
                                        </Row>
                                        <Row>
                                            <Column
                                                className="left-nav-wrapper"
                                                span={2}
                                                spanLg={3}
                                                spanMd={3}
                                                spanSm={4}
                                                spanXl={2}
                                                spanXs={null}
                                            >
                                                <FiltersSidebar />
                                            </Column>
                                            <Column
                                                span={10}
                                                spanLg={9}
                                                spanMd={9}
                                                spanSm={8}
                                                spanXl={10}
                                                spanXs={12}
                                            >
                                                <Subheader />
                                                <PreviewArea />
                                                <Pagination />
                                                {(!!galleryMetadata && showProductInfo) && <SecondaryContent />}
                                                <section className="footer">
                                                    <Faq />
                                                    <ReviewsCallout />
                                                </section>
                                                <nav className="external-links">
                                                    <ProductOptionsDescriptions />
                                                    <QuickLinks />
                                                    {shouldShowTlp0Callout && <TLPL0Callout />}
                                                </nav>
                                                <section className="care-callout-wrapper">
                                                    <CareCallout />
                                                </section>
                                            </Column>
                                        </Row>
                                    </GridContainer>
                                </BrandProfileProvider>
                                <ProductStructuredData />
                            </BoundedContent>
                        </ErrorBoundary>
                    </SiteMain>
                    <TextInput id="_initialContentRequest" type="hidden" value={lastContentRequestUrl as string} />
                </ScreenClassProvider>
            </SwanSSRProvider>
        </SwanProvider>
    );
});
