import {
    MouseEvent, useRef,
} from 'react';
import {
    ModalDialog,
    ModalDialogBody,
    ModalDialogContent,
    Button,
    Typography,
    Icon,
    ModalDialogFooter,
} from '@vp/swan';
import { useSelector } from 'react-redux';
import equal from 'fast-deep-equal/es6';

import { Filters } from 'client/components/Gallery/Filters';
import { FilterMenuHeader } from 'client/components/Gallery/Header/FilterMenu/FilterMenuHeader';
import { FilterMenuFooter } from 'client/components/Gallery/Header/FilterMenu/FilterMenuFooter';
import { useTranslations } from 'client/hooks/useTranslations';
import { findAllRemovableRefinements } from 'client/components/Gallery/Subheader/RefinementsList/findAllRemovableRefinements';
import classnames from 'classnames';
import { useFavorites } from 'client/hooks/features/useFavorites';
import { RefinementsList } from 'client/components/Gallery/Subheader/RefinementsList';
import { totalEntityCountSelector } from '~/client/store/paging';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { filtersFlyoutOpenState, searchAutoFocusedState } from 'client/atoms/filtersFlyout';
import { scrollUpToElement } from 'client/utils/scrollToElement';
import { galleryHeaderId } from 'client/components/Gallery/Header/constants';
import { filterMenuDialogId } from 'client/components/Gallery/Header/FilterMenu/constants';
import { refinementsSelector } from '~/client/store/refinement';
import { ANALYTICS_EVENT_ACTIONS } from '~/shared/constants';
import { useAnalytics } from '~/client/hooks/gallery/useAnalytics';

interface FilterMenuProps {
    shouldRenderSmallMobileIcon?: boolean;
    showColorFilterFirst?: boolean;
}

export const FilterMenu = ({ shouldRenderSmallMobileIcon, showColorFilterFirst }: FilterMenuProps): JSX.Element => {
    const localize = useTranslations();
    const analytics = useAnalytics();
    const allRefinements = useSelector(refinementsSelector);
    const refinements = useSelector(findAllRemovableRefinements);
    const resultCount = useSelector(totalEntityCountSelector);
    const showFavorites = useFavorites();
    const initialAllRefiniments = useRef(allRefinements);

    const refinementsCount = Object.values(refinements).length;
    const resultCopy = `${resultCount} ${resultCount === 1 ? localize('Result') : localize('Results')}`;

    // Header Hierarchry Test: Boths recoil states are needed to allow the mobile search button to open
    // the filters flyout and focus the search input.
    const [filtersFlyoutOpen, setFiltersFlyoutOpen] = useRecoilState(filtersFlyoutOpenState);
    const setSearchAutoFocused = useSetRecoilState(searchAutoFocusedState);

    // Open filters modal if photo personalization opened after page refresh or redirect
    // Only for photo personalization

    const handleClose = (): void => {
        setSearchAutoFocused(false);
        setFiltersFlyoutOpen(false);
        if (!equal(initialAllRefiniments.current, allRefinements)) {
            scrollUpToElement(galleryHeaderId);
        }
    };

    const showFilters = (event: MouseEvent<HTMLButtonElement>): void => {
        event.preventDefault();

        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.FLY_OUT_VIEWED,
            eventLabel: 'Filter Button',
            eventDetail: 'Filters Fly-out Open',
            ...analytics.getPageProperties(),
        });

        setFiltersFlyoutOpen(true);
    };

    const shouldShowRefinementsCount = refinementsCount > 0;

    return (
        <>
            <Button
                aria-label={localize('FilterButtonA11yText')}
                aria-labelledby="filter-menu-button-label"
                buttonShape="round"
                className={classnames({
                    'favorites-filter-menu-button': showFavorites,
                    'mobile-rounded-icon-button': !shouldShowRefinementsCount,
                    'filter-menu-button-with-count': shouldShowRefinementsCount && !shouldRenderSmallMobileIcon,
                    'filter-menu-button-with-count-small': shouldShowRefinementsCount && shouldRenderSmallMobileIcon,
                }, 'filter-menu-button')}
                iconPosition="left"
                onClick={showFilters}
            >
                <Icon className="filter-icon" iconType="filter" marginLeft={0} marginRight={shouldRenderSmallMobileIcon ? 0 : 2} />
                <span className={classnames({ 'filter-menu-text': showFavorites })} id="filter-menu-button-label">
                    {localize('FilterMenuButtonText')}
                </span>
                {shouldShowRefinementsCount && ` (${refinementsCount})`}
            </Button>
            <ModalDialog
                takeOver
                className="filter-menu-dialog stylized-dialog-panel-left stylized-dialog-panel-menu"
                id={filterMenuDialogId}
                isOpen={filtersFlyoutOpen}
                variant="panel-left"
                onRequestDismiss={handleClose}
            >
                <ModalDialogContent fullBleed aria-label="filter-menu">
                    <ModalDialogBody>
                        <FilterMenuHeader onClose={handleClose} />
                        <div className="search-refinements">
                            {resultCount > 0 && (
                                <Typography className="result-count" fontSize="xsmall">
                                    {resultCopy}
                                </Typography>
                            )}
                            <RefinementsList />
                        </div>
                        <Filters showColorFirst={showColorFilterFirst} />
                    </ModalDialogBody>
                    <ModalDialogFooter pinned p={0}>
                        <FilterMenuFooter onClose={handleClose} />
                    </ModalDialogFooter>
                </ModalDialogContent>
            </ModalDialog>
        </>
    );
};

FilterMenu.displayName = 'FilterMenu';
