import {
    ActionInfo,
    buildActionInfo,
    clearTimer,
    startTimer,
    getTimerDuration,
    TIMERS,
} from 'client/components/Gallery/Header/PersonalizationV1/utils';
import { VistaAsset } from '@design-stack-vista/vista-assets-sdk';
import { useRecoilValue } from 'recoil';

import { hasAssetsState } from '~/client/atoms/hasAssetsAtom';
import { useAuth } from 'client/hooks/useAuth';
import { usePersonalizationAnalytics } from 'src/client/components/Gallery/Header/PersonalizationV1/hooks/usePersonalizationAnalytics';
import { useCallback } from 'react';

interface UseDrawerHandlers {
    handleImageLoadStart(asset: VistaAsset): void;
    handleImageLoadComplete(asset: VistaAsset): void;
    handleImageLoadError(asset: VistaAsset): void;
}

export const useDrawerHandlers = (): UseDrawerHandlers => {
    const auth = useAuth();
    const hasAssets = useRecoilValue(hasAssetsState);
    const personalizationAnalytics = usePersonalizationAnalytics();

    const isSignedIn = !!auth?.isSignedIn;

    const getActionInfo = useCallback((asset: VistaAsset): ActionInfo => {
        const loadingDuration = getTimerDuration(TIMERS.LOADING_TIMER, asset.data?.id);
        const actionInfo = buildActionInfo({
            actionDuration: loadingDuration,
            file: asset,
            hasAssets,
            isSignedIn,
            uploadId: asset.data?.id,
        });

        clearTimer(TIMERS.LOADING_TIMER, asset.data?.id);

        return actionInfo;
    }, [isSignedIn, hasAssets]);

    const handleImageLoadStart = useCallback(() => (asset: VistaAsset): void => {
        startTimer(TIMERS.LOADING_TIMER, asset.data?.id);
    }, []);

    const handleImageLoadComplete = useCallback((asset: VistaAsset): void => {
        personalizationAnalytics.recordImageLoadingSuccess(getActionInfo(asset));
    }, [personalizationAnalytics, getActionInfo]);

    const handleImageLoadError = useCallback((asset: VistaAsset): void => {
        personalizationAnalytics.recordImageLoadingError(getActionInfo(asset));
    }, [personalizationAnalytics, getActionInfo]);

    return {
        handleImageLoadStart,
        handleImageLoadComplete,
        handleImageLoadError,
    };
};
