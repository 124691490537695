import { resolveInitialSwatchId } from '~/shared/resolveInitialSwatchId';

export const getInitialColorSwatchId = (tileEntity: State.TileEntity, selectedDesignId: Gallery.Models.Url.ValidParsedQsValue<string>): string => {
    if (selectedDesignId) {
        return selectedDesignId;
    }

    const currentDesignId = (typeof window !== 'undefined' && (window.history.state?.usr?.designId ?? window.history.state?.designId))
        ? window.history.state.designId ?? window.history.state.usr.designId
        : resolveInitialSwatchId(tileEntity);

    return currentDesignId;
};

export const normalizeQuickViewUrl = (url: string, searchParam: string): string => {
    const urlObj = new URL(url);

    urlObj.searchParams.delete(searchParam);

    return urlObj.toString();
};
