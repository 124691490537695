/* eslint-disable max-len */
import { DesignPersonalizationContextService } from '~/services/DesignPersonalizationContextService';

export const createDesignPersonalizationSession = async (
    {
        designPersonalizationData,
        brandId,
        accessToken,
        canonicalId,
    }: VP.DesignPersonalization.Mutations.DesignPersonalizationContextService.CreateDesignPersonalizationSessionMutation,

): Promise<VP.DesignPersonalization.Models.DesignPersonalizationContextService.PersonalizationSessionResponse> => DesignPersonalizationContextService
    .createDesignPersonalizationSession(designPersonalizationData, brandId, accessToken, canonicalId);

export const updateDesignPersonalizationSession = async (
    {
        sessionId,
        designPersonalizationData,
        brandId,
        accessToken,
        canonicalId,
    }: VP.DesignPersonalization.Mutations.DesignPersonalizationContextService.UpdateDesignPersonalizationSessionMutation,
): Promise<VP.DesignPersonalization.Models.DesignPersonalizationContextService.PersonalizationSessionResponse> => DesignPersonalizationContextService
    .updateDesignPersonalizationSession(sessionId, designPersonalizationData, brandId, accessToken, canonicalId);
