import {
    Pricing, SelectionSetInput, SelectionSetLabel,
    StandardTileContents, StandardTileName, StandardTilePrice, SelectionSetTileImageContainer, Typography,
    StandardTileDescription, FluidImage,
} from '@vp/swan';
import classnames from 'classnames';
import { ComponentProps } from 'react';
import { useTranslations } from 'client/hooks/useTranslations';

export interface PropTypes extends ComponentProps<typeof SelectionSetInput> {
    pricing?: JSX.Element;
    image?: JSX.Element;
    title: string;
    iconography?: Gallery.QuickView.IcongraphyInfo;
    enableUnreachable: boolean;
    isNewQuickViewUiDensityEnabled?: boolean;
}

export const Filter = (props: PropTypes): JSX.Element => {
    const {
        disabled,
        image: backsideImage,
        pricing,
        title,
        value,
        iconography,
        enableUnreachable,
        isNewQuickViewUiDensityEnabled,
        ...rest
    } = props;

    const localize = useTranslations();

    const functionallyDisabled = !enableUnreachable && disabled;
    const shouldShowIncompatibleOption = enableUnreachable && disabled;

    return (
        <SelectionSetInput key={value} {...rest} disabled={functionallyDisabled} value={value}>
            <SelectionSetLabel
                className={classnames(
                    'qv-selection-option',
                    {
                        'new-quick-view-ui-density-enabled': isNewQuickViewUiDensityEnabled,
                        'grey-skin-tile': disabled,
                    },
                )}
                padding={0}
            >
                {/* We check !backsideImage so that we don't ever have two images on one filter */}
                {/* backsideImage is part of an AB test set in SelectBackside.tsx */}
                {!backsideImage && iconography && (
                    <SelectionSetTileImageContainer as="span">
                        <FluidImage src={iconography.src} />
                    </SelectionSetTileImageContainer>
                )}
                <StandardTileContents as="span" padding="5" textAlign="left">
                    <StandardTileName as="span">
                        <Typography as="span" fontSize="xsmall" fontWeight="bold">
                            {title}
                        </Typography>
                    </StandardTileName>
                    {backsideImage}
                    {(!functionallyDisabled && pricing) && (
                        <StandardTilePrice as="span" fontSize="xsmall">
                            <Pricing>
                                {pricing}
                            </Pricing>
                        </StandardTilePrice>
                    )}
                    {shouldShowIncompatibleOption && (
                        <StandardTileDescription as="span">
                            <Typography as="span" fontSize="xsmall">
                                {localize('QuickViewIncompatibleOption')}
                            </Typography>
                        </StandardTileDescription>
                    )}
                </StandardTileContents>
            </SelectionSetLabel>
        </SelectionSetInput>
    );
};
