export type ReturnState = {
    options: Gallery.QuickView.FilterOptionWithProductOption[],
    selectedOption: Gallery.QuickView.RenderableFilterOption | undefined,
};

const toIconedFilterOptions = (
    options: Gallery.QuickView.FilterOptionWithProductOption[],
    iconDictionary?: Util.StringDictionary<Util.StringDictionary<string>> | null | undefined,
): Gallery.QuickView.FilterOptionWithProductOption[] => {
    let iconedFilters: Gallery.QuickView.FilterOptionWithProductOption[] | undefined = options;

    if (iconDictionary) {
        iconedFilters = options.reduce((accum, filterOption) => {
            const filterToOptionsDict = iconDictionary[filterOption.productOption.optionName];
            const optionIconography = filterToOptionsDict[filterOption.productOption.optionValue] || undefined;

            if (optionIconography) {
                accum.push({
                    ...filterOption,
                    iconography: {
                        src: optionIconography,
                        isNoneFilter: filterOption.productOption.optionValue === 'None',
                    },
                });
            }
            return accum;
        }, [] as Gallery.QuickView.FilterOptionWithProductOption[]);
    }

    // Only include icons if they're present for all options within filter
    return iconedFilters.length === options.length ? iconedFilters : options;
};

export const generateFilterGroupData = (
    filter: State.QuickViewAttributeFilter,
    filterToRenderableOptionsMap: Util.StringDictionary<Gallery.QuickView.RenderableFilter>,
    designVariations: DesignVariations.DesignVariationsByHash,
    iconDictionary?: Util.StringDictionary<Util.StringDictionary<string>>,
): ReturnState | null => {
    const filterOptions = filterToRenderableOptionsMap[filter.name].options;
    const selectedOption = filterOptions.find((o) => o.selected);
    const selectedOptionName = selectedOption?.filterKey;
    const variations = Object.values(designVariations);

    const reachableOptions = filterOptions.filter((f) => variations
        .some((variation) => variation.variationSelections[f.filterKey] === f.filterValue));

    const shouldRenderFilters = selectedOptionName
        && reachableOptions.length > 1;

    return shouldRenderFilters ? {
        selectedOption,
        options: reachableOptions.some((o) => o.productOption)
            ? toIconedFilterOptions(reachableOptions, iconDictionary)
            : reachableOptions,
    } : null;
};
