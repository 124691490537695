export const ROOT_MARGIN = '0px 0px 200px 0px';

export const LOAD_MORE_DELAY = 300;

export enum TileSizeDesktop {
    Default = 4,
    Large = 3
}

export enum TileSizeMobile {
    Default = 8,
    Large = 6
}
