import { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { VortexPreview } from '~/client/components/Gallery/PreviewArea/GalleryQuickView/components/VortexPreview';
import { globalQuickViewDataSelector } from '~/client/store/quickView/selectors';
import { useNewTemplateAltText } from '~/client/hooks/useNewTemplateAltText';
import { PreviewImage } from 'client/components/common/PreviewImage';
import { WebGlSupported } from '@rendering/vortex-core';
import { ModalPreviewComponentProps } from '~/client/components/Gallery/PreviewArea/FullscreenQuickViewModal/interface';

const ModalPreviewComponent = ({
    currentEntity,
    startingEntity,
    shouldRenderVortex,
    shouldUseDiamond,
}: ModalPreviewComponentProps): JSX.Element => {
    const {
        bypassedApproval,
        maxPreviewHeight,
    } = useSelector(globalQuickViewDataSelector);

    const previewAlt = useNewTemplateAltText(startingEntity, currentEntity.colorSwatches);
    const { previewsUrls, previewWidth } = currentEntity.renderablePreviews;
    const { templateToken } = (
        currentEntity?.renderablePreviews?.previewInfo || {}
    ) as Gallery.ContentQuery.PreviewInfo | Record<string, undefined>;
    const canvasAttributes = useMemo(() => ({ 'aria-label': previewAlt }), [previewAlt]);

    const PreviewImageComponent = useMemo(() => (
        <PreviewImage
            alt={previewAlt}
            bypassedApproval={bypassedApproval}
            maxPreviewHeight={maxPreviewHeight}
            previewsUrls={previewsUrls}
            previewWidth={previewWidth}
            size="large"
            style={{ height: 'none' }}
        />
    ), [previewAlt, bypassedApproval, maxPreviewHeight, previewsUrls, previewWidth]);

    if (templateToken && shouldRenderVortex && WebGlSupported()) {
        return (
            <VortexPreview
                canvasAttributes={canvasAttributes}
                currentEntity={currentEntity}
                fallbackComponent={PreviewImageComponent}
                shouldUseDiamond={shouldUseDiamond}
                templateToken={templateToken}
            />
        );
    }

    return PreviewImageComponent;
};

export const ModalPreview = memo(ModalPreviewComponent);
