import {
    ModalDialog,
    ModalDialogBody,
    ModalDialogCloseButton,
    ModalDialogContent,
} from '@vp/swan';
import classnames from 'classnames';
import { BrowserZoom } from 'client/components/BrowserZoom';
import { useTranslations } from 'client/hooks/useTranslations';
import { ComponentProps } from 'react';

export interface PropTypes extends ComponentProps<typeof ModalDialog> {
    ariaLabel?: string;
    deferredDesignId?: string;
}

export const QuickView = (props: PropTypes): JSX.Element => {
    const {
        ariaLabel, children, className, onRequestDismiss, isModal, deferredDesignId, ...rest
    } = props;
    const localize = useTranslations();

    return (
        <BrowserZoom>
            <ModalDialog
                {...rest}
                className={classnames('quick-view-dialog', className, { 'new-quick-view-modal-dialog': isModal })}
                variant={isModal ? 'standard' : 'panel-right'}
                onRequestDismiss={onRequestDismiss}
            >
                <ModalDialogContent
                    aria-label={ariaLabel}
                    className={classnames(
                        {
                            'quick-view-wrapper': !isModal,
                            'new-quick-view-wrapper': isModal,
                        },
                    )}
                >
                    {!isModal && (
                        <ModalDialogCloseButton
                            accessibleText={localize('FilterMenuCloseButtonText')}
                            aria-label={localize('FilterMenuCloseButtonText')}
                            data-section="Gallery Fly-out"
                            data-translation="Fly-Out Closed"
                        />
                    )}
                    <ModalDialogBody className={classnames('quick-view', { 'modal-body-height': isModal && !deferredDesignId })} mb={0}>
                        {children}
                    </ModalDialogBody>
                </ModalDialogContent>
            </ModalDialog>
        </BrowserZoom>
    );
};
