import { Filter } from 'client/components/common/QuickView';
import { QuickViewFilterPricing } from 'client/components/common/QuickView/Sidebar/QuickViewFilterPricing';
import { getLogger } from 'client/utils/gallery/logger';
import { getProductOptionsHash } from 'client/utils/getProductOptionsHash';
import { useMemo } from 'react';
import { findClosestVariation } from '~/client/components/Gallery/PreviewArea/GalleryQuickView/utils/findClosestVariation';

type CurrentDesignOrVariationData = Gallery.QuickView.CurrentDesignOrVariationData;

export type PropTypes = {
    currentEntity: CurrentDesignOrVariationData,
    unreachable: boolean,
    enableUnreachable: boolean,
    iconography: Gallery.QuickView.IcongraphyInfo | undefined,
    filterKey: string,
    filterValue: string,
    title: string,
    variableSelections: DesignVariations.VariableSelections,
    isNewQuickViewUiDensityEnabled?: boolean;
};

const LOGGER = getLogger();

function renderPricing(
    currentEntity: CurrentDesignOrVariationData,
    filterKey: string,
    filterValue: string,
    title: string,
    variableSelections: DesignVariations.VariableSelections,
    enableUnreachable: boolean,
): JSX.Element | undefined {
    const { designVariations } = currentEntity;
    const updatedSelections = {
        ...variableSelections,
        [filterKey]: filterValue,
    };
    const hash = getProductOptionsHash(updatedSelections);
    let dv = designVariations[hash];

    if (!dv) {
        if (!enableUnreachable) {
            LOGGER.warning({ message: `Could not find product options for filter ${title}, but filter is enabled; pricing is disabled` });

            return undefined;
        }

        const usableVariations = Object.values(designVariations)
            .filter((v) => v.variationSelections[filterKey] === filterValue);

        dv = findClosestVariation(usableVariations, updatedSelections);
    }

    const { productOptions } = dv;

    return (
        <QuickViewFilterPricing
            fullProductOptions={
                currentEntity.fullProductOptions
            }
            productKey={currentEntity.productKey}
            productOptions={productOptions}
            productOptionsByProductKey={
                currentEntity.productOptionsByProductKey
            }
            productVersion={currentEntity.productVersion}
        />
    );
}

export const DesignVariationFilter = (props: PropTypes): JSX.Element => {
    const {
        currentEntity,
        unreachable,
        enableUnreachable,
        iconography,
        filterKey,
        filterValue,
        title,
        variableSelections,
        isNewQuickViewUiDensityEnabled,
    } = props;
    const Pricing = useMemo(() => ((enableUnreachable || !unreachable) ? renderPricing(
        currentEntity,
        filterKey,
        filterValue,
        title,
        variableSelections,
        enableUnreachable,
    ) : undefined), [currentEntity, unreachable, enableUnreachable, filterKey, filterValue, variableSelections, title]);

    return (
        <Filter
            disabled={unreachable}
            enableUnreachable={enableUnreachable}
            iconography={iconography}
            isNewQuickViewUiDensityEnabled={isNewQuickViewUiDensityEnabled}
            key={filterKey}
            pricing={Pricing}
            title={title}
            value={filterValue}
        />
    );
};
