import { useRecoilValue } from 'recoil';
import { pricingContextAtom } from '../atoms/pricingContext';
import { State } from '../components/Gallery/PreviewArea/GalleryQuickView/hooks/useCurrentDesign';
import { useBatchEstimatedPricing } from './useBatchEstimatedPricing';

export interface UseProductOptionsTrackingInput {
    currentEntity: State;
    startingEntity: State.TileEntity;
    locale: i18n.Locale;
    market: string;
    quantity: Gallery.Models.Url.ValidParsedQsValue<number>;
    enabled?: boolean;
}

export interface UseProductOptionsTrackingOutput {
    initialEstimatedPriceData: VP.PCT.Models.ProductCatalogPricingService.QuantitiesPricingResult | null | undefined;
    currentEstimatedPriceData: VP.PCT.Models.ProductCatalogPricingService.QuantitiesPricingResult | null | undefined;
}

export const useProductOptionsTracking = (props: UseProductOptionsTrackingInput):
UseProductOptionsTrackingOutput => {
    const {
        currentEntity, startingEntity, locale, market, quantity, enabled = true,
    } = props;
    const pricingContext = useRecoilValue(pricingContextAtom)?.getEncodedContextString();

    const { data: initialEstimatedPriceData } = useBatchEstimatedPricing({
        market,
        productKey: startingEntity.productKey,
        quantities: quantity ? [quantity] : [],
        productVersion: startingEntity.productVersion,
        pricingContext,
        selections: startingEntity.productOptions,
        locale,
        vatInc: false,
    }, { enabled });

    const { data: currentEstimatedPriceData } = useBatchEstimatedPricing({
        market,
        productKey: currentEntity.productKey,
        quantities: quantity ? [quantity] : [],
        productVersion: currentEntity.productVersion,
        pricingContext,
        selections: currentEntity.productOptions,
        locale,
        vatInc: false,
    }, { enabled });

    return {
        initialEstimatedPriceData,
        currentEstimatedPriceData,
    };
};
