import {
    Box,
    Button,
    FormField,
    FormInputGroup,
    Spinner,
    StandardForm,
    TextInput,
    TextInputFloatingLabel,
    TextInputWithFloatingLabel,
    Typography,
} from '@vp/swan';
import { batch, useDispatch, useSelector } from 'react-redux';
import {
    useState,
    useCallback,
    useContext,
} from 'react';
import { useAnalytics } from '~/client/hooks/gallery/useAnalytics';
import { ANALYTICS_EVENT_ACTIONS } from '~/shared/constants';
import { isSmallScreen } from '~/client/utils/deviceDetection';
import { getGalleryIdSelector } from '~/client/store/config';
import { useTranslations } from '~/client/hooks/useTranslations';
import { scrollToElement } from 'client/utils/scrollToElement';
import { galleryHeaderId } from 'client/components/Gallery/Header/constants';
import { useRecoilValue } from 'recoil';
import {
    IMAGE_UPLOAD_STATES,
} from 'client/constants';
import { imageUploadState } from '~/client/atoms/imageUploadStateAtom';
import { PersonalizationReactContext } from '~/client/contexts/PersonalizationReactContext';
import { useAuth } from '~/client/hooks/useAuth';
import { TRACKING_BEHAVIOR, useExperimentation } from '~/client/hooks/useExperimentation';
import { PERSONALIZATION_UX_BRAND_EXPERIMENT_NAME, PERSONALIZATION_UX_BRAND_VARIATIONS } from '~/experiments/Personalization/constants';
import { selectedUploadsState } from '~/client/atoms/selectedUploadsStateAtom';
import { trackExperimentEngagement } from '~/shared/ab-testing';
import { getRawExperiments } from '~/client/store/experimentation';
import { designPersonalizationContextSelector } from '~/client/store/designPersonalization/selectors';
import { designPersonalizationSessionUpdate, dpcsLoadingUpdate } from '~/client/store/designPersonalization/actions';
import type { DesignPersonalizationText } from '@vp/personalization-types';
import { contentUpdate } from '~/client/store/content';
import { dpcToTextFields, personalizedTextToDpcText } from './dpcConverters';
import { PURPOSE_NAMES, TEXT_INPUT_DEBOUNCE_TIME_MS } from './constants';
import { TemplateField } from './purposeHelpers';
import { PersonalizationImageModal } from './PersonalizationImageModal';
import { usePurposeNames } from '../../Header/PersonalizationV1/hooks/usePurposeNames';
import { BrandSelector } from './BrandSelector';
import { BrandSignIn } from './BrandSignIn';
import { usePersonalizationFlyoutOpen } from '../../Header/PersonalizationV1/hooks';
import { useMobileSave } from './useMobileSave';
import { saveBrandAppliedToLocalStorage } from './designPersonalizationSessionHelpers';

export const PersonalizationFilter = (): JSX.Element => {
    const {
        textfieldValues,
        setTextfieldValues,
        isSmallScreenPersonalizationEnabled,
        mobileTextfieldValues,
        setMobileTextfieldValues,
        shouldDisplayBrand,
        setShouldDisplayBrand,
        setMobileBrandEnabled,
        brandDps,
        gatherMobileInputs,
        setManualAppliedImages,
        dispatchDesignPersonalizationManualUpdate,
        selectedDpcImages,
    } = useContext(PersonalizationReactContext);
    const [textDebounceFns, setTextDebounceFns] = useState<Record<string, NodeJS.Timeout>>({});
    const dispatch = useDispatch();
    const { setMobilePersonalizationModalOpen } = usePersonalizationFlyoutOpen();
    const uploadState = useRecoilValue(imageUploadState);
    const [textUpdateLoading, setTextUpdateLoading] = useState<Record<string, boolean>>({});
    const analytics = useAnalytics();
    const auth = useAuth();
    const localize = useTranslations();
    const purposeNames = usePurposeNames();
    const selectedUploads = useRecoilValue(selectedUploadsState);
    const onSignInPress = useMobileSave();

    const dpc = useSelector(designPersonalizationContextSelector);
    const personalizedText = dpcToTextFields(purposeNames, dpc);
    const galleryId = useSelector(getGalleryIdSelector);

    const isExperimentActive = useExperimentation();
    const isBrandExperimentEnabled = isExperimentActive(
        PERSONALIZATION_UX_BRAND_VARIATIONS.Enabled,
        TRACKING_BEHAVIOR.Suppress,
    );
    const rawExperiments = useSelector(getRawExperiments);

    const updatePersonalizedText = useCallback((newTextInput: string, purposeName: PURPOSE_NAMES) => {
        dispatch(dpcsLoadingUpdate(true));
        if (newTextInput !== '') {
            dispatchDesignPersonalizationManualUpdate?.({
                text: personalizedTextToDpcText({ ...textfieldValues, [purposeName]: newTextInput }),
            });
        } else {
            // Reset field back to template placeholder
            const { [purposeName]: _, ...newPersonalizedText } = textfieldValues;

            dispatchDesignPersonalizationManualUpdate?.({
                text: personalizedTextToDpcText(newPersonalizedText),
            });
        }

        if (!shouldDisplayBrand) {
            analytics.trackEvent({
                action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
                eventLabel: 'Text Personalized (manual input)',
                eventDetail: `Text Personalized: ${purposeName}`,
                ...analytics.getPageProperties(),
            });
        }
    }, [dispatch, shouldDisplayBrand, dispatchDesignPersonalizationManualUpdate, textfieldValues, analytics]);

    const handleTextChange = (templateField: TemplateField, event: React.ChangeEvent<HTMLInputElement>): void => {
        const { purposeName } = templateField;
        const newText = event.target.value;

        if (isSmallScreen()) {
            setMobileTextfieldValues?.({ ...mobileTextfieldValues, [purposeName]: newText });
            setShouldDisplayBrand?.(false);
            return;
        }

        setTextfieldValues?.({ ...textfieldValues, [purposeName]: newText });

        if (shouldDisplayBrand) {
            setShouldDisplayBrand?.(false);
            // useEffect in brandSelector tracks shouldDisplayBrand
            // and reapplies manual input text and images onto templates when brand is turned off
            // so no need to apply text a second time in this function
            return;
        }

        const debounceFn = textDebounceFns[purposeName];

        if (debounceFn) {
            clearTimeout(debounceFn);
            setTextUpdateLoading((prevState) => ({
                ...prevState,
                [purposeName]: false,
            }));
        }

        setTextDebounceFns({
            ...textDebounceFns,
            [purposeName]: setTimeout(() => {
                updatePersonalizedText(newText, purposeName);
                setTextUpdateLoading((prevState) => ({
                    ...prevState,
                    [purposeName]: false,
                }));
            }, TEXT_INPUT_DEBOUNCE_TIME_MS),
        });
        setTextUpdateLoading((prevState) => ({
            ...prevState,
            [purposeName]: true,
        }));
    };

    const handleEnterPress = (purposeName: PURPOSE_NAMES, event: React.KeyboardEvent<HTMLInputElement>): void => {
        if (event.key === 'Enter' && textfieldValues[purposeName] !== personalizedText[purposeName]) {
            setShouldDisplayBrand?.(false);
            analytics.trackEvent({
                action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
                eventLabel: 'Text Personalized (enter key)',
                eventDetail: `Text Personalized (enter key): ${purposeName}`,
                ...analytics.getPageProperties(),
            });

            if (textDebounceFns[purposeName]) {
                clearTimeout(textDebounceFns[purposeName]);
                setTextUpdateLoading((prevState) => ({
                    ...prevState,
                    [purposeName]: false,
                }));
            }

            updatePersonalizedText(textfieldValues[purposeName], purposeName);
        }
    };

    const trackTextFocus = (purposeName: string): void => {
        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
            eventLabel: 'Text Field Selected',
            eventDetail: `Text Field Selected: ${purposeName}`,
            ...analytics.getPageProperties(),
        });
    };

    const handleMobileSubmitPersonalization = (): void => {
        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
            eventLabel: 'Personalization submit button clicked',
            eventDetail: `Personalization submit button clicked`,
            ...analytics.getPageProperties(),
        });

        // if brand is turned on apply brand and ignore text and image fields
        setMobileBrandEnabled?.(shouldDisplayBrand);
        if (shouldDisplayBrand) {
            batch(() => {
                dispatch(designPersonalizationSessionUpdate(brandDps));
                dispatch(contentUpdate());
            });

            saveBrandAppliedToLocalStorage(true, galleryId);
            setMobilePersonalizationModalOpen(false);
            setTimeout(() => {
                scrollToElement(galleryHeaderId, { behavior: 'auto', block: 'start', inline: 'nearest' });
            }, 100);

            const brandDpc = brandDps?.designPersonalizationContext?.designPersonalizationData;
            const numImages = brandDpc?.images?.length;

            if (numImages) {
                trackExperimentEngagement(
                    PERSONALIZATION_UX_BRAND_VARIATIONS.Enabled,
                    rawExperiments,
                    analytics,
                    ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
                    'Brand Kit personalization images applied',
                    PERSONALIZATION_UX_BRAND_EXPERIMENT_NAME,
                    `${numImages} Brand kit images applied`,
                );
            }

            brandDpc?.text?.forEach((element: DesignPersonalizationText) => {
                trackExperimentEngagement(
                    PERSONALIZATION_UX_BRAND_VARIATIONS.Enabled,
                    rawExperiments,
                    analytics,
                    ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
                    'Brand Kit personalization text applied',
                    PERSONALIZATION_UX_BRAND_EXPERIMENT_NAME,
                    `Text Personalized: ${element.textField.purposeName}`,
                );
            });
            return;
        }

        setManualAppliedImages?.(selectedUploads);

        // updatePersonalizedText() doesn't work for bulk text updates, only single field updates
        const newPersonalizedText: Record<string, string> = gatherMobileInputs?.() || {};

        batch(() => {
            analytics.trackEvent({
                action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
                eventLabel: 'Personalization modal images applied (mobile)',
                eventDetail: `${selectedUploads.length} Mobile personalization modal images applied`,
                ...analytics.getPageProperties(),
            });
            dispatch(dpcsLoadingUpdate(true));
            dispatchDesignPersonalizationManualUpdate?.({
                text: personalizedTextToDpcText(newPersonalizedText),
                images: selectedDpcImages,
            });
        });

        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
            eventLabel: 'Personalization submit button clicked',
            eventDetail: `Personalization submit button clicked`,
            ...analytics.getPageProperties(),
        });

        setMobilePersonalizationModalOpen(false);
        setTimeout(() => {
            scrollToElement(galleryHeaderId, { behavior: 'auto', block: 'start', inline: 'nearest' });
        }, 100);
    };

    return auth ? (
        <section aria-label={localize('PersonalizationFilterHeader')} className="personalization-container">
            {isBrandExperimentEnabled && auth.isSignedIn && (
                <>
                    <BrandSelector />
                    {brandDps
                        && <Typography fontSkin={isSmallScreenPersonalizationEnabled ? 'body-standard-bold' : 'body-small-bold'} marginBottom={isSmallScreenPersonalizationEnabled ? 0 : 'between-actions'}>{localize('PersonalizationManualInputSubheader')}</Typography>}
                </>
            )}
            <Box
                className="personalization-upload-button-container"
                mb={purposeNames.length ? 'between-actions' : 0}
                pt={isSmallScreenPersonalizationEnabled ? 3 : 0}
            >
                <PersonalizationImageModal />
            </Box>
            <StandardForm autoComplete="off">
                {purposeNames.map((p, i, { length }) => (
                    <FormField className="personalization-form-field" key={p.purposeName}>
                        <FormInputGroup mb={i !== length - 1 ? 'between-actions' : 0}>
                            {isSmallScreenPersonalizationEnabled && (
                                <TextInputWithFloatingLabel>
                                    <TextInput
                                        className="personalization-text-input"
                                        placeholder={p.placeholder}
                                        value={mobileTextfieldValues[p.purposeName] || ''}
                                        onChange={(e): void => handleTextChange(p, e)}
                                        onFocus={(): void => trackTextFocus(p.purposeName)}
                                    />
                                    <TextInputFloatingLabel>
                                        {p.placeholder}
                                    </TextInputFloatingLabel>
                                </TextInputWithFloatingLabel>
                            )}
                            {!isSmallScreenPersonalizationEnabled && (
                                <TextInputWithFloatingLabel>
                                    <TextInput
                                        className={textUpdateLoading[p.purposeName] ? 'personalization-text-input-loading' : undefined}
                                        placeholder={p.placeholder}
                                        value={textfieldValues[p.purposeName] || ''}
                                        onChange={(e): void => handleTextChange(p, e)}
                                        onFocus={(): void => trackTextFocus(p.purposeName)}
                                        onKeyDown={(e): void => handleEnterPress(p.purposeName, e)}
                                    />
                                    {textUpdateLoading[p.purposeName] && <Spinner accessibleText={localize('Loading')} className="personalization-text-input-spinner" size="tiny" />}
                                    <TextInputFloatingLabel>
                                        {p.placeholder}
                                    </TextInputFloatingLabel>
                                </TextInputWithFloatingLabel>
                            )}
                        </FormInputGroup>
                    </FormField>
                ))}
            </StandardForm>
            {!isSmallScreenPersonalizationEnabled
                && isBrandExperimentEnabled
                && !auth.isSignedIn
                && <BrandSignIn beforeSignIn={onSignInPress} canSave={!!dpc} linkStyle="inline" />}
            {isSmallScreenPersonalizationEnabled
                && (
                    <Button
                        disabled={uploadState.status === IMAGE_UPLOAD_STATES.LOADING}
                        mt="between-subsections"
                        skin="primary"
                        width="full-width"
                        onClick={handleMobileSubmitPersonalization}
                    >
                        {isBrandExperimentEnabled ? localize('Apply') : localize('PersonalizationSubmitButtonText')}
                    </Button>
                )}
        </section>
    ) : <Box loadingShimmer className="personalization-container-skeleton" mb={5} />;
};
