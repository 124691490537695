import { memo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAnalytics } from 'client/hooks/gallery/useAnalytics';
import { galleryImpressionIdSelector } from 'client/store/analytics/reducer';
import { colorSwatchSelector, designsSelector } from 'client/store/design';
import { tileEntityByIdSelector } from 'client/store/tileEntity';
import {
    AnalyticsNames, ANALYTICS_EVENT_ACTIONS, ANALYTICS_LABEL, PREVIEW_TYPE,
} from 'shared/constants';
import { resolveInitialSwatchId } from 'shared/resolveInitialSwatchId';
import { getLogger } from 'client/utils/gallery/logger';
import { useGalleryBaseURL } from '~/client/hooks/useGalleryBaseURL';
import { FullscreenModalContent } from '~/client/components/Gallery/PreviewArea/FullscreenQuickViewModal/components/FullscreenModalContent';
import { FullscreenQuickViewModalComponentProps } from '~/client/components/Gallery/PreviewArea/FullscreenQuickViewModal/interface';
import { designPersonalizationContextSelector } from '~/client/store/designPersonalization/selectors';
import { getSelectedDesignId, getTakeOverQV } from '~/client/store/debug/reducer';
import { getInitialColorSwatchId } from '~/client/components/Gallery/PreviewArea/utils';

const LOGGER = getLogger();

const FullscreenQuickViewModalComponent = ({
    designId,
}: FullscreenQuickViewModalComponentProps): JSX.Element | null => {
    const navigate = useNavigate();
    const analytics = useAnalytics();
    const galleryBaseURL = useGalleryBaseURL();
    const isTakeOverQV = useSelector(getTakeOverQV);

    const tileEntityById = useSelector(tileEntityByIdSelector);
    const designs = useSelector(designsSelector);
    const selectColorSwatch = useSelector(colorSwatchSelector);
    const impressionId = useSelector((state: State.GlobalState) => galleryImpressionIdSelector(state, designId || ''));

    const tileEntity = tileEntityById(designId);
    const selectedDesignId = useSelector(getSelectedDesignId);
    const initialColorSwatchId = getInitialColorSwatchId(tileEntity, selectedDesignId);
    const dpc = useSelector(designPersonalizationContextSelector);

    useEffect(() => {
        try {
            const currentDesignId = resolveInitialSwatchId(tileEntity);
            const color = designs.byId[currentDesignId].color?.split('#')[1];
            const currentColorSwatch = selectColorSwatch(
                currentDesignId,
                designId,
                PREVIEW_TYPE.TILE,
            );

            const isPersonalized = dpc ? 'Quick view opened with personalized design' : '';

            if (!isTakeOverQV) {
                analytics.trackEvent({
                    action: ANALYTICS_EVENT_ACTIONS.GALLERY_DESIGN_ENGAGEMENT,
                    eventLabel: ANALYTICS_LABEL.FLY_OUT_VIEWED,
                    eventDetail: isPersonalized,
                    ...analytics.buildDesignEngagement({
                        engagementAction: ANALYTICS_LABEL.OPEN_QUICKVIEW,
                        selectedDesign: currentDesignId,
                        color,
                        tileEntity,
                        colorSwatchObjects: currentColorSwatch.colorSwatches,
                        impressionId,
                        location: AnalyticsNames.DesignTile,
                        position: tileEntity.position,
                    }),
                });
            }
        } catch (e) {
            LOGGER.error(`Failed to open routable QV: ${designId}`);
        }
        // All the data should be here so we only want this hook to run on mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!tileEntity) {
        navigate(galleryBaseURL);

        return null;
    }

    return (
        <FullscreenModalContent
            initialColorSwatchId={initialColorSwatchId}
            startingEntityId={designId}
        />
    );
};

export const FullscreenQuickViewModal = memo(FullscreenQuickViewModalComponent);
