import { AnyAction } from 'redux';
import { createSelector } from 'reselect';

const initialState: State.QuickViewFilterState = {} as State.QuickViewFilterState;

export function reducer(
    state: State.QuickViewFilterState = initialState,
    action: AnyAction,
): State.QuickViewFilterState {
    switch (action.type) {
        default:
            return state;
    }
}

export const selectQuickViewFilterNames = (state: State.GlobalState): string[] => state.quickViewFilters.allIds;

export const quickViewFilterByIdSelector = createSelector(
    (state: State.GlobalState) => state.quickViewFilters,
    (quickViewFilters) => (name: string): State.QuickViewFilter => quickViewFilters.byId[name],
);
