import { AnyAction } from 'redux';
import { createSelector } from 'reselect';

const initialState: State.FilterTemplateUseCaseState = {};

export function reducer(
    state: State.FilterTemplateUseCaseState = initialState,
    action: AnyAction,
): State.FilterTemplateUseCaseState {
    switch (action.type) {
        default:
            return state;
    }
}

export const templateUseCaseByIdSelector = createSelector(
    (state: State.GlobalState) => state.filterTemplateUseCases,
    (filterTemplateUseCases) => (
        templateUseCaseId: string,
    ): State.TemplateUseCase => filterTemplateUseCases[templateUseCaseId],
);

export const parentByChildTemplateUseCaseSelector = (state: State.GlobalState) => (templateUseCaseId: string): string => state.parentByChildTemplateUseCaseId[templateUseCaseId];
