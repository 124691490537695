import { useContext, useEffect, useMemo } from 'react';
import { batch } from 'react-redux';
import { Button, ButtonProps, Spinner } from '@vp/swan';
import { useUploadManager } from '@design-stack-vista/upload-components';
import type { DesignPersonalizationImage } from '@vp/personalization-types/dist';
import { usePersonalizationFlyoutOpen } from 'src/client/components/Gallery/Header/PersonalizationV1/hooks';
import { useTranslations } from 'client/hooks/useTranslations';
import { useRecoilValue } from 'recoil';
import { ANALYTICS_EVENT_ACTIONS } from 'shared/constants';
import { useAnalytics } from 'client/hooks/gallery/useAnalytics';
import { isSmallScreen } from '~/client/utils/deviceDetection';
import { selectedUploadsState } from '~/client/atoms/selectedUploadsStateAtom';
import { PersonalizationReactContext } from '~/client/contexts/PersonalizationReactContext';
import { selectedUploadToDpcImage } from './dpcConverters';
import { useExpectImagesToBePhotos } from './useSpecifyImageCopy';

type PersonalizationImageModalPreviewButtonProps = Omit<ButtonProps, 'ref'> & {
    selectedImagesAreAlreadyApplied: boolean,
}

const DIMENSION_DATA_TIMEOUT_MS = 30000;

export const PersonalizationImageModalPreviewButton = (props: PersonalizationImageModalPreviewButtonProps): JSX.Element => {
    const { selectedImagesAreAlreadyApplied, disabled, ...rest } = props;
    const selectedUploads = useRecoilValue(selectedUploadsState);
    const localize = useTranslations();
    const analytics = useAnalytics();
    const { setUploadModalOpen } = usePersonalizationFlyoutOpen();
    const {
        dispatchDesignPersonalizationManualUpdate,
        setShouldDisplayBrand,
        isSmallScreenPersonalizationEnabled,
        setManualAppliedImages,
        selectedDpcImages,
        setSelectedDpcImages,
    } = useContext(PersonalizationReactContext);
    const { assetStore } = useUploadManager();
    const defaultToPhoto = useExpectImagesToBePhotos();

    // convert selected uploads to Dpc format
    useEffect(
        () => {
            let isMounted = true;

            const getDpcSelectedImages = async (): Promise<void> => {
                const images = await Promise.all(selectedUploads.map(
                    (upload) => selectedUploadToDpcImage(upload, DIMENSION_DATA_TIMEOUT_MS, defaultToPhoto, assetStore),
                ));

                if (isMounted) {
                    setSelectedDpcImages?.(images.filter((image): image is DesignPersonalizationImage => !!image));
                }
            };

            getDpcSelectedImages();

            return () => {
                isMounted = false;
            };
        },
        [assetStore, defaultToPhoto, selectedUploads, setSelectedDpcImages],
    );

    const applySelectedImagesText = useMemo(() => {
        const numImages = selectedUploads.length;

        if (numImages > 1) {
            return localize('PersonalizationModalPluralPreviewImageButtonText').replace('{{number}}', `${numImages}`);
        }

        if (numImages === 1) {
            return localize('PersonalizationModalSingularPreviewImageButtonText');
        }

        if (numImages === 0 && !selectedImagesAreAlreadyApplied) {
            return localize('PersonalizationModalClearPreviewButtonText');
        }

        return localize('PersonalizationModalGenericPreviewImageButtonText');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedUploads.length]);

    const handleApplySelectedUploads = (): void => {
        setShouldDisplayBrand?.(false);
        if (!isSmallScreen()) {
            batch(() => {
                analytics.trackEvent({
                    action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
                    eventLabel: 'Personalization modal images applied',
                    eventDetail: `${selectedUploads.length} Personalization modal images applied`,
                    ...analytics.getPageProperties(),
                });

                dispatchDesignPersonalizationManualUpdate?.({
                    images: selectedDpcImages,
                });
            });
            setManualAppliedImages?.(selectedUploads);
        } else {
            analytics.trackEvent({
                action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
                eventLabel: 'Mobile image upload modal preview button clicked',
                eventDetail: `${selectedUploads.length} images selected`,
                ...analytics.getPageProperties(),
            });
        }

        setUploadModalOpen(false, isSmallScreenPersonalizationEnabled);
    };

    const areSelectedUploadsAndDpcInSync = useMemo(() => {
        // eslint-disable-next-line max-len
        const selectedDpcImageIdString = selectedDpcImages?.map((dpcImage) => dpcImage.image.sherbertAssetId).sort().join();
        const selectedUploadIdString = selectedUploads.map((upload) => upload.assetId).sort().join();

        return selectedDpcImageIdString === selectedUploadIdString;
    }, [selectedDpcImages, selectedUploads]);

    return (
        <Button
            aria-label={applySelectedImagesText}
            className="apply-uploads-button"
            disabled={disabled || !areSelectedUploadsAndDpcInSync}
            skin="primary"
            onClick={handleApplySelectedUploads}
            {...rest}
        >
            {
                areSelectedUploadsAndDpcInSync
                    ? applySelectedImagesText
                    : <Spinner accessibleText={localize('Loading')} className="personalization-preview-image-spinner" size="tiny" />
            }
        </Button>
    );
};
