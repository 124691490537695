import { useQuery } from '@tanstack/react-query';

import { queryIconography, QUERY_KEY } from 'client/queries/queryIconography';

type Iconography = VP.MTT.Models.MsxService.IconographyResult | null;

type QueryKey = VP.MTT.Queries.MsxService.IconographyQueryKey;

type IconographyDictionary = Util.StringDictionary<Util.StringDictionary<string>>;

const buildIconographyDictionary = (iconData: VP.MTT.Models.MsxService.IconographyResult, locale: i18n.Locale):
IconographyDictionary | undefined | null => {
    const filterNamesListForLocale = Object.values(iconData[locale]?.list);

    return filterNamesListForLocale.reduce(
        (
            filtersToOptionValuesDict: IconographyDictionary,
            filterName: VP.MTT.Models.MsxService.IconographyListElement,
        ) => {
            const filterOptions = filterName?.values ? Object.values(filterName.values) : [];

            // Add iconography to filter option if found in the response (the first element in image arr)
            const filterWithIconedOptions: Util.StringDictionary<string> = filterOptions
                .reduce((
                    filterOptionToIconDict: Util.StringDictionary<string>,
                    filterOption: VP.MTT.Models.MsxService.IconographyProductOptionValue,
                ) => (
                    {
                        ...filterOptionToIconDict,
                        [filterOption.key]: filterOption.images?.length ? filterOption.images[0] : '',
                    }), {} as Util.StringDictionary<string>);

            return {
                ...filtersToOptionValuesDict,
                [filterName.key.key]: filterWithIconedOptions,
            };
        },
        {} as IconographyDictionary,
    );
};

export const useIconography = (
    productKey: string,
    version: number,
    tenant: string,
    locale: i18n.Locale,
    shouldRenderIconography: boolean,
    imageTransformations?: string | undefined,
    iconTransformations?: string | undefined,
): IconographyDictionary | null | undefined => {
    const { data: iconData } = useQuery<Iconography, Error, Iconography, QueryKey>(
        [QUERY_KEY, {
            productKey,
            version,
            tenant,
            locale,
            imageTransformations,
            iconTransformations,
        }],
        queryIconography,
        {
            enabled: !!shouldRenderIconography && !!productKey && !!version && !!tenant && !!locale,
            retry: 1,
        },
    );

    return iconData && locale && buildIconographyDictionary(iconData, locale);
};
