import { useSelector } from 'react-redux';
import { booleanRenderPropertySelector, stringRenderPropertySelector } from '~/client/store/config';
import { RenderProperty } from 'shared/renderProperties';
import { isSmallScreen } from '~/client/utils/deviceDetection';
import { getBooleanFromValue } from '~/shared/url';

interface PersonalizationState {
    isPersonalizationEnabled: boolean;
    isMobilePersonalizationEnabled: boolean;
    isSidebarPersonalizationEnabled: boolean;
    shouldShowColorFilterFirst: boolean;
    isPersonalizationV2Enabled: boolean;
}

const PERSONALIZATIONV2 = 'personalizationV2';

export const useIsPersonalizationEnabled = (): PersonalizationState => {
    const booleanRenderProperty = useSelector(booleanRenderPropertySelector);
    const isPersonalizationEnabled = booleanRenderProperty(RenderProperty.ShowPersonalizationUI);
    const stringRenderPropSelector = useSelector(stringRenderPropertySelector);
    const shouldShowColorFilterFirst = isPersonalizationEnabled && stringRenderPropSelector(RenderProperty.PersonalizationImageCopy)?.toString() !== 'Photo';

    // For internal testing purposes only
    const isPersonalizationV2Enabled = isPersonalizationEnabled && typeof window !== 'undefined' && !!getBooleanFromValue(
        new URLSearchParams(window.location.search).get(PERSONALIZATIONV2),
    );

    const isMobilePersonalizationEnabled = isSmallScreen() && isPersonalizationEnabled;
    const isSidebarPersonalizationEnabled = !isSmallScreen() && isPersonalizationEnabled;

    return {
        isPersonalizationEnabled,
        isMobilePersonalizationEnabled,
        isSidebarPersonalizationEnabled,
        shouldShowColorFilterFirst,
        isPersonalizationV2Enabled,
    };
};
