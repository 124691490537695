import {
    SelectionSet, Typography, SelectedValue, Box,
} from '@vp/swan';
import classnames from 'classnames';
import { ComponentProps, HTMLProps } from 'react';

export interface PropTypes extends Omit<HTMLProps<HTMLDivElement>, 'onChange'> {
    onChange?: (selectedValue: SelectedValue, event: React.ChangeEvent<HTMLInputElement>) => void;
    selectionSetProps?: ComponentProps<typeof SelectionSet>;
    selectedValue: ComponentProps<typeof SelectionSet>['selectedValue'];
    isNewQuickViewUiDensityEnabled?: boolean;
    title?: string;
    typographyProps?: ComponentProps<typeof Typography>;
    variant?: ComponentProps<typeof SelectionSet>['variant'];
}

export const FilterGroup = (props: PropTypes): JSX.Element => {
    const {
        children,
        className,
        onChange,
        selectionSetProps = {},
        selectedValue,
        title,
        isNewQuickViewUiDensityEnabled = false,
        typographyProps = {},
        variant = 'single-select',
        ...rest
    } = props;
    const {
        className: typographyClassName,
        ...typographyRest
    } = typographyProps;
    const {
        className: selectionSetClassName,
        ...selectionSetRest
    } = selectionSetProps;

    return (
        <div {...rest} aria-label={title} className={classnames('quick-view-filter-group', className)}>
            {!!title && isNewQuickViewUiDensityEnabled && (
            <Box mb={2}>
                <Typography
                    as="label"
                    className={classnames('quick-view-filter-label', typographyClassName)}
                    {...typographyRest}
                    fontSkin="body-standard-bold"
                >
                    {title}
                </Typography>
            </Box>
            )}
            {!!title && !isNewQuickViewUiDensityEnabled && (
            <Typography
                className={classnames('quick-view-filter-label', typographyClassName)}
                {...typographyRest}
                fontSize="standard"
                fontWeight="bold"
            >
                {title}
            </Typography>
            )}
            {/* @ts-expect-error */}
            <SelectionSet
                className={classnames('quick-view-filter', { 'new-quick-view-ui-density-enabled': isNewQuickViewUiDensityEnabled }, selectionSetClassName)}
                selectedValue={selectedValue}
                variant={variant}
                onSelectedValueChange={onChange}
                {...selectionSetRest}
                skin="tiles-vertical"
            >
                {children}
            </SelectionSet>
        </div>
    );
};
