import classnames from 'classnames';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { RenderProperty } from 'shared/renderProperties';
import { tileEntityAllIdsSelector } from 'client/store/tileEntity';
import { currentPageSelector, totalEntityCountSelector } from 'client/store/paging';
import { stringRenderPropertySelector } from 'client/store/config';
import { usePreviewDimensions } from 'client/hooks/usePreviewDimensions';
import { productOptionsByProductKeySelector } from 'client/store/productOptionsByProductKey';
import { FullBleedUploadTile } from 'src/client/components/Gallery/PreviewArea/FullBleedUploadTile/FullBleedUploadTile';
import { CareTile } from 'client/components/Gallery/PreviewArea/CareTile';
import { shouldHideFbuTileSelector } from 'client/store/refinement/selectors';
import { getIsLoadMoreAvailable } from '~/client/store/config/selectors';
import { DesignSkeleton } from 'client/components/Gallery/PreviewArea/DesignSkeleton';
import { currentPageInfoSelector } from '~/client/store/paging/selectors';
import { useInView } from 'react-intersection-observer';
import { ErrorBoundary } from '@vp/swan';
import { getCareData } from '~/client/store/care';
import { DesignTilesList } from 'client/components/Gallery/PreviewArea/DesignTilesList';
import { useExperimentation } from '~/client/hooks/useExperimentation';
import { NEW_DIFY_TILE_POSITION_VARIATIONS } from '~/experiments/NewDIFYTilePosition/constants';

export const PreviewListComponent = (): JSX.Element => {
    const isExperimentActive = useExperimentation();
    const resultCount = useSelector(totalEntityCountSelector);
    const tileEntityIds = useSelector(tileEntityAllIdsSelector);
    const productOptionsByProductKey = useSelector(productOptionsByProductKeySelector);
    const stringRenderProperty = useSelector(stringRenderPropertySelector);
    const currentPage = useSelector(currentPageSelector);
    const shouldHideFbuTile = useSelector(shouldHideFbuTileSelector);
    const isLoadMoreAvailable = useSelector(getIsLoadMoreAvailable);
    const { pageSize } = useSelector(currentPageInfoSelector);
    const care = useSelector(getCareData);

    const NewDIFYTileVariant1Enabled = isExperimentActive(NEW_DIFY_TILE_POSITION_VARIATIONS.Enabled1);

    const tilePreviewSize = stringRenderProperty(RenderProperty.TilePreviewSize);
    const previewHeight = usePreviewDimensions();

    const isFirstPage = currentPage === 1;
    const isFirstPageAndTemplatesExist = isFirstPage && resultCount > 0;
    const shouldShowFbuTile = isFirstPageAndTemplatesExist && !shouldHideFbuTile;
    const shouldShowCareTile = isFirstPageAndTemplatesExist && care?.designServicesAvailable;

    // Render Props
    const pricingPresentationType = stringRenderProperty(RenderProperty.PricingPresentationType);
    const { ref } = useInView({ threshold: 1 });

    const careTilePosition = pageSize - 1;
    const isCareTileAtEnd = careTilePosition >= tileEntityIds.length;
    const shouldShowTileCareAtEnd = shouldShowCareTile && isCareTileAtEnd && !NewDIFYTileVariant1Enabled;

    return (
        <ul
            className={classnames(
                'preview-area',
                `preview-area-${tilePreviewSize?.toLowerCase()}`,
                'stylized-list',
                'stylized-list-minimal',
            )}
            ref={ref}
        >
            {shouldShowFbuTile && (
                <ErrorBoundary fallback={<>&nbsp;</>} forceShowError={false}>
                    <FullBleedUploadTile
                        key="fbu-tile"
                        previewHeight={previewHeight}
                        pricingPresentationType={pricingPresentationType}
                        productOptionsByKey={productOptionsByProductKey}
                        userInteractionLocation="tile"
                    />
                </ErrorBoundary>
            )}
            <DesignTilesList />
            {shouldShowTileCareAtEnd && <CareTile previewHeight={previewHeight} />}
            {isLoadMoreAvailable && <DesignSkeleton />}
        </ul>
    );
};

export const PreviewList = memo(PreviewListComponent);
