import {
    useEffect, memo, RefObject,
} from 'react';
import { useSelector } from 'react-redux';
import { useRecoilState } from 'recoil';
import { RenderProperty } from 'shared/renderProperties';
import { tileEntityAllIdsSelector } from 'client/store/tileEntity';
import {
    WES_TENANT,
    DEFAULT_EAGER_IMAGES,
    LOADING_TYPE,
} from 'client/constants';
import { useAuth } from 'client/hooks/useAuth';
import { useQueryFavorites } from 'client/hooks/useFavorites';
import {
    getLocaleSelector,
    getProductKey,
    getMarket,
    getQuantitySelector,
    stringRenderPropertySelector,
    getViewId,
} from 'client/store/config';
import { mapFavoritesWorkIdsToEntityIds } from '~/client/utils/mapFavoritesWorkIdsToEntityIds';
import { productOptionsByProductKeySelector } from 'client/store/productOptionsByProductKey';
import { GalleryTileEntityProvider } from 'client/components/Gallery/PreviewArea/GalleryTileEntityProvider';
import {
    AnalyticsNames,
    ANALYTICS_EVENT_ACTIONS,
    PREVIEW_TYPE,
    ANALYTICS_LABEL,
} from 'shared/constants';
import { useAnalytics } from 'client/hooks/gallery/useAnalytics';
import { designsSelector } from 'client/store/design';
import { useFavorites } from 'client/hooks/features/useFavorites';
import { currentFavoritesState } from '~/client/atoms/currentFavoritesAtom';
import { getEntityImpressionId } from '~/client/store/analytics/entityImpressionId';
import { generateImpressionId } from '~/client/store/analytics/reducer';
import { DesignTile } from '~/client/components/Gallery/DesignTile';
import { designPersonalizationContextSelector } from '~/client/store/designPersonalization/selectors';

interface PropTypes {
    entityId: string;
    maxColorSwatchesPerRow: number;
    refValue: RefObject<HTMLDivElement> | null;
}

const DesignTileWrapperComponent = ({
    entityId, maxColorSwatchesPerRow, refValue,
}: PropTypes): JSX.Element => {
    const analytics = useAnalytics();
    const designs = useSelector(designsSelector);
    const tileEntityIds = useSelector(tileEntityAllIdsSelector);
    const productOptionsByProductKey = useSelector(productOptionsByProductKeySelector);
    const stringRenderProperty = useSelector(stringRenderPropertySelector);
    const locale = useSelector(getLocaleSelector);
    const auth = useAuth();
    const productKey = useSelector(getProductKey);
    const market = useSelector(getMarket);
    const quantity = useSelector(getQuantitySelector);
    const [currentFavorites, setCurrentFavorites] = useRecoilState(currentFavoritesState);
    const viewId = useSelector(getViewId);
    const dpc = useSelector(designPersonalizationContextSelector);

    // Render Props
    const showFavorites = useFavorites();
    const pricingPresentationType = stringRenderProperty(RenderProperty.PricingPresentationType);

    // fetch favorites
    const { data: favoriteData } = useQueryFavorites(
        locale as i18n.Locale,
        WES_TENANT,
        auth?.canonicalId,
        auth?.accessToken,
        productKey,
        {
            enabled: showFavorites && !!auth && !!auth.canonicalId && !!auth.accessToken && !!locale,
            retry: 1,
            staleTime: Infinity,
        },
    );

    useEffect(() => {
        if (!favoriteData) {
            return;
        }
        setCurrentFavorites(mapFavoritesWorkIdsToEntityIds(tileEntityIds, favoriteData));
    }, [favoriteData, setCurrentFavorites, tileEntityIds]);

    const curryHandleOpenQuickView = (
        colorSwatchObjects: Gallery.ContentQuery.ColorSwatch[],
        currentDesignId: string,
        tileEntity: State.TileEntity,
    ) => (): void => {
        const color = designs.byId[currentDesignId].color?.split('#')[1];
        const impressionIdFactory = (id: string): string => {
            const tileImpressionId = getEntityImpressionId(id);

            return generateImpressionId(viewId, tileImpressionId);
        };

        const isPersonalized = dpc ? 'Quick view opened with personalized design' : '';

        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.GALLERY_DESIGN_ENGAGEMENT,
            eventLabel: ANALYTICS_LABEL.FLY_OUT_VIEWED,
            eventDetail: isPersonalized,
            ...analytics.buildDesignEngagement({
                engagementAction: ANALYTICS_LABEL.OPEN_QUICKVIEW,
                selectedDesign: currentDesignId,
                color,
                tileEntity,
                colorSwatchObjects,
                impressionId: impressionIdFactory,
                location: AnalyticsNames.DesignTile,
                position: tileEntity.position,
            }),
        });
    };

    const favoriteId = currentFavorites.get(entityId);

    return (
        <GalleryTileEntityProvider tileEntityId={entityId}>
            {(
                tileEntity,
                imageProps,
                handleColorSwatchChange,
                colorSwatchObjects,
                currentColorSwatch,
                currentDesignId,
                greetingOptions,
                greetingTitle,
                shouldShowGreetingOptions,
                shouldHideForSocialMedia,
                onMouseEnter,
            ): JSX.Element | null => (
                <DesignTile
                    noFollow
                    colorSwatches={colorSwatchObjects}
                    currentDesignId={currentDesignId}
                    entityId={entityId}
                    favoriteId={favoriteId}
                    greetingOptions={greetingOptions}
                    greetingTitle={greetingTitle}
                    handleColorSwatchChange={handleColorSwatchChange}
                    imageProps={{
                        ...imageProps,
                        loading: tileEntity.position < DEFAULT_EAGER_IMAGES
                            ? LOADING_TYPE.EAGER : LOADING_TYPE.LAZY,
                    }}
                    market={market}
                    maxColorSwatchesPerRow={maxColorSwatchesPerRow}
                    previewType={PREVIEW_TYPE.TILE}
                    pricingPresentationType={pricingPresentationType}
                    productOptionsByProductKey={productOptionsByProductKey}
                    quantity={quantity}
                    refValue={refValue}
                    selectedDesignId={currentColorSwatch.designId}
                    shouldHideForSocialMedia={shouldHideForSocialMedia}
                    shouldShowGreetingOptions={shouldShowGreetingOptions}
                    tileEntity={tileEntity}
                    onLabelClick={curryHandleOpenQuickView(
                        colorSwatchObjects,
                        currentDesignId,
                        tileEntity,
                    )}
                    onMouseEnter={onMouseEnter}
                />
            )}
        </GalleryTileEntityProvider>
    );
};

export const DesignTileWrapper = memo(DesignTileWrapperComponent);
