import { createContext, Dispatch, SetStateAction } from 'react';
import type { DesignPersonalizationContext, DesignPersonalizationImage } from '@vp/personalization-types';
import { Brand } from '@vp/react-brand';

type DPS = VP.DesignPersonalization.Models.DesignPersonalizationContextService.PersonalizationSessionResponse;

export interface PersonalizationReactContextInterface {
    experimentName?: string;
    experimentVariation?: string;
    textfieldValues: Record<string, string>;
    setTextfieldValues: (Dispatch<SetStateAction<Record<string, string>>>) | null;
    isSmallScreenPersonalizationEnabled: boolean;
    mobileTextfieldValues: Record<string, string>;
    setMobileTextfieldValues: (Dispatch<SetStateAction<Record<string, string>>>) | null;
    brandDps: DPS | undefined;
    setBrandDps:
    (Dispatch<SetStateAction<DPS | undefined>>) | null;
    shouldDisplayBrand: boolean;
    setShouldDisplayBrand: (Dispatch<SetStateAction<boolean>>) | null;
    mobileBrandEnabled: boolean;
    setMobileBrandEnabled: (Dispatch<SetStateAction<boolean>>) | null;
    manualAppliedImages: Gallery.Models.Personalization.UploadIdentifier[] | undefined;
    setManualAppliedImages:
    (Dispatch<SetStateAction<Gallery.Models.Personalization.UploadIdentifier[] | undefined>>) | null;
    gatherMobileInputs: (() => Record<string, string>) | null
    dispatchDesignPersonalizationManualUpdate: ((
        newData: Partial<DesignPersonalizationContext>
    ) => Promise<void>) | null;
    selectedDpcImages: DesignPersonalizationImage[] | undefined;
    setSelectedDpcImages: (Dispatch<SetStateAction<DesignPersonalizationImage[] | undefined>>) | null;
    targetBrand: Brand | undefined,
}

export const PersonalizationReactContext = createContext<PersonalizationReactContextInterface>({
    textfieldValues: {},
    setTextfieldValues: null,
    isSmallScreenPersonalizationEnabled: false,
    mobileTextfieldValues: {},
    setMobileTextfieldValues: null,
    brandDps: undefined,
    setBrandDps: null,
    shouldDisplayBrand: false,
    setShouldDisplayBrand: null,
    mobileBrandEnabled: false,
    setMobileBrandEnabled: null,
    manualAppliedImages: undefined,
    setManualAppliedImages: null,
    gatherMobileInputs: null,
    dispatchDesignPersonalizationManualUpdate: null,
    selectedDpcImages: undefined,
    setSelectedDpcImages: null,
    targetBrand: undefined,
});
