/* eslint-disable jsx-a11y/anchor-is-valid */
import {
    Drawer,
    DrawerProps,
    InfiniteScrollContainer,
    UploadDropZone,
    UploadsButton,
    useUploadConfiguration,
    useUploadManager,
} from '@design-stack-vista/upload-components';
import {
    Box,
    Button,
    Divider,
    FlexBox,
    Icon,
    Link,
    ModalDialogBody,
    ModalDialogCloseButton,
    ModalDialogContent,
    ModalDialogFooter,
    ModalDialogHeader,
    ModalDialogNav,
    ModalDialogTitle,
    tokens,
    Typography,
} from '@vp/swan';
import { useSelector } from 'react-redux';
import {
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useRecoilState } from 'recoil';
import { SignInButton } from '~/client/components/common/SignInButton';
import { useAuth } from '~/client/hooks/useAuth';
import { useTranslations } from '~/client/hooks/useTranslations';
import { IMAGE_UPLOAD_STATES } from 'client/constants';
import { imageUploadState } from '~/client/atoms/imageUploadStateAtom';
import { getLogger } from 'client/utils/gallery/logger';
import { useDrawerHandlers, usePersonalizationFlyoutOpen } from 'client/components/Gallery/Header/PersonalizationV1/hooks';
import { useAnalytics } from 'client/hooks/gallery/useAnalytics';
import { ANALYTICS_EVENT_ACTIONS } from 'shared/constants';
import { RenderProperty } from 'shared/renderProperties';
import { appliedUploadsSelector, designPersonalizationContextSelector } from 'client/store/designPersonalization/selectors';
import { DismissableAlertBox } from '~/client/components/common/DismissableAlertBox';
import { PersonalizationReactContext } from '~/client/contexts/PersonalizationReactContext';
import { stringRenderPropertySelector } from '~/client/store/config';
import { selectedUploadsState } from '~/client/atoms/selectedUploadsStateAtom';
import { PersonalizationImageModalPreviewButton } from './PersonalizationImageModalPreviewButton';
import { convertUploadIdentiferToUsedAsset, isSameUpload } from '../../Header/PersonalizationV1/utils/vistaAssetUtils';
import { MAX_UPLOADS } from './constants';
import { useMobileSave } from './useMobileSave';
import { dpcToTextFields } from './dpcConverters';
import { usePurposeNames } from '../../Header/PersonalizationV1/hooks/usePurposeNames';

const LOGGER = getLogger();

export const PersonalizationImageModalContent = (): JSX.Element => {
    const {
        isSmallScreenPersonalizationEnabled,
        manualAppliedImages,
        setMobileTextfieldValues,
        mobileBrandEnabled,
        setShouldDisplayBrand,
    } = useContext(PersonalizationReactContext);
    const { hasAssets } = useUploadManager();
    const analytics = useAnalytics();
    const [selectedUploads, setSelectedUploads] = useRecoilState(selectedUploadsState);
    const [uploadState, setUploadState] = useRecoilState(imageUploadState);
    const appliedUploads = useSelector(appliedUploadsSelector);
    const usedAssets = useMemo(() => selectedUploads.map(convertUploadIdentiferToUsedAsset), [selectedUploads]);
    const { uploadModalOpen, setUploadModalOpen, setMobilePersonalizationModalOpen } = usePersonalizationFlyoutOpen();
    const uploadsButtonRef = useRef<HTMLInputElement>(null);
    const onSignInPress = useMobileSave();
    const purposeNames = usePurposeNames();
    const dpc = useSelector(designPersonalizationContextSelector);

    // THe follow state is to handle the fact InfinteScrollContainer needs to be in a fixed height div
    // This is a terrible hack and not recommended
    // TODO: QSP-793 clean this up after the underlying issues is addressed by https://vistaprint.atlassian.net/browse/DKT-389
    const [usedHeight, setUsedHeight] = useState(0);
    const navRef = useRef<HTMLDivElement>(null);
    const headerRef = useRef<HTMLDivElement>(null);
    const errorRef = useRef<HTMLDivElement>(null);
    const uploadsZoneRef = useRef<HTMLDivElement>(null);
    const hasAssetsHeaderRef = useRef<HTMLDivElement>(null);
    const previewButtonRef = useRef<HTMLDivElement>(null);
    const footerRef = useRef<HTMLDivElement>(null);
    const PREVIEW_BUTTON_TO_BOTTOM_MIN_MARGIN = tokens.SwanSemSpace5;

    const isSignedIn = useAuth()?.isSignedIn;
    const localize = useTranslations();
    const {
        supportedFileTypes: { fileExtensionsAsString },
    } = useUploadConfiguration();
    const updatedFileExtensions = fileExtensionsAsString
        .split(', ')
        .filter((ext: string) => ext !== '.pdf')
        .join(', ');

    const stringRenderPropSelector = useSelector(stringRenderPropertySelector);
    const imageCopyKey = stringRenderPropSelector(RenderProperty.PersonalizationImageCopy);
    const usePhotoText = imageCopyKey === 'Photo';
    const acceptedFormatsCopy = usePhotoText
        ? `${localize('PhotoPersonalizationAcceptedFormatsMessage2')} ${updatedFileExtensions}`
        : `${localize('PersonalizationModalAcceptedFormats')} ${updatedFileExtensions}`;

    /*
     * Workaround for cases where elements within modal have too much height due to copy wrapping to next line
     * in header and footer
     * More of the terrible hack
     * TODO: QSP-793 clean this up
     * Also on mobile which has compressed spacing, which allows for more space for the drawer
     */
    useEffect(() => {
        const margin = isSmallScreenPersonalizationEnabled ? 24 + 16 + 32 + 24 + 24 + 8 : 32 * 3 + 24 + 8;
        const calc = (navRef.current?.getBoundingClientRect().height ?? 0)
            + (headerRef.current?.getBoundingClientRect().height ?? 0)
            // an additional hack to account for the margin on the error popover
            + (errorRef.current?.getBoundingClientRect().height ?? -24)
            + (uploadsZoneRef.current?.getBoundingClientRect().height ?? 0)
            + (hasAssetsHeaderRef.current?.getBoundingClientRect().height ?? 0)
            + (previewButtonRef.current?.getBoundingClientRect().height ?? 0)
            + (footerRef.current?.getBoundingClientRect().height ?? 0)
            + margin;

        setUsedHeight(calc);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setUsedHeight, uploadModalOpen, uploadState.status,
        navRef.current, headerRef.current, errorRef.current,
        uploadsZoneRef.current, hasAssetsHeaderRef.current, previewButtonRef.current, footerRef.current]);

    const selectedUploadsareAlreadyApplied = useMemo(() => (
        selectedUploads.length === appliedUploads.length
        && selectedUploads.every((selectedUpload, index) => isSameUpload(selectedUpload, appliedUploads[index]))
    ), [appliedUploads, selectedUploads]);

    const disableImagePreviewButton = useMemo(
        () => selectedUploadsareAlreadyApplied || selectedUploads.length > MAX_UPLOADS,
        [selectedUploads.length, selectedUploadsareAlreadyApplied],
    );

    const { handleImageLoadStart, handleImageLoadComplete, handleImageLoadError } = useDrawerHandlers();

    const handleDrawerClick: DrawerProps['onClick'] = async (asset, pageNumber) => {
        try {
            const assetIndexInSelection = selectedUploads.findIndex(
                (upload) => upload.assetId === asset.data?.id && (upload.page ?? 1) === pageNumber,
            );
            let newUploadSelection;

            if (assetIndexInSelection > -1) {
                // asset was selected, unselect it
                newUploadSelection = [
                    ...selectedUploads.slice(0, assetIndexInSelection),
                    ...selectedUploads.slice(assetIndexInSelection + 1),
                ];
                analytics.trackEvent({
                    action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
                    eventLabel: 'Personalization modal image deselected',
                    eventDetail: `Personalization modal image deselected`,
                    ...analytics.getPageProperties(),
                });
            } else {
                newUploadSelection = [...selectedUploads, { assetId: asset.data?.id ?? '', page: pageNumber }];

                analytics.trackEvent({
                    action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
                    eventLabel: 'Personalization modal image selected',
                    eventDetail: `Personalization modal image selected`,
                    ...analytics.getPageProperties(),
                });
            }

            setSelectedUploads(newUploadSelection);
        } catch (e) {
            LOGGER.error(`Failed to select photos to preview: ${e}`, e as Error, { asset });
            setUploadState({
                status: IMAGE_UPLOAD_STATES.ERROR,
                message: localize('PhotoPersonalizationGeneralError'),
            });
        }
    };

    const handleUploadsClicked = (): void => {
        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
            eventLabel: 'Image Upload Clicked',
            eventDetail: `Image Upload Clicked`,
            ...analytics.getPageProperties(),
        });
    };

    const handleClearSelectedImages = (): void => {
        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
            eventLabel: 'Personalization modal selected images cleared',
            eventDetail: `Personalization modal selected images cleared`,
            ...analytics.getPageProperties(),
        });
        setSelectedUploads([]);
    };

    useEffect(() => {
        const imageButtonFocusTimeout = setTimeout(() => {
            if (uploadModalOpen) {
                uploadsButtonRef.current?.focus();
                setUploadState({ status: IMAGE_UPLOAD_STATES.READY });
            }
        }, 100);

        // Cleanup timeout on unmount or dependency change
        return () => clearTimeout(imageButtonFocusTimeout);
    }, [uploadModalOpen, setUploadState]);

    const handleClosePersonalization = (): void => {
        // Desktop tracking exists in PersonalizationImageModal component
        if (isSmallScreenPersonalizationEnabled) {
            analytics.trackEvent({
                action: ANALYTICS_EVENT_ACTIONS.FLY_OUT_CLOSED,
                eventLabel: 'Personalization Image Modal Close Button',
                eventDetail: 'Personalization Image Modal Closed',
                ...analytics.getPageProperties(),
            });

            setShouldDisplayBrand?.(mobileBrandEnabled);

            if (!mobileBrandEnabled) {
                setMobileTextfieldValues?.(dpcToTextFields(purposeNames, dpc));
            }
            // TODO: manual text input needs to be restored when brand is applied
            // QSP-906
        }

        setSelectedUploads(manualAppliedImages || []);
        setUploadModalOpen(false);
        setMobilePersonalizationModalOpen(false);
    };

    const handleMobileUploadBackButton = (): void => {
        setUploadModalOpen(false, true);
        setSelectedUploads(manualAppliedImages || []);
    };

    return (
        <ModalDialogContent>
            <ModalDialogNav mb={0} pb={isSmallScreenPersonalizationEnabled ? 0 : 7} ref={navRef}>
                {isSmallScreenPersonalizationEnabled && (
                    <Button
                        className="personalization-mobile-back-button"
                        skin="unstyled"
                        onClick={handleMobileUploadBackButton}
                    >
                        <Icon iconType="arrowLeft" pr={1} />
                        {localize('PersonalizationModalBackButtonText')}
                    </Button>
                )}
                <ModalDialogCloseButton
                    accessibleText="Close"
                    aria-label={localize('PhotoPersonalizationModalCloseButtonTitle')}
                    visuallyHiddenLabel={localize('PhotoPersonalizationModalCloseButtonTitle')}
                    onClick={handleClosePersonalization}
                />
            </ModalDialogNav>
            <Box className="personalization-modal-content" px={isSmallScreenPersonalizationEnabled ? 0 : 2}>
                <ModalDialogHeader
                    mb={isSmallScreenPersonalizationEnabled ? 'to-actions' : 7}
                    mt={isSmallScreenPersonalizationEnabled ? 6 : undefined}
                    ref={headerRef}
                >
                    <ModalDialogTitle
                        className={isSmallScreenPersonalizationEnabled ? 'personalization-mobile-modal-header' : 'personalization-modal-header'}
                        fontSkin="title-section"
                    >
                        {localize('PersonalizationModalImageHeaderText')}
                    </ModalDialogTitle>
                </ModalDialogHeader>
                {(uploadState.status === IMAGE_UPLOAD_STATES.ERROR) && (
                    <Box ref={errorRef}>
                        <DismissableAlertBox
                            toast
                            className="personalization-image-alert"
                            dismissVisuallyHiddenLabel={localize('DismissAlert')}
                            marginBottom="to-actions"
                            skin="error"
                            onRequestDismiss={(): void => setUploadState({ status: IMAGE_UPLOAD_STATES.READY })}
                        >
                            {localize('PersonalizationImageUploadErrorMessage')}
                        </DismissableAlertBox>
                    </Box>
                )}
                <ModalDialogBody>
                    <Box ref={uploadsZoneRef}>
                        <UploadDropZone noClick noKeyboard>
                            <UploadsButton
                                multiple
                                disabled={uploadState.status === IMAGE_UPLOAD_STATES.LOADING}
                                iconPosition="left"
                                ref={uploadsButtonRef}
                                skin={hasAssets && !disableImagePreviewButton ? 'secondary' : 'primary'}
                                width="full-width"
                                onClick={handleUploadsClicked}
                            >
                                <Icon iconType="upload" size="20p" />
                                <span>{localize('PersonalizationModalImageUploadButtonText')}</span>
                            </UploadsButton>
                        </UploadDropZone>
                        <Typography className="accepted-formats" fontSize="small" mt="4" textColor="subtle">
                            {acceptedFormatsCopy}
                        </Typography>
                    </Box>
                    {hasAssets && (
                        <>
                            <Box ref={hasAssetsHeaderRef}>
                                <Divider mt={isSmallScreenPersonalizationEnabled ? 'between-subsections' : 7} />
                                <FlexBox
                                    alignItems="center"
                                    justifyContent="space-between"
                                    my={5}
                                >
                                    <Typography fontSize="small" fontWeight="bold">{localize('PersonalizationModalUploadedAssetsTitle')}</Typography>
                                    {(selectedUploads.length > 0) && (
                                        <Link
                                            component="button"
                                            fontSize="small"
                                            render={(p): JSX.Element => (
                                                <Button
                                                    className={p.className}
                                                    skin="link"
                                                    onClick={handleClearSelectedImages}
                                                >
                                                    {p.children}
                                                </Button>
                                            )}
                                        >
                                            {localize('ClearSelection')}
                                        </Link>
                                    )}
                                </FlexBox>
                            </Box>
                            <Box
                                className={(!isSignedIn)
                                    ? 'personalization-drawer-small-container' : 'personalization-drawer-container'}
                                mb={isSmallScreenPersonalizationEnabled ? 'to-actions' : 6}
                                // This is a hack to deal with InfiniteScrollContainer requiring a fixed size wrapper
                                style={{
                                    maxHeight: `calc(100dvh - ${PREVIEW_BUTTON_TO_BOTTOM_MIN_MARGIN} - ${usedHeight}px)`,
                                }}
                            >
                                <InfiniteScrollContainer infiniteScrollId="personalization-scroll-list-container">
                                    <Drawer
                                        deleteOption="confirm"
                                        // eslint-disable-next-line react/jsx-no-useless-fragment
                                        emptyResultsElement={<></>}
                                        styleOptions={{ thumbnailSize: 102, flexboxGap: 12 }}
                                        usedAssets={usedAssets}
                                        onClick={handleDrawerClick}
                                        onImageLoadCompleted={handleImageLoadComplete}
                                        onImageLoadError={handleImageLoadError}
                                        onImageLoadStart={handleImageLoadStart}
                                    />
                                </InfiniteScrollContainer>
                            </Box>
                            <Box
                                ref={previewButtonRef}
                            >
                                <PersonalizationImageModalPreviewButton
                                    disabled={disableImagePreviewButton}
                                    selectedImagesAreAlreadyApplied={selectedUploadsareAlreadyApplied}
                                    width="full-width"
                                />
                            </Box>
                        </>
                    )}
                </ModalDialogBody>
            </Box>
            {(!isSignedIn) && (
                <ModalDialogFooter
                    pinned
                    className="personalization-modal-footer"
                    py={isSmallScreenPersonalizationEnabled ? 0 : 5}
                    ref={footerRef}
                >
                    <Box
                        className="personalization-modal-footer-content"
                        mb={isSmallScreenPersonalizationEnabled ? 5 : undefined}
                        pt={isSmallScreenPersonalizationEnabled ? 6 : undefined}
                        py={isSmallScreenPersonalizationEnabled ? undefined : 4}
                    >
                        <Typography fontSkin="body-small" mb={isSmallScreenPersonalizationEnabled ? 4 : 5} textAlign="center">{localize('PersonalizationModalLogInDescription')}</Typography>
                        <SignInButton
                            beforeSignIn={onSignInPress}
                            className="personalization-modal-sign-in-button"
                            component="button"
                            fontSkin="body-standard-bold"
                            signInText={localize('LogInButtonText')}
                            skin="cta"
                        />
                    </Box>
                </ModalDialogFooter>
            )}
        </ModalDialogContent>
    );
};
