import { useDesignVariations } from 'client/hooks/useDesignVariations';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { colorSwatchSelectorWithDesignVariation } from 'client/store/design';
import { getProductOptionsHash } from 'client/utils/getProductOptionsHash';
import { PREVIEW_TYPE } from 'shared/constants';
import { designVariationsInputSelector } from 'client/store/quickView/selectors';
import { useRecoilValue } from 'recoil';
import { currentFavoritesState } from '~/client/atoms/currentFavoritesAtom';
import { galleryImpressionIdSelector } from '~/client/store/analytics/reducer';

export type State = Gallery.QuickView.QuickViewCurrentDesignData & Omit<ReturnType<typeof useDesignVariations>, 'data'>;

export const useCurrentDesignOrVariation = (
    startingEntity: State.TileEntity,
    currentDesignId: string,
    variableSelections: DesignVariations.VariableSelections | Gallery.ContentQuery.ProductOptions,
    initialProductOptions: Gallery.ContentQuery.ProductOptions = {},
): State => {
    // Unique to DVs
    // The return value is memo'd and dependent on the entity and the resulting query should only run once
    // per unique entityId
    const designVariationInput = useSelector(designVariationsInputSelector)(
        startingEntity,
        startingEntity.productOptions,
        Object.keys(startingEntity.templateUseCases),
    );
    const { data, ...rest } = useDesignVariations({
        ...designVariationInput,
        templateToken: startingEntity.previewInfo.templateToken,
    });

    const currentDesignVariation = data[getProductOptionsHash(variableSelections)];

    const impressionId = useSelector((state: State.GlobalState) => galleryImpressionIdSelector(
        state,
        startingEntity.designId,
    ));
    const productOptionsHash = getProductOptionsHash(currentDesignVariation?.productOptions || initialProductOptions);

    const favoriteId = useRecoilValue(currentFavoritesState).get(startingEntity.designId);
    const currentColorSwatch = useSelector(colorSwatchSelectorWithDesignVariation)(
        currentDesignId,
        startingEntity.designId,
        currentDesignVariation,
        PREVIEW_TYPE.QUICKVIEW,
        impressionId,
        currentDesignVariation?.fullProductOptions || initialProductOptions,
    );
    const productOptionsByProductKey = {
        [startingEntity.productKey]: useMemo(() => Object.values(data).reduce(
            (accum, design) => ({
                ...accum,
                [getProductOptionsHash(design.productOptions)]: design.fullProductOptions,
            }),
            {},
        ), [data]),
    };

    return {
        ...rest,
        ...startingEntity,
        ...currentDesignVariation,
        ...currentColorSwatch,
        currentDesignId,
        currentEntityId: currentDesignVariation?.designId ?? startingEntity.designId,
        currentColorSwatch,
        favoriteId,
        productOptionsHash,
        productOptionsByProductKey,
        // We don't have a comparative name property on design variations. Since we're showing
        // the selected product options there's also no need to show it, so set it to undefined
        // to prevent the field from showing up
        comparativeName: undefined,
        designVariations: data,
    };
};
